import EmailEditorTypes from "./type";

// beePro login
export const beeProLogin = () => {
  return {
    type: EmailEditorTypes.BEE_LOGIN,
  };
};

export const beeProLoginSuccess = (payload) => {
  return {
    type: EmailEditorTypes.BEE_LOGIN_SUCCESS,
    payload,
  };
};

export const beeProLoginFailure = (payload) => {
  return {
    type: EmailEditorTypes.BEE_LOGIN_FAILURE,
    payload,
  };
};

// publish email to client view
export const publishEmail = (payload) => {
  return {
    type: EmailEditorTypes.PUBLISH_EMAIL,
    payload,
  };
};

export const publishEmailSuccess = (payload) => {
  return {
    type: EmailEditorTypes.PUBLISH_EMAIL_SUCCESS,
    payload,
  };
};

export const publishEmailFailure = (payload) => {
  return {
    type: EmailEditorTypes.PUBLISH_EMAIL_FAILURE,
    payload,
  };
};

// create client email copy
export const clientEmailCopy = (payload) => {
  return {
    type: EmailEditorTypes.CLIENT_EMAIL_COPY,
    payload,
  };
};

export const clientEmailCopySuccess = (payload) => {
  return {
    type: EmailEditorTypes.CLIENT_EMAIL_COPY_SUCCESS,
    payload,
  };
};

export const clientEmailCopyFailure = (payload) => {
  return {
    type: EmailEditorTypes.CLIENT_EMAIL_COPY_FAILURE,
    payload,
  };
};

// export client html email
export const exportClientEmail = (payload) => {
  return {
    type: EmailEditorTypes.EXPORT_CLIENT_EMAIL,
    payload,
  };
};

export const exportClientEmailSuccess = (payload) => {
  return {
    type: EmailEditorTypes.EXPORT_CLIENT_EMAIL_SUCCESS,
    payload,
  };
};

export const exportClientEmailFailure = (payload) => {
  return {
    type: EmailEditorTypes.EXPORT_CLIENT_EMAIL_FAILURE,
    payload,
  };
};

// Download bulk client emails
export const downloadClientEmail = (payload) => {
  return {
    type: EmailEditorTypes.DOWNLOAD_CLIENT_EMAIL,
    payload,
  };
};

export const downloadClientEmailSuccess = (payload) => {
  return {
    type: EmailEditorTypes.DOWNLOAD_CLIENT_EMAIL_SUCCESS,
    payload,
  };
};

export const downloadClientEmailFailure = (payload) => {
  return {
    type: EmailEditorTypes.DOWNLOAD_CLIENT_EMAIL_FAILURE,
    payload,
  };
};

// get BeePro templates
export const getBeeTemplates = (payload, userData) => {
  return {
    type: EmailEditorTypes.GET_BEE_TEMPLATES,
    payload,
    userData,
  };
};

export const getBeeTemplatesSuccess = (payload) => {
  return {
    type: EmailEditorTypes.GET_BEE_TEMPLATES_SUCCESS,
    payload,
  };
};

export const getBeeTemplatesFailure = (payload) => {
  return {
    type: EmailEditorTypes.GET_BEE_TEMPLATES_FAILURE,
    payload,
  };
};

// get email tags
export const getEmailTags = (userData) => {
  return {
    type: EmailEditorTypes.GET_EMAIL_TAGS,
    userData,
  };
};

export const getEmailTagsSuccess = (payload) => {
  return {
    type: EmailEditorTypes.GET_EMAIL_TAGS_SUCCESS,
    payload,
  };
};

export const getEmailTagsFailure = (payload) => {
  return {
    type: EmailEditorTypes.GET_EMAIL_TAGS_FAILURE,
    payload,
  };
};

// get selected BeePro template
export const getSingleBeeTemplate = (payload, userData) => {
  return {
    type: EmailEditorTypes.GET_SINGLE_BEE_TEMPLATE,
    payload,
    userData,
  };
};

export const getSingleBeeTemplateSuccess = (payload) => {
  return {
    type: EmailEditorTypes.GET_SINGLE_BEE_TEMPLATE_SUCCESS,
    payload,
  };
};

export const getSingleBeeTemplateFailure = (payload) => {
  return {
    type: EmailEditorTypes.GET_SINGLE_BEE_TEMPLATE_FAILURE,
    payload,
  };
};

// save bee email template changes
export const saveBeeTemplate = (payload, userData) => {
  return {
    type: EmailEditorTypes.SAVE_BEE_TEMPLATE,
    payload,
    userData,
  };
};

export const saveBeeTemplateSuccess = (payload) => {
  return {
    type: EmailEditorTypes.SAVE_BEE_TEMPLATE_SUCCESS,
    payload,
  };
};

export const saveBeeTemplateFailure = (payload) => {
  return {
    type: EmailEditorTypes.SAVE_BEE_TEMPLATE_FAILURE,
    payload,
  };
};

// get client emails
export const getClientEmails = (payload, userData) => {
  return {
    type: EmailEditorTypes.GET_CLIENT_EMAILS,
    payload,
    userData,
  };
};

export const getClientEmailsSuccess = (payload) => {
  return {
    type: EmailEditorTypes.GET_CLIENT_EMAILS_SUCCESS,
    payload,
  };
};

export const getClientEmailByIdSuccess = (payload) => {
  return {
    type: EmailEditorTypes.GET_CLIENT_EMAIL_BY_ID_SUCCESS,
    payload,
  };
};

export const getClientEmailsFailure = (payload) => {
  return {
    type: EmailEditorTypes.GET_CLIENT_EMAILS_FAILURE,
    payload,
  };
};

// get client emails by tag
export const getClientEmailsByTag = (payload) => {
  return {
    type: EmailEditorTypes.GET_CLIENT_EMAILS_BY_TAG,
    payload,
  };
};

export const getClientEmailsByTagSuccess = (payload) => {
  return {
    type: EmailEditorTypes.GET_CLIENT_EMAILS_BY_TAG_SUCCESS,
    payload,
  };
};

export const getClientEmailsByTagFailure = (payload) => {
  return {
    type: EmailEditorTypes.GET_CLIENT_EMAILS_BY_TAG_FAILURE,
    payload,
  };
};

// update client email
export const updateClientEmail = (payload) => {
  return {
    type: EmailEditorTypes.UPDATE_CLIENT_EMAIL,
    payload,
  };
};

export const updateClientEmailSuccess = (payload) => {
  return {
    type: EmailEditorTypes.UPDATE_CLIENT_EMAIL_SUCCESS,
    payload,
  };
};

export const updateClientEmailFailure = (payload) => {
  return {
    type: EmailEditorTypes.UPDATE_CLIENT_EMAIL_FAILURE,
    payload,
  };
};

// delete client email
export const deleteClientEmail = (payload) => {
  return {
    type: EmailEditorTypes.DELETE_CLIENT_EMAIL,
    payload,
  };
};

export const deleteClientEmailSuccess = (payload) => {
  return {
    type: EmailEditorTypes.DELETE_CLIENT_EMAIL_SUCCESS,
    payload,
  };
};

export const deleteClientEmailFailure = (payload) => {
  return {
    type: EmailEditorTypes.DELETE_CLIENT_EMAIL_FAILURE,
    payload,
  };
};

// store email tag selction
export const storeEmailTag = (payload) => {
  return {
    type: EmailEditorTypes.STORE_EMAIL_TAG,
    payload,
  };
};

// remove data after success
export const removeEmailEditorMessage = () => {
  return {
    type: EmailEditorTypes.REMOVE_EMAIL_EDITOR_MESSAGE,
  };
};
// remove single selected client email data
export const removeSingleClientEmail = () => {
  return {
    type: EmailEditorTypes.REMOVE_SINGLE_CLIENT_EMAIL,
  };
};

// remove emails by tag data
export const removeEmailsByTagData = () => {
  return {
    type: EmailEditorTypes.REMOVE_EMAILS_BY_TAG_DATA,
  };
};
