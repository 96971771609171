import React from "react";
import DataTable from "react-data-table-component";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

// core components
import Button from "components/CustomButtons/Button.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/batchHistoryStyle.js";
const useStyles = makeStyles(styles);

export default function ClientsDialog(props) {
  const { open, handleClose, clientDetails } = props;

  // styles
  const classes = useStyles();

  const columns = [
    {
      id: "client0",
      name: "Client Acronym",
      selector: (row) => row.acronym,
      sortable: true,
    },
    {
      id: "client1",
      name: "Client Name",
      selector: (row) => row.client_name,
      sortable: true,
    },
    {
      id: "client2",
      name: "Client Category",
      selector: (row) => row.client_category,
      sortable: true,
    },
  ];

  return (
    <Dialog open={open} disableBackdropClick maxWidth="md" fullWidth>
      <DialogTitle>
        Clients List
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={handleClose}
        >
          <Close />
        </Button>
      </DialogTitle>

      <DialogContent>
        <DataTable
          columns={columns}
          data={clientDetails}
          defaultSortFieldId="client0"
          pagination
          paginationPerPage={100}
          paginationRowsPerPageOptions={[100, 200, 300]}
          paginationComponentOptions={{
            rowsPerPageText: "Records per page:",
            rangeSeparatorText: "out of",
          }}
          customStyles={styles.datatableFontSize}
        />
      </DialogContent>
    </Dialog>
  );
}

// define props type
ClientsDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  clientDetails: PropTypes.array,
};
