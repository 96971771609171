import EmailTemplateTypes from "./type";

const initialState = {
  uploads: [],
  templates: [],
  clients: [],
  TmpFilePath: [],
  templateVariables: [],
  SingleTemplateData: "",
  htmlTemplate: "",
  loading: false,
  uploadLoading: false,
  createLoading: false,
  getClientLoading: false,
  updateVariablesLoading: false,
  updateTemplateLoading: false,
  downloadLoading: false,
  successMessage: "",
  errorMessage: "",
  UrlConstructData: [],
};

export default function EmailTemplateReducer(state = initialState, action) {
  switch (action.type) {
    // upload email templates
    case EmailTemplateTypes.UPLOAD_TEMPLATE:
      return {
        ...state,
        uploadLoading: true,
      };

    case EmailTemplateTypes.UPLOAD_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadLoading: false,
        uploads: action.payload,
        successMessage: action.payload.msg,
        errorMessage: "",
      };

    case EmailTemplateTypes.UPLOAD_TEMPLATE_FAILURE:
      return {
        ...state,
        uploadLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // create email template
    case EmailTemplateTypes.CREATE_EMAILS:
      return {
        ...state,
        createLoading: true,
      };

    case EmailTemplateTypes.CREATE_EMAILS_SUCCESS:
      return {
        ...state,
        createLoading: false,
        TmpFilePath: action.payload.payload,
        successMessage: action.payload.msg,
        errorMessage: "",
      };

    case EmailTemplateTypes.CREATE_EMAILS_FAILURE:
      return {
        ...state,
        createLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // create url construct
    case EmailTemplateTypes.CREATE_URL_CONSTRUCT:
      return {
        ...state,
        createLoading: false,
        UrlConstructData: [
          ...state.UrlConstructData.filter(
            (x) => x.acronym !== action.payload.acronym
          ),
          action.payload,
        ],
      };

    // get email templates
    case EmailTemplateTypes.GET_TEMPLATES:
      return {
        ...state,
        loading: true,
      };

    case EmailTemplateTypes.GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: action.payload,
        successMessage: action.payload.msg,
        errorMessage: "",
      };

    case EmailTemplateTypes.GET_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // get email template variables
    case EmailTemplateTypes.GET_TEMPLATE_VARIABLES:
      return {
        ...state,
        updateVariablesLoading: true,
      };

    case EmailTemplateTypes.GET_TEMPLATE_VARIABLES_SUCCESS:
      return {
        ...state,
        updateVariablesLoading: false,
        SingleTemplateData: action.payload.item,
        templateVariables: action.payload.placeholders,
        htmlTemplate: action.payload.HtmlTemplate,
        successMessage: action.payload.msg,
        errorMessage: "",
      };

    case EmailTemplateTypes.GET_TEMPLATE_VARIABLES_FAILURE:
      return {
        ...state,
        updateVariablesLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // update email template status
    case EmailTemplateTypes.UPDATE_TEMPLATE_STATUS:
      return {
        ...state,
        updateTemplateLoading: true,
      };

    case EmailTemplateTypes.UPDATE_TEMPLATE_STATUS_SUCCESS:
      return {
        ...state,
        updateTemplateLoading: false,
        successMessage: action.payload.msg,
        errorMessage: "",
      };

    case EmailTemplateTypes.UPDATE_TEMPLATE_STATUS_FAILURE:
      return {
        ...state,
        updateTemplateLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // download email template
    case EmailTemplateTypes.DOWNLOAD_TEMPLATE:
      return {
        ...state,
        downloadLoading: true,
      };

    case EmailTemplateTypes.DOWNLOAD_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadLoading: false,
        successMessage: "",
        errorMessage: "",
      };

    case EmailTemplateTypes.DOWNLOAD_TEMPLATE_FAILURE:
      return {
        ...state,
        downloadLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // remove template data
    case EmailTemplateTypes.REMOVE_TEMPLATE_DATA:
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };

    default:
      return state;
  }
}
