// import npm packages
import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import { useAuth0 } from "@auth0/auth0-react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { dashRoutes, adminRoutes } from "routes.js";
import { ROLE_KEY } from "utils/constants/AuthConfig.js";
import { adminUserRole } from "utils/constants/index.js";
import { getActiveRoute, getIsAgency } from "utils/helpers/index.js";
import { getBeeTemplates, getClientEmails } from "redux/EmailEditor/action.js";

// style
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
const useStyles = makeStyles(styles);

var ps;

export default function Dashboard(props) {
  const { ...rest } = props;
  const { user: authUser } = useAuth0();

  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);

  // Redux store methods
  const dispatch = useDispatch();
  const beeTemplates = useSelector(({ EmailEditor }) =>
    Object.keys(EmailEditor.beeTemplates).length ? EmailEditor.beeTemplates : []
  );
  const lastEvaluatedKey = useSelector(({ EmailEditor }) =>
    EmailEditor.clientEmails &&
    Object.keys(EmailEditor.clientEmails.emaildata).length
      ? EmailEditor.clientEmails.emaildata.LastEvaluatedKey
      : ""
  );
  const emailTag = useSelector(({ EmailEditor }) =>
    EmailEditor.emailTagSelection ? EmailEditor.emailTagSelection : ""
  );

  // styles
  const classes = useStyles();

  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.useRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  // lazy-loading
  const handleScroll = () => {
    const { scrollTop, scrollHeight, offsetHeight } = mainPanel.current;
    const isAgency = getIsAgency(authUser);
    if (authUser[ROLE_KEY] != adminUserRole) {
      if (lastEvaluatedKey && scrollTop == scrollHeight - offsetHeight) {
        dispatch(
          getClientEmails(
            {
              clientAcronym: authUser[ROLE_KEY],
              lastEvaluatedKey: lastEvaluatedKey ? lastEvaluatedKey : "",
            },
            isAgency
          )
        );
      }
    } else {
      if (beeTemplates.next && scrollTop == scrollHeight - offsetHeight) {
        dispatch(
          getBeeTemplates(
            beeTemplates.next.substring(beeTemplates.next.lastIndexOf("?")),
            isAgency
          )
        );
      }
    }
  };

  let routes =
    authUser && authUser[ROLE_KEY] == adminUserRole ? adminRoutes : dashRoutes;

  // event listner on scroll
  React.useEffect(() => {
    if (
      getActiveRoute(routes) == "Email Editor" &&
      mainPanel.current &&
      !emailTag
    ) {
      mainPanel.current.addEventListener("scroll", handleScroll, false);
    }
    return () =>
      mainPanel.current &&
      mainPanel.current.removeEventListener("scroll", handleScroll);
  }, [lastEvaluatedKey, beeTemplates, emailTag]);

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"BuildR"}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={"rose"}
        bgColor={"white"}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        <div className={classes.content}>
          <div className={classes.container}>
            <Switch>
              {getRoutes(routes)}
              <Redirect
                from="/admin"
                to={
                  authUser && authUser[ROLE_KEY] == adminUserRole
                    ? "/admin/create-email-template"
                    : "/admin/marketing-settings"
                }
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
