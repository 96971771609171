// core components
import CreateEmailTemplate from "views/EmailTemplate/Create.js";
import EmailTemplate from "views/EmailTemplate/View.js";
import Client from "views/Client/View.js";
import Login from "views/Login/login.js";
import TemplateBatchHistory from "views/BatchHistory/View.js";
import MarketingSettings from "views/MarketingSettings/index.js";
import EmailEditor from "views/EmailEditor/View.js";
import Edit from "views/EmailEditor/Edit.js";
// import LightboxView from "views/Lightbox/View.js";
// import LightboxEditor from "views/Lightbox/Edit.js";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import HistoryIcon from "@material-ui/icons/History";
import PersonIcon from "@material-ui/icons/Person";
import EditIcon from "@material-ui/icons/Edit";

const commonRoutes = [
  {
    path: "/email-editor",
    name: "Email Editor",
    id: "email_editor",
    icon: EditIcon,
    component: EmailEditor,
    layout: "/admin",
    display: true,
  },
  {
    path: "/edit/:id/:name",
    component: Edit,
    layout: "/admin",
    display: false,
  },
  // {
  //   path: "/lightbox/edit/:id?",
  //   name: "Editor",
  //   component: LightboxEditor,
  //   layout: "/admin",
  //   display: false,
  // },
  // {
  //   path: "/lightbox",
  //   name: "Lightbox Editor",
  //   icon: EditIcon,
  //   component: LightboxView,
  //   layout: "/admin",
  //   display: true,
  // },
];

// client user routes
export const dashRoutes = [
  // Auth routes
  {
    path: "/login",
    name: "BuildR",
    component: Login,
    layout: "/auth",
    display: false,
  },
  {
    path: "/marketing-settings",
    name: "Marketing Settings",
    icon: PersonIcon,
    component: MarketingSettings,
    layout: "/admin",
    display: true,
  },
  ...commonRoutes,
];

// Admin routes
export const adminRoutes = [
  {
    path: "/create-email-template",
    name: "Create Email Template",
    icon: DashboardIcon,
    component: CreateEmailTemplate,
    layout: "/admin",
    display: true,
  },
  {
    path: "/email-template",
    name: "Select Email Templates",
    icon: Apps,
    component: EmailTemplate,
    layout: "/admin",
    display: true,
  },
  {
    path: "/client",
    name: "Manage Client Data",
    icon: "content_paste",
    component: Client,
    layout: "/admin",
    display: true,
  },
  {
    path: "/batch-history",
    name: "Batch History",
    icon: HistoryIcon,
    component: TemplateBatchHistory,
    layout: "/admin",
    display: true,
  },
  ...commonRoutes,
];
