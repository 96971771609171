// import npm packages
import { takeLatest, call, put, all } from "redux-saga/effects";

// core components
import {
  getRequestAPI,
  getRequestAPIWithHeader,
  putRequestAPI,
  postRequestAPIWithoutBaseUrl,
  patchRequestAPIWithoutBaseUrl,
  deleteRequestAPI,
} from "utils/services/api/index.js";
import {
  publishEmailUrl,
  getBeeTemplatesUrl,
  getBeeSummitElementsUrl,
  clientEmailsUrl,
  beeLoginUrl,
} from "redux/baseUrl";
import {
  beeProLogin,
  beeProLoginFailure,
  beeProLoginSuccess,
  getBeeTemplatesFailure,
  getBeeTemplatesSuccess,
  getClientEmailsFailure,
  getClientEmailsSuccess,
  getSingleBeeTemplateFailure,
  getSingleBeeTemplateSuccess,
  publishEmailFailure,
  publishEmailSuccess,
  clientEmailCopySuccess,
  clientEmailCopyFailure,
  exportClientEmailFailure,
  exportClientEmailSuccess,
  downloadClientEmailSuccess,
  downloadClientEmailFailure,
  getClientEmailByIdSuccess,
  getEmailTagsSuccess,
  getEmailTagsFailure,
  getClientEmailsByTagSuccess,
  getClientEmailsByTagFailure,
  deleteClientEmailSuccess,
  deleteClientEmailFailure,
  saveBeeTemplateSuccess,
  saveBeeTemplateFailure,
  updateClientEmailSuccess,
  updateClientEmailFailure,
} from "./action";
import EmailEditorTypes from "./type";
let templatesUrl = getBeeTemplatesUrl;

// BeePro login
export function* beeProLoginRequest() {
  try {
    const loginCred = {
      email: process.env.REACT_APP_EMAIL,
      password: process.env.REACT_APP_PASSWORD,
    };

    const response = yield call(postRequestAPIWithoutBaseUrl, {
      url: beeLoginUrl,
      data: loginCred,
    });

    if (response.token || response.access) {
      localStorage.setItem("auth_token", response.token || response.access);
      yield put(beeProLoginSuccess(response));
    } else {
      yield put(beeProLoginFailure("Login Failed!"));
    }
  } catch (error) {
    yield put(beeProLoginFailure(error.message));
  }
}

// publish email to client view
export function* publishEmailRequest(action) {
  try {
    const response = yield call(putRequestAPI, {
      url: publishEmailUrl,
      data: action.payload,
    });

    if (response.success) {
      yield put(publishEmailSuccess(response));
    } else {
      yield put(publishEmailFailure(response.msg));
    }
  } catch (error) {
    yield put(publishEmailFailure(error.message));
  }
}

// create client email copy
export function* clientEmailCopyRequest(action) {
  try {
    const response = yield call(putRequestAPI, {
      url: `${clientEmailsUrl}copy`,
      data: action.payload,
    });

    if (response.success) {
      yield put(clientEmailCopySuccess(response));
    } else {
      yield put(clientEmailCopyFailure(response.msg));
    }
  } catch (error) {
    yield put(clientEmailCopyFailure(error.message));
  }
}

// export client html email
export function* exportClientEmailRequest(action) {
  try {
    const response = yield call(putRequestAPI, {
      url: `${clientEmailsUrl}export`,
      data: action.payload,
    });

    if (response.success) {
      yield put(exportClientEmailSuccess(response));
      response.payload && window.open(response.payload);
    } else {
      yield put(exportClientEmailFailure(response.msg));
    }
  } catch (error) {
    yield put(exportClientEmailFailure(error.message));
  }
}

// Download bulk client emails
export function* downloadClientEmailRequest(action) {
  try {
    const response = yield call(putRequestAPI, {
      url: `${clientEmailsUrl}download`,
      data: action.payload,
    });

    if (response.success) {
      yield put(downloadClientEmailSuccess(response));
      response.payload && window.open(response.payload);
    } else {
      yield put(downloadClientEmailFailure(response.msg));
    }
  } catch (error) {
    yield put(downloadClientEmailFailure(error.message));
  }
}

// get all BeePro templates
export function* getBeeTemplatesRequest(action) {
  try {
    if (action?.userData) {
      templatesUrl =
        action?.userData[0] == "buildr"
          ? getBeeTemplatesUrl
          : getBeeSummitElementsUrl;
    } else {
      templatesUrl = getBeeTemplatesUrl;
    }
    // get auth-token from localStorage
    const auth_token = localStorage.getItem("auth_token");
    if (!auth_token) {
      yield put(beeProLogin());
    }

    const response = yield call(getRequestAPIWithHeader, {
      url: `${templatesUrl}messages/${action.payload}`,
      headerParams: {
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      },
    });

    if (response.results && response.results.length) {
      yield put(getBeeTemplatesSuccess(response));
    } else {
      yield put(beeProLogin());
      yield put(getBeeTemplatesFailure(""));
    }
  } catch (error) {
    yield put(getBeeTemplatesFailure(error.message));
  }
}

// get email tags
export function* getEmailTagsRequest(action) {
  try {
    if (action?.userData) {
      templatesUrl =
        action?.userData[0] == "buildr"
          ? getBeeTemplatesUrl
          : getBeeSummitElementsUrl;
    } else {
      templatesUrl = getBeeTemplatesUrl;
    }
    // get auth-token from localStorage
    const auth_token = localStorage.getItem("auth_token");
    if (!auth_token) {
      yield put(beeProLogin());
    }

    const response = yield call(getRequestAPIWithHeader, {
      url: `${templatesUrl}messagetags`,
      headerParams: {
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      },
    });

    if (response && response.length) {
      yield put(getEmailTagsSuccess(response));
    } else {
      yield put(beeProLogin());
      yield put(getEmailTagsFailure(""));
    }
  } catch (error) {
    yield put(getEmailTagsFailure(error.message));
  }
}

// get selected BeePro template
export function* getSingleBeeTemplateRequest(action) {
  try {
    if (action?.userData) {
      templatesUrl =
        action?.userData[0] == "buildr"
          ? getBeeTemplatesUrl
          : getBeeSummitElementsUrl;
    } else {
      templatesUrl = getBeeTemplatesUrl;
    }
    // get auth-token from localStorage
    const auth_token = localStorage.getItem("auth_token");
    if (!auth_token) {
      yield put(beeProLogin());
    }

    const response = yield call(getRequestAPIWithHeader, {
      url: `${templatesUrl}messages/${action.payload.id}`,
      headerParams: {
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      },
    });

    // message id from url
    const messageId = window.location.pathname.split("/")[3];

    if (response.id == messageId) {
      yield put(getSingleBeeTemplateSuccess(response));
    } else {
      yield put(beeProLogin());
      yield put(getSingleBeeTemplateFailure(""));
    }
  } catch (error) {
    yield put(getSingleBeeTemplateFailure(error.message));
  }
}

// get client emails
export function* getClientEmailsRequest(action) {
  try {
    const response = yield call(putRequestAPI, {
      url: `${clientEmailsUrl}?client_acronym=${
        action.payload.clientAcronym ? action.payload.clientAcronym : ""
      }&clientEmailId=${
        action.payload.clientEmailId ? action.payload.clientEmailId : ""
      }&isAgencyData=${JSON.stringify(action.userData)}`,
      data: action.payload,
    });

    if (response.success) {
      action.payload.clientEmailId
        ? yield put(getClientEmailByIdSuccess(response.payload))
        : yield put(getClientEmailsSuccess(response.payload));
    } else {
      yield put(getClientEmailsFailure(response.msg));
    }
  } catch (error) {
    yield put(getClientEmailsFailure(error.message));
  }
}

// get client emails by tag
export function* getClientEmailsByTagRequest(action) {
  try {
    const response = yield call(getRequestAPI, {
      url: `${clientEmailsUrl}${action.payload.client_acronym}/?email_tag=${
        action.payload.email_tag
      }&isAgencyData=${JSON.stringify(action.payload.is_agency)}`,
    });

    if (response.success) {
      yield put(getClientEmailsByTagSuccess(response.payload));
    } else {
      yield put(getClientEmailsByTagFailure(response.msg));
    }
  } catch (error) {
    yield put(getClientEmailsByTagFailure(error.message));
  }
}

// save bee email template changes
export function* saveBeeTemplateRequest(action) {
  try {
    if (action?.userData) {
      templatesUrl =
        action?.userData[0] == "buildr"
          ? getBeeTemplatesUrl
          : getBeeSummitElementsUrl;
    } else {
      templatesUrl = getBeeTemplatesUrl;
    }
    // get auth-token from localStorage
    const auth_token = localStorage.getItem("auth_token");
    if (!auth_token) {
      yield put(beeProLogin());
    }

    const response = yield call(patchRequestAPIWithoutBaseUrl, {
      url: `${templatesUrl}messages/${action.payload.id}`,
      data: action.payload.jsonData,
      options: {
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      },
    });

    if (response?.id) {
      yield put(saveBeeTemplateSuccess(response));
    } else {
      yield put(beeProLogin());
      yield put(saveBeeTemplateFailure(""));
    }
  } catch (error) {
    yield put(saveBeeTemplateFailure(error.message));
  }
}

// update client email
export function* updateEmailRequest(action) {
  try {
    const response = yield call(putRequestAPI, {
      url: `${clientEmailsUrl}update/${action.payload.clientEmailEditorId}`,
      data: action.payload,
    });

    if (response.success) {
      yield put(updateClientEmailSuccess(response));
    } else {
      yield put(updateClientEmailFailure(response.msg));
    }
  } catch (error) {
    yield put(updateClientEmailFailure(error.message));
  }
}

// delete client email
export function* deleteClientEmailRequest(action) {
  try {
    const response = yield call(deleteRequestAPI, {
      url: clientEmailsUrl + action.payload.templateId,
      data: action.payload,
    });

    if (response.success) {
      yield put(deleteClientEmailSuccess(response));
    } else {
      yield put(deleteClientEmailFailure(response.msg));
    }
  } catch (error) {
    yield put(deleteClientEmailFailure(error.message));
  }
}

export function* watchClient() {
  yield takeLatest(EmailEditorTypes.BEE_LOGIN, beeProLoginRequest);
  yield takeLatest(EmailEditorTypes.PUBLISH_EMAIL, publishEmailRequest);
  yield takeLatest(EmailEditorTypes.CLIENT_EMAIL_COPY, clientEmailCopyRequest);
  yield takeLatest(
    EmailEditorTypes.EXPORT_CLIENT_EMAIL,
    exportClientEmailRequest
  );
  yield takeLatest(
    EmailEditorTypes.DOWNLOAD_CLIENT_EMAIL,
    downloadClientEmailRequest
  );
  yield takeLatest(EmailEditorTypes.GET_BEE_TEMPLATES, getBeeTemplatesRequest);
  yield takeLatest(EmailEditorTypes.GET_EMAIL_TAGS, getEmailTagsRequest);
  yield takeLatest(
    EmailEditorTypes.GET_SINGLE_BEE_TEMPLATE,
    getSingleBeeTemplateRequest
  );
  yield takeLatest(EmailEditorTypes.GET_CLIENT_EMAILS, getClientEmailsRequest);
  yield takeLatest(
    EmailEditorTypes.GET_CLIENT_EMAILS_BY_TAG,
    getClientEmailsByTagRequest
  );
  yield takeLatest(EmailEditorTypes.SAVE_BEE_TEMPLATE, saveBeeTemplateRequest);
  yield takeLatest(EmailEditorTypes.UPDATE_CLIENT_EMAIL, updateEmailRequest);
  yield takeLatest(
    EmailEditorTypes.DELETE_CLIENT_EMAIL,
    deleteClientEmailRequest
  );
}

export function* EmailEditorSaga() {
  yield all([call(watchClient)]);
}
