import EmailTemplateTypes from "./type";

// uplaod email template
export const uploadTemplate = (payload, userData) => {
  return {
    type: EmailTemplateTypes.UPLOAD_TEMPLATE,
    payload,
    userData,
  };
};

export const uploadTemplateSuccess = (payload) => {
  return {
    type: EmailTemplateTypes.UPLOAD_TEMPLATE_SUCCESS,
    payload,
  };
};

export const uploadTemplateFailure = (payload) => {
  return {
    type: EmailTemplateTypes.UPLOAD_TEMPLATE_FAILURE,
    payload,
  };
};

// create email templates
export const createEmailTemplate = (payload) => {
  return {
    type: EmailTemplateTypes.CREATE_EMAILS,
    payload,
  };
};

export const createEmailTemplateSuccess = (payload) => {
  return {
    type: EmailTemplateTypes.CREATE_EMAILS_SUCCESS,
    payload,
  };
};

export const createEmailTemplateFailure = (payload) => {
  return {
    type: EmailTemplateTypes.CREATE_EMAILS_FAILURE,
    payload,
  };
};

// create URL CONSTRUCT
export const createUrlConstruct = (data) => {
  return {
    type: EmailTemplateTypes.CREATE_URL_CONSTRUCT,
    payload: data,
  };
};

// get email templates
export const getTemplates = (payload) => {
  return {
    type: EmailTemplateTypes.GET_TEMPLATES,
    payload,
  };
};

export const getTemplatesSuccess = (payload) => {
  return {
    type: EmailTemplateTypes.GET_TEMPLATES_SUCCESS,
    payload,
  };
};

export const getTemplatesFailure = (payload) => {
  return {
    type: EmailTemplateTypes.GET_TEMPLATES_FAILURE,
    payload,
  };
};

// get email template variables
export const getTemplateVariables = (payload, userData) => {
  return {
    type: EmailTemplateTypes.GET_TEMPLATE_VARIABLES,
    payload,
    userData,
  };
};

export const getTemplateVariablesSuccess = (payload) => {
  return {
    type: EmailTemplateTypes.GET_TEMPLATE_VARIABLES_SUCCESS,
    payload,
  };
};

export const getTemplateVariablesFailure = (payload) => {
  return {
    type: EmailTemplateTypes.GET_TEMPLATE_VARIABLES_FAILURE,
    payload,
  };
};

// update email template status
export const updateTemplateStatus = (payload, userData) => {
  return {
    type: EmailTemplateTypes.UPDATE_TEMPLATE_STATUS,
    payload,
    userData,
  };
};

export const updateTemplateStatusSuccess = (payload) => {
  return {
    type: EmailTemplateTypes.UPDATE_TEMPLATE_STATUS_SUCCESS,
    payload,
  };
};

export const updateTemplateStatusFailure = (payload) => {
  return {
    type: EmailTemplateTypes.UPDATE_TEMPLATE_STATUS_FAILURE,
    payload,
  };
};

// download email template
export const downloadTemplate = (payload) => {
  return {
    type: EmailTemplateTypes.DOWNLOAD_TEMPLATE,
    payload,
  };
};

export const downloadTemplateSuccess = (payload) => {
  return {
    type: EmailTemplateTypes.DOWNLOAD_TEMPLATE_SUCCESS,
    payload,
  };
};

export const downloadTemplateFailure = (payload) => {
  return {
    type: EmailTemplateTypes.DOWNLOAD_TEMPLATE_FAILURE,
    payload,
  };
};

// remove template data
export const removeTemplateData = () => {
  return {
    type: EmailTemplateTypes.REMOVE_TEMPLATE_DATA,
  };
};
