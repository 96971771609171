const EmailTemplateTypes = {
  // uplaod email template
  UPLOAD_TEMPLATE: "UPLOAD_TEMPLATE",
  UPLOAD_TEMPLATE_SUCCESS: "UPLOAD_TEMPLATE_SUCCESS",
  UPLOAD_TEMPLATE_FAILURE: "UPLOAD_TEMPLATE_FAILURE",

  // get email templates
  GET_TEMPLATES: "GET_TEMPLATES",
  GET_TEMPLATES_SUCCESS: "GET_TEMPLATES_SUCCESS",
  GET_TEMPLATES_FAILURE: "GET_TEMPLATES_FAILURE",

  // create email templates
  CREATE_EMAILS: "CREATE_EMAILS",
  CREATE_EMAILS_SUCCESS: "CREATE_EMAILS_SUCCESS",
  CREATE_EMAILS_FAILURE: "CREATE_EMAILS_FAILURE",

  // get email variables
  GET_TEMPLATE_VARIABLES: "GET_TEMPLATE_VARIABLES",
  GET_TEMPLATE_VARIABLES_SUCCESS: "GET_TEMPLATE_VARIABLES_SUCCESS",
  GET_TEMPLATE_VARIABLES_FAILURE: "GET_TEMPLATE_VARIABLES_FAILURE",

  // update email template status
  UPDATE_TEMPLATE_STATUS: "UPDATE_TEMPLATE_STATUS",
  UPDATE_TEMPLATE_STATUS_SUCCESS: "UPDATE_TEMPLATE_STATUS_SUCCESS",
  UPDATE_TEMPLATE_STATUS_FAILURE: "UPDATE_TEMPLATE_STATUS_FAILURE",

  // download email template
  DOWNLOAD_TEMPLATE: "DOWNLOAD_TEMPLATE",
  DOWNLOAD_TEMPLATE_SUCCESS: "DOWNLOAD_TEMPLATE_SUCCESS",
  DOWNLOAD_TEMPLATE_FAILURE: "DOWNLOAD_TEMPLATE_FAILURE",

  // remove template data
  REMOVE_TEMPLATE_DATA: "REMOVE_TEMPLATE_DATA",

  // create URL CONSTRUCT
  CREATE_URL_CONSTRUCT: "CREATE_URL_CONSTRUCT",
};

export default EmailTemplateTypes;
