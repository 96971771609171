import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import styles from "assets/jss/material-dashboard-pro-react/components/customCircularProgressStyle.js";

const useStyles = makeStyles(styles);

export default function CustomCircularProgress(props) {
  // const [open, setOpen] = useState(true);
  // const handleClose = () => {
  //   setOpen(false);
  // };

  const classes = useStyles();
  const { loading, ...rest } = props;
  return (
    <Backdrop open={loading} className={classes.rootZIndex}>
      <CircularProgress
        {...rest}
        size={70}
        sx={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 2,
        }}
        color="secondary"
        // classes={{
        //   root: classes.root + " " + classes[color + "Background"],
        //   bar: classes.bar + " " + classes[color],
        // }}
      />
    </Backdrop>
  );
}

CustomCircularProgress.defaultProps = {
  loading: true,
};

CustomCircularProgress.propTypes = {
  loading: PropTypes.bool,
};
