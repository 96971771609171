// import npm packages
import { all, call, put, takeLatest } from "@redux-saga/core/effects";

// core components
import { getRequestAPI, putRequestAPI } from "utils/services/api";
import { getBatchHistoryUrl, downloadBatchTemplateUrl } from "redux/baseUrl";
import {
  getBatchHistorySuccess,
  getBatchHistoryFailure,
  downloadBatchTemplateSuccess,
  downloadBatchTemplateFailure,
} from "./action";
import BatchHistoryTypes from "./type";

export function* getBatchHistoryRequest(action) {
  try {
    const response = yield call(getRequestAPI, {
      url:
        getBatchHistoryUrl + `?isAgencyData=${JSON.stringify(action.payload)}`,
    });

    if (response.success) {
      yield put(getBatchHistorySuccess(response.payload));
    } else {
      yield put(getBatchHistoryFailure(response.msg));
    }
  } catch (error) {
    yield put(getBatchHistoryFailure(error.message));
  }
}

// download batch template request
export function* downloadBatchTemplateRequest(action) {
  try {
    const response = yield call(putRequestAPI, {
      url: downloadBatchTemplateUrl,
      data: action.payload,
    });

    if (response.success) {
      if (response.payload) window.location.href = response.payload;
      yield put(downloadBatchTemplateSuccess(response));
    } else {
      yield put(downloadBatchTemplateFailure(response.msg));
    }
  } catch (error) {
    yield put(downloadBatchTemplateFailure(error.message));
  }
}

export function* watchClient() {
  yield takeLatest(BatchHistoryTypes.GET_BATCH_HISTORY, getBatchHistoryRequest);
  yield takeLatest(
    BatchHistoryTypes.DOWNLOAD_BATCH_TEMPLATES,
    downloadBatchTemplateRequest
  );
}

export function* BatchHistorySaga() {
  yield all([call(watchClient)]);
}
