// import npm packages
import { takeLatest, call, put, all } from "redux-saga/effects";

// core components
import { adminUserRole } from "utils/constants/index.js";
import { clientUrl, uploadImageUrl } from "redux/baseUrl";
import {
  postRequestAPI,
  getRequestAPI,
  patchRequestAPI,
  putS3RequestAPI,
} from "utils/services/api";
import {
  insertClientSuccess,
  insertClientFailure,
  getClient,
  getClientSuccess,
  getClientFailure,
  getClientByAcronymSuccess,
  getClientByAcronymFailure,
  getClientByCategorySuccess,
  getClientByCategoryFailure,
  updateClientSuccess,
  updateClientFailure,
  uploadImageSuccess,
  uploadSignatureImageSuccess,
  uploadImageFailure,
} from "./action";
import ClientTypes from "./type";

// insert client
export function* makeInsertClientRequest(action) {
  try {
    const response = yield call(postRequestAPI, {
      url: clientUrl,
      data: action.payload,
    });

    if (response.success) {
      yield put(insertClientSuccess(response));
      yield put(getClient(action.payload.is_agency));
    } else {
      yield put(insertClientFailure(response.msg));
    }
  } catch (error) {
    yield put(insertClientFailure(error.message));
  }
}

// get all clients
export function* makeGetClientsRequest(action) {
  try {
    const response = yield call(getRequestAPI, {
      url: clientUrl + `?isAgencyData=${JSON.stringify(action.payload)}`,
    });

    if (response.success) {
      yield put(getClientSuccess(response.payload));
    } else {
      yield put(getClientFailure(response.msg));
    }
  } catch (error) {
    yield put(getClientFailure(error.message));
  }
}

// get client data by acronym
export function* getClientDetailsByAcronym(action) {
  try {
    const response = yield call(getRequestAPI, {
      url: clientUrl + action.payload,
    });

    if (response.success) {
      yield put(getClientByAcronymSuccess(response.payload));
    } else {
      yield put(getClientByAcronymFailure(response.msg));
    }
  } catch (error) {
    yield put(getClientByAcronymFailure(error.message));
  }
}

// get clients by category
export function* getClientByCategoryRequest(action) {
  try {
    const response = yield call(getRequestAPI, {
      url:
        clientUrl +
        `?client_category=${action.payload}&isAgencyData=${JSON.stringify(
          action.userData
        )}`,
    });

    if (response.success) {
      yield put(getClientByCategorySuccess(response.payload));
    } else {
      yield put(getClientByCategoryFailure(response.msg));
    }
  } catch (error) {
    yield put(getClientByCategoryFailure(error.message));
  }
}

// update client details
export function* makeUpdateClientRequest(action) {
  try {
    const response = yield call(patchRequestAPI, {
      url: clientUrl + action.payload.acronym,
      data: action.payload,
    });

    if (response.success) {
      yield put(updateClientSuccess(response));
      action.payload.role == adminUserRole &&
        (yield put(getClient(action.payload.is_agency)));
    } else {
      yield put(updateClientFailure(response.msg));
    }
  } catch (error) {
    yield put(updateClientFailure(error.message));
  }
}

// upload images
export function* UploadImagesRequest(action) {
  try {
    const response = yield call(postRequestAPI, {
      url: uploadImageUrl,
      data: action.payload,
    });

    if (response.success) {
      const { fileLocation, preSignedS3URL } = response.payload;

      const fileData = action.payload.get("file");

      // Upload file to S3 using presigned URL
      const s3response = yield call(putS3RequestAPI, {
        url: preSignedS3URL,
        data: fileData,
        options: {
          headers: { "Content-Type": fileData.type },
        },
      });

      // If we got response as empty means file is uploaded successfully
      if (s3response == "" || s3response.response.status == 200) {
        if (action.payload.get("upload_type") == 1) {
          yield put(uploadImageSuccess(fileLocation));
        } else {
          yield put(uploadSignatureImageSuccess(fileLocation));
        }
      } else {
        yield put(
          uploadImageFailure("Something went wrong! Please try again later.")
        );
      }
    } else {
      yield put(uploadImageFailure(response.msg));
    }
  } catch (error) {
    yield put(uploadImageFailure(error.message));
  }
}

export function* watchClient() {
  yield takeLatest(ClientTypes.GET_CLIENT, makeGetClientsRequest);
  yield takeLatest(
    ClientTypes.GET_CLIENT_BY_ACRONYM,
    getClientDetailsByAcronym
  );
  yield takeLatest(
    ClientTypes.GET_CLIENT_BY_CATEGORY,
    getClientByCategoryRequest
  );
  yield takeLatest(ClientTypes.INSERT_CLIENT, makeInsertClientRequest);
  yield takeLatest(ClientTypes.UPDATE_CLIENT, makeUpdateClientRequest);
  yield takeLatest(ClientTypes.UPLOAD_IMAGES, UploadImagesRequest);
}

export function* ClientSaga() {
  yield all([call(watchClient)]);
}
