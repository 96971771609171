import { combineReducers } from "redux";
import ClientReducer from "./Client/reducer";
import EmailTemplateReducer from "./EmailTemplate/reducer";
import BatchHistoryReducer from "./BatchHistory/reducer";
import EmailEditorReducer from "./EmailEditor/reducer";
import LightboxReducer from "./Lightbox/reducer";

const RootReducer = combineReducers({
  EmailTemplate: EmailTemplateReducer,
  EmailClient: ClientReducer,
  BatchHistory: BatchHistoryReducer,
  EmailEditor: EmailEditorReducer,
  Lightbox: LightboxReducer,
});
export default RootReducer;
