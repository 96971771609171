import LightboxTypes from "./type";

// create
export const createLightbox = (payload) => {
  return {
    type: LightboxTypes.CREATE_LIGHTBOX,
    payload,
  };
};

export const createLightboxSuccess = (payload) => {
  return {
    type: LightboxTypes.CREATE_LIGHTBOX_SUCCESS,
    payload,
  };
};

export const createLightboxWarning = (payload) => {
  return {
    type: LightboxTypes.CREATE_LIGHTBOX_WARNING,
    payload,
  };
};

export const createLightboxFailure = (payload) => {
  return {
    type: LightboxTypes.CREATE_LIGHTBOX_FAILURE,
    payload,
  };
};

// get all
export const getLightbox = () => {
  return {
    type: LightboxTypes.GET_LIGHTBOXES,
  };
};

export const getLightboxSuccess = (payload) => {
  return {
    type: LightboxTypes.GET_LIGHTBOXES_SUCCESS,
    payload,
  };
};

export const getLightboxFailure = (payload) => {
  return {
    type: LightboxTypes.GET_LIGHTBOXES_FAILURE,
    payload,
  };
};

// get by id
export const getLightboxById = (payload) => {
  return {
    type: LightboxTypes.GET_LIGHTBOX_BY_ID,
    payload,
  };
};

export const getLightboxByIdSuccess = (payload) => {
  return {
    type: LightboxTypes.GET_LIGHTBOX_BY_ID_SUCCESS,
    payload,
  };
};

export const getLightboxByIdFailure = (payload) => {
  return {
    type: LightboxTypes.GET_LIGHTBOX_BY_ID_FAILURE,
    payload,
  };
};

export const removeLightboxData = () => {
  return {
    type: LightboxTypes.REMOVE_DATA,
  };
};

// update
export const updateLightbox = (payload) => {
  return {
    type: LightboxTypes.UPDATE_LIGHTBOX,
    payload,
  };
};

export const updateLightboxSuccess = (payload) => {
  return {
    type: LightboxTypes.UPDATE_LIGHTBOX_SUCCESS,
    payload,
  };
};

export const updateLightboxWarning = (payload) => {
  return {
    type: LightboxTypes.UPDATE_LIGHTBOX_WARNING,
    payload,
  };
};

export const updateLightboxFailure = (payload) => {
  return {
    type: LightboxTypes.UPDATE_LIGHTBOX_FAILURE,
    payload,
  };
};

// schedule
export const scheduleLightbox = (payload) => {
  return {
    type: LightboxTypes.SCHEDULE_LIGHTBOX,
    payload,
  };
};

export const scheduleLightboxSuccess = (payload) => {
  return {
    type: LightboxTypes.SCHEDULE_LIGHTBOX_SUCCESS,
    payload,
  };
};

export const scheduleLightboxWarning = (payload) => {
  return {
    type: LightboxTypes.SCHEDULE_LIGHTBOX_WARNING,
    payload,
  };
};

export const scheduleLightboxFailure = (payload) => {
  return {
    type: LightboxTypes.SCHEDULE_LIGHTBOX_FAILURE,
    payload,
  };
};

// copy
export const copyLightbox = (payload) => {
  return {
    type: LightboxTypes.COPY_LIGHTBOX,
    payload,
  };
};

export const copyLightboxSuccess = (payload) => {
  return {
    type: LightboxTypes.COPY_LIGHTBOX_SUCCESS,
    payload,
  };
};

export const copyLightboxFailure = (payload) => {
  return {
    type: LightboxTypes.COPY_LIGHTBOX_FAILURE,
    payload,
  };
};
