import { btnLabel } from "utils/constants";
import LightboxTypes from "./type";

const initialState = {
  createLoading: false,
  loading: false,
  singleLightboxLoading: false,
  updateLoading: false,
  skipLoading: false,
  scheduleLoading: false,
  copyLoading: false,
  lightboxesList: [],
  singleLightbox: "",
  successMessage: "",
  errorMessage: "",
  warningMessage: "",
};

export default function LightboxReducer(state = initialState, action) {
  switch (action.type) {
    // create
    case LightboxTypes.CREATE_LIGHTBOX:
      return {
        ...state,
        createLoading: action.payload.btnType == btnLabel.SAVE,
        skipLoading: action.payload.btnType == btnLabel.SKIP,
        successMessage: "",
        errorMessage: "",
        warningMessage: "",
      };

    case LightboxTypes.CREATE_LIGHTBOX_SUCCESS:
      return {
        ...state,
        createLoading: action.payload.btnType == btnLabel.SAVE,
        skipLoading: action.payload.btnType == btnLabel.SKIP,
        successMessage: action.payload.msg,
        errorMessage: "",
        warningMessage: "",
      };

    case LightboxTypes.CREATE_LIGHTBOX_WARNING:
      return {
        ...state,
        createLoading: action.payload.btnType == btnLabel.SAVE,
        skipLoading: action.payload.btnType == btnLabel.SKIP,
        successMessage: "",
        errorMessage: "",
        warningMessage: action.payload,
      };

    case LightboxTypes.CREATE_LIGHTBOX_FAILURE:
      return {
        ...state,
        createLoading: action.payload.btnType == btnLabel.SAVE,
        skipLoading: action.payload.btnType == btnLabel.SKIP,
        successMessage: "",
        errorMessage: action.payload,
        warningMessage: "",
      };

    // get all
    case LightboxTypes.GET_LIGHTBOXES:
      return {
        ...state,
        loading: true,
      };

    case LightboxTypes.GET_LIGHTBOXES_SUCCESS:
      return {
        ...state,
        loading: false,
        lightboxesList: action.payload,
        successMessage: "",
        errorMessage: "",
        warningMessage: "",
      };

    case LightboxTypes.GET_LIGHTBOXES_FAILURE:
      return {
        ...state,
        loading: false,
        successMessage: "",
        errorMessage: action.payload,
        warningMessage: "",
      };

    // get by id
    case LightboxTypes.GET_LIGHTBOX_BY_ID:
      return {
        ...state,
        singleLightboxLoading: true,
        successMessage: "",
        errorMessage: "",
        warningMessage: "",
      };

    case LightboxTypes.GET_LIGHTBOX_BY_ID_SUCCESS:
      return {
        ...state,
        singleLightboxLoading: false,
        singleLightbox: action.payload,
        successMessage: "",
        errorMessage: "",
        warningMessage: "",
      };

    case LightboxTypes.GET_LIGHTBOX_BY_ID_FAILURE:
      return {
        ...state,
        singleLightboxLoading: false,
        successMessage: "",
        errorMessage: action.payload,
        warningMessage: "",
      };

    // update
    case LightboxTypes.UPDATE_LIGHTBOX:
      return {
        ...state,
        updateLoading: action.payload.btnType == btnLabel.SAVE,
        skipLoading: action.payload.btnType == btnLabel.SKIP,
        successMessage: "",
        errorMessage: "",
        warningMessage: "",
      };

    case LightboxTypes.UPDATE_LIGHTBOX_SUCCESS:
      return {
        ...state,
        updateLoading: action.payload.btnType == btnLabel.SAVE,
        skipLoading: action.payload.btnType == btnLabel.SKIP,
        successMessage: action.payload.msg,
        errorMessage: "",
        warningMessage: "",
      };

    case LightboxTypes.UPDATE_LIGHTBOX_WARNING:
      return {
        ...state,
        updateLoading: action.payload.btnType == btnLabel.SAVE,
        skipLoading: action.payload.btnType == btnLabel.SKIP,
        successMessage: "",
        errorMessage: "",
        warningMessage: action.payload,
      };

    case LightboxTypes.UPDATE_LIGHTBOX_FAILURE:
      return {
        ...state,
        updateLoading: action.payload.btnType == btnLabel.SAVE,
        skipLoading: action.payload.btnType == btnLabel.SKIP,
        successMessage: "",
        errorMessage: action.payload,
        warningMessage: "",
      };

    // schedule
    case LightboxTypes.SCHEDULE_LIGHTBOX:
      return {
        ...state,
        scheduleLoading: true,
        successMessage: "",
        errorMessage: "",
        warningMessage: "",
      };

    case LightboxTypes.SCHEDULE_LIGHTBOX_SUCCESS:
      return {
        ...state,
        scheduleLoading: false,
        successMessage: action.payload.msg,
        errorMessage: "",
        warningMessage: "",
      };

    case LightboxTypes.SCHEDULE_LIGHTBOX_WARNING:
      return {
        ...state,
        scheduleLoading: false,
        successMessage: "",
        errorMessage: "",
        warningMessage: action.payload,
      };

    case LightboxTypes.SCHEDULE_LIGHTBOX_FAILURE:
      return {
        ...state,
        scheduleLoading: false,
        successMessage: "",
        errorMessage: action.payload,
        warningMessage: "",
      };

    // copy
    case LightboxTypes.COPY_LIGHTBOX:
      return {
        ...state,
        copyLoading: true,
        successMessage: "",
        errorMessage: "",
        warningMessage: "",
      };

    case LightboxTypes.COPY_LIGHTBOX_SUCCESS:
      return {
        ...state,
        copyLoading: false,
        successMessage: action.payload.msg,
        errorMessage: "",
        warningMessage: "",
      };

    case LightboxTypes.COPY_LIGHTBOX_FAILURE:
      return {
        ...state,
        copyLoading: false,
        successMessage: "",
        errorMessage: action.payload,
        warningMessage: "",
      };

    // remove store data
    case LightboxTypes.REMOVE_DATA:
      return {
        ...state,
        lightboxesList: [],
        singleLightbox: "",
        successMessage: "",
        errorMessage: "",
        warningMessage: "",
      };

    default:
      return state;
  }
}
