import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

// material ui icons
import Close from "@material-ui/icons/Close";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import CustomCircularProgress from "components/CustomCircularProgress/CustomCircularProgress.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import EmailListCard from "components/EmailListCard/EmailListCard.js";
import {
  getBeeTemplates,
  beeProLogin,
  getClientEmails,
  getEmailTags,
  getClientEmailsByTag,
  storeEmailTag,
  removeEmailsByTagData,
  removeEmailEditorMessage,
} from "redux/EmailEditor/action.js";
import { ROLE_KEY } from "utils/constants/AuthConfig.js";
import { adminUserRole } from "utils/constants/index.js";
import { captureElement, getIsAgency } from "utils/helpers/index.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/emailEditorStyle.js";
const useStyles = makeStyles(styles);

export default function View() {
  // styles
  const classes = useStyles();

  // auth0 variables
  const { user } = useAuth0();

  const [BEEemails, setBEEemails] = useState([]); // state to store BEE email templates
  const [filterBEEemails, setFilterBEEemails] = useState([]); // state to store filtered BEE emails
  const [clientEmails, setClientEmails] = useState([]); // state to store client emails
  // Toggle snackbar (Notification)
  const [show, setShow] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    color: "success",
    message: "",
  });

  // get auth-token from localStorage
  const auth_token = localStorage.getItem("auth_token");
  const isAgency = getIsAgency(user);
  // Redux store methods
  const dispatch = useDispatch();
  const emailTemplatesData = useSelector(({ EmailEditor }) => EmailEditor);

  useEffect(() => {
    !auth_token && dispatch(beeProLogin());
    if (user[ROLE_KEY] != adminUserRole) {
      dispatch(
        getClientEmails(
          {
            clientAcronym: user[ROLE_KEY],
            lastEvaluatedKey: emailClients.LastEvaluatedKey
              ? emailClients.LastEvaluatedKey
              : "",
          },
          isAgency
        )
      );
    }
    return () => {
      dispatch(removeEmailEditorMessage());
      dispatch(storeEmailTag(""));
    };
  }, []);

  useEffect(() => {
    if (emailTemplatesData.authToken || auth_token) {
      user[ROLE_KEY] == adminUserRole &&
        dispatch(getBeeTemplates("?page=1&pagesize=8", isAgency));
      dispatch(getEmailTags(isAgency));
    }
  }, [emailTemplatesData.authToken]);

  const beeTemplates = useSelector(({ EmailEditor }) =>
    Object.keys(EmailEditor.beeTemplates).length ? EmailEditor.beeTemplates : []
  );
  const emailClients = useSelector(({ EmailEditor }) =>
    EmailEditor.clientEmails &&
    Object.keys(EmailEditor.clientEmails.emaildata).length
      ? EmailEditor.clientEmails.emaildata
      : []
  );
  const clientHtmlEmail = useSelector(({ EmailEditor }) =>
    EmailEditor.clientHtmlEmails &&
    Object.keys(EmailEditor.clientHtmlEmails).length
      ? EmailEditor.clientHtmlEmails
      : ""
  );
  const filterClientEmails = useSelector(({ EmailEditor }) =>
    Object.keys(EmailEditor.emailsByTag).length
      ? EmailEditor.emailsByTag.filter((item) => item.status != "deleted")
      : []
  );
  const FilteredHtmlEmails = useSelector(({ EmailEditor }) =>
    EmailEditor.FilteredHtmlEmail &&
    Object.keys(EmailEditor.FilteredHtmlEmail).length
      ? EmailEditor.FilteredHtmlEmail
      : ""
  );
  const emailTag = useSelector(({ EmailEditor }) =>
    EmailEditor.emailTagSelection ? EmailEditor.emailTagSelection : ""
  );

  // append next BEE emails with previous ones
  useEffect(() => {
    if (beeTemplates.results && beeTemplates.results.length > 0) {
      setBEEemails([...BEEemails, ...beeTemplates.results]);
    }
  }, [beeTemplates]);

  // append next client emails with previous ones
  useEffect(() => {
    if (emailClients.Items && emailClients.Items.length > 0) {
      // filter emails which don't have status deleted
      const activeEmails = emailClients.Items.filter(
        (item) => item.status != "deleted"
      );
      setClientEmails([...clientEmails, ...activeEmails]);
    }
  }, [emailClients]);

  // filter BEE emails
  useEffect(() => {
    if (user[ROLE_KEY] == adminUserRole) {
      setFilterBEEemails(
        BEEemails.filter((item) => item.tags.find((tag) => tag == emailTag))
      );
    }
  }, [BEEemails, emailTag]);

  const toggleSnackbar = () => setShow(!show);

  useEffect(() => {
    if (user[ROLE_KEY] !== adminUserRole) {
      // If any error found, display in notification
      if (emailTemplatesData.errorMessage) {
        setNotificationProps({
          color: "danger",
          message: emailTemplatesData.errorMessage,
        });
        setShow(true);
      }

      // If email template created successfully, display in notification
      if (emailTemplatesData.successMessage) {
        setNotificationProps({
          color: "success",
          message: emailTemplatesData.successMessage,
        });
        setShow(true);

        if (emailTag) {
          dispatch(
            getClientEmailsByTag({
              client_acronym: user[ROLE_KEY],
              email_tag: emailTag,
              is_agency: isAgency,
            })
          );
        } else {
          setClientEmails([]);
          dispatch(
            getClientEmails(
              {
                clientAcronym: user[ROLE_KEY],
                lastEvaluatedKey: "",
              },
              isAgency
            )
          );
        }
        dispatch(removeEmailEditorMessage());
      }
      // hide notification after specific time
      setTimeout(() => setShow(false), 5000);
    }
  }, [
    emailTemplatesData.successMessage,
    emailTemplatesData.errorMessage,
    emailTag,
  ]);

  // handle email tag selection
  const emailTagSelection = (event) => {
    dispatch(removeEmailsByTagData());
    dispatch(storeEmailTag(event.target.value));
    user[ROLE_KEY] != adminUserRole &&
      dispatch(
        getClientEmailsByTag({
          client_acronym: user[ROLE_KEY],
          email_tag: event.target.value,
          is_agency: isAgency,
        })
      );
  };

  // display BEE emails
  const BEEEmailsList = (template, index) => (
    <EmailListCard
      key={index}
      index={index}
      randonNumber={Math.random().toString(36).substring(2, 15)}
      templatePrev={
        <img
          src={template.thumb_medium}
          alt={template.name}
          height="100%"
          style={{ objectFit: "contain" }}
        />
      }
      editNavlink={`edit/${template.id}/${template.name.replace(/[ ]/g, "_")}`}
      templateName={template.name}
      clientEmails={clientEmails}
    />
  );

  // display client emails
  const clientEmailsList = (email, index) => {
    const randonNumber = Math.random().toString(36).substring(2, 15);
    const emailData = JSON.parse(email.emailDetails);

    // client's HTML email
    const htmlClientEmail = emailTag
      ? FilteredHtmlEmails[email.clientEmailEditorId] &&
        FilteredHtmlEmails[email.clientEmailEditorId]
      : clientHtmlEmail[email.clientEmailEditorId] &&
        clientHtmlEmail[email.clientEmailEditorId];

    // generate HTML email thumbnail, if HTML email is there
    htmlClientEmail && captureElement(htmlClientEmail, randonNumber);

    return (
      <EmailListCard
        index={index}
        key={index}
        randonNumber={randonNumber}
        templatePrev={
          <img
            id={`prev${randonNumber}`}
            src={!htmlClientEmail ? emailData.emailThumbnail : ""}
            alt={emailData.email_name || emailData.template_name}
            height="100%"
            width="100%"
            style={{ objectFit: htmlClientEmail ? "cover" : "contain" }}
          />
        }
        editNavlink={`edit/${email.clientEmailEditorId}/${(
          emailData.email_name || emailData.template_name
        ).replace(/[ ]/g, "_")}`}
        templateName={emailData.email_name || emailData.template_name}
        templateId={email.clientEmailEditorId}
        clientEmails={clientEmails}
      />
    );
  };

  return (
    <div>
      {(filterBEEemails.length > 0 ||
        BEEemails.length > 0 ||
        filterClientEmails.length > 0 ||
        clientEmails.length > 0) && (
        <FormControl
          className={`${classes.selectFormControl} ${classes.filterBlock}`}
        >
          <InputLabel htmlFor="email_tag" className={classes.selectLabel}>
            Choose Email Tag
          </InputLabel>
          <Select
            MenuProps={{ className: classes.selectMenu }}
            classes={{ select: classes.select }}
            style={{ marginBottom: "1.7rem" }}
            value={emailTag}
            onChange={emailTagSelection}
            inputProps={{
              name: "email_tag",
              id: "email_tag",
            }}
            IconComponent={() => {
              if (emailTag) {
                return (
                  <Close
                    color="action"
                    onClick={() => dispatch(storeEmailTag(""))}
                    className={classes.pointerCursor}
                  />
                );
              } else
                return (
                  <ArrowDropDownIcon
                    color="action"
                    className={classes.pointerCursor}
                  />
                );
            }}
          >
            <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
              Choose Email Tag
            </MenuItem>
            {emailTemplatesData.emailTags.length > 0 &&
              emailTemplatesData.emailTags.map((tag, index) => (
                <MenuItem
                  key={`tag${index}`}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={tag.tag}
                >
                  {tag.tag}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
      <GridContainer>
        {user[ROLE_KEY] == adminUserRole
          ? emailTag
            ? filterBEEemails.length > 0 &&
              filterBEEemails.map((template, index) =>
                BEEEmailsList(template, index)
              )
            : BEEemails.length > 0 &&
              BEEemails.map((template, index) => BEEEmailsList(template, index))
          : emailTag
          ? filterClientEmails.length > 0 &&
            filterClientEmails.map((email, index) =>
              clientEmailsList(email, index)
            )
          : clientEmails.length > 0 &&
            clientEmails.map((email, index) => clientEmailsList(email, index))}
        <CustomCircularProgress
          loading={
            user[ROLE_KEY] == adminUserRole
              ? emailTemplatesData.loading ||
                emailTemplatesData.loginLoading ||
                emailTemplatesData.emailTagsLoading
              : emailTemplatesData.clientEmailsLoading ||
                emailTemplatesData.emailsByTagLoading ||
                emailTemplatesData.clientEmailCopyLoading ||
                emailTemplatesData.deleteEmailLoading
          }
        />
        <Snackbar
          color={notificationProps.color}
          message={notificationProps.message}
          place="tr"
          open={show}
          close
          closeNotification={toggleSnackbar}
        />
      </GridContainer>
    </div>
  );
}
