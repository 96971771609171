import {
  blackColor,
  hexToRgb,
} from "assets/jss/material-dashboard-pro-react.js";

const dropzoneStyle = {
  baseStyle: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "45px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.5)",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  focusedStyle: {
    borderColor: "#2196f3",
  },
  acceptStyle: {
    borderColor: "#00e676",
  },
  rejectStyle: {
    borderColor: "#ff1744",
  },
};

export default dropzoneStyle;
