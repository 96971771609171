let ApiBaseURL = "";
let DomainRedirectURL = "";
let HubLoginURL = "";

switch (window.location.hostname) {
  case "localhost":
    // LOCAL
    ApiBaseURL = "http://192.168.2.76:3030";
    DomainRedirectURL = "?http://localhost:3001/admin/create-email-template";
    HubLoginURL = "http://localhost:3000/apps";
    break;

  case "buildr.charitable.one":
    // PRODUCTION
    ApiBaseURL =
      "https://k7bvftpgf6.execute-api.us-east-1.amazonaws.com/production";
    DomainRedirectURL =
      "?https://buildr.charitable.one/admin/create-email-template";
    HubLoginURL = "https://hub-staging.charitable.one/apps";
    break;

  default:
    // STAGE
    ApiBaseURL =
      "https://n5g9y815ah.execute-api.us-east-1.amazonaws.com/development";
    DomainRedirectURL =
      "?https://dev-buildr.charitable.one/admin/create-email-template";
    HubLoginURL = "https://hub-staging.charitable.one/apps";
    break;
}
export const API_BASE_URL = ApiBaseURL;
export const DOMAIN_BASE_URL = DomainRedirectURL;
export const HUB_LOGIN_URL = HubLoginURL;

// user role
export const adminUserRole = "admin";

// validate URL
export const validateUrlRegex = /(https?):\/\/(\w+:?\w*@)?(\S+)(:\d+)?(\/|\/([\w#:.?+=&%@!-]))?/;

// client categories
export const clientCatg = [
  { label: "Humane", value: "Humane" },
  { label: "Food Bank", value: "Food Bank" },
];

// email platform list
export const emailPlatformList = [
  { label: "Mailchimp", value: "Mailchimp" },
  { label: "Constant Contact", value: "Constant Contact" },
  { label: "Luminate Online", value: "Luminate Online" },
  { label: "Other", value: "Other" },
];

// UTM Parameter Fields
export const utmParameters = [
  {
    label: "UTM Source",
    name: "utm_source",
    field: "utmSource",
    type: "text",
  },
  {
    label: "UTM Campaign",
    name: "utm_campaign",
    field: "utmCampaign",
    type: "text",
  },
  {
    label: "UTM Medium",
    name: "utm_medium",
    field: "utmMedium",
    type: "text",
  },
];

// email platform value
export const emailPlatformValue = {
  mailChimp: {
    key: "Mailchimp",
    value: "*|IF:FNAME|* *|TITLE:FNAME|* *|ELSE:|* Friend *|END:IF|*",
  },
  constantContact: {
    key: "Constant Contact",
    value: '[[FIRSTNAME OR "Friend"]]',
  },
  luminateOnline: {
    key: "Luminate Online",
    value: "[[?xx::x[[S1:first_name]]x::Friend::[[S1:first_name]]]]",
  },
  other: {
    key: "Other",
    value: "{{FIRST_NAME}}",
  },
};

// Persistent Client Detail
export const clientVariableName = [
  {
    label: "CLIENT_NAME",
    name: "client_name",
    field: "clientName",
    type: "text",
    tooltipDescr: "Please enter client's name.",
  },
  {
    label: "CLIENT_CATEGORY",
    name: "client_category",
    field: "clientCategory",
    type: "select",
    tooltipDescr: "Please enter client's category.",
  },
  {
    label: "ORGANIZATION_NAME",
    name: "organization_name",
    field: "organizationName",
    type: "text",
    tooltipDescr: "Please enter the full and proper name of your organization.",
  },
  {
    label: "ORGANIZATION_NIC_NAME",
    name: "organization_nic_name",
    field: "organizationNicName",
    type: "text",
    tooltipDescr:
      "Please enter the nic name of your organization, if none, re-enter the full name.",
  },
  {
    label: "COMMUNITY_NAME",
    name: "community_name",
    field: "communityName",
    type: "text",
    tooltipDescr:
      "Please enter the service region of your organization. Example: North East Texas",
  },
  {
    label: "CLIENT_WEBSITE_URL",
    name: "client_website_url",
    field: "clientWebsiteUrl",
    type: "url",
    tooltipDescr:
      "Please enter your organization's homepage URL for the logo link.",
  },
  {
    label: "CLIENT_LOGO_URL",
    name: "client_logo_url",
    field: "clientLogoUrl",
    type: "url",
    tooltipDescr: "Please enter the URL for your organization's logo image.",
    flag: "client_logo",
  },
  {
    label: "SIGNATURE_FILE_URL",
    name: "signature_file_url",
    field: "signatureFileUrl",
    type: "url",
    tooltipDescr:
      "Please enter the URL of your organization's President/CEO's signature image.",
    flag: "signature_image",
  },
  {
    label: "SIGNATURE_NAME",
    name: "signature_name",
    field: "signatureName",
    type: "text",
    tooltipDescr: "Please enter the name of the signature signee.",
  },
  {
    label: "SIGNATURE_TITLE",
    name: "signature_title",
    field: "signatureTitle",
    type: "text",
    tooltipDescr:
      "Please enter the title of the signature signee. Example: President of XYZ",
  },
  {
    label: "DEFAULT_ALGORITHM",
    name: "default_algorithm",
    field: "defaultAlgorithm",
    type: "text",
    tooltipDescr:
      "Please enter the dollar to service ratio. Example: For every $1 five meals = 1:5",
  },
  {
    label: "DOLLAR_AMOUNT",
    name: "dollar_amount",
    field: "dollarAmount",
    type: "text",
    tooltipDescr:
      'Please enter the dollar amount for client algorithm. Example 1 dollar buys 10 meals. You would just store "1" here.',
  },
  {
    label: "MEALS_AMOUNT",
    name: "meals_amount",
    field: "mealsAmount",
    type: "text",
    tooltipDescr:
      'Please enter a number of meals for the client algorithm. Example 1 dollar buys 10 meals. you would just store "10" here.',
  },
  {
    label: "DEFAULT_DF_URL",
    name: "default_df_url",
    field: "defaultDfUrl",
    type: "url",
    tooltipDescr:
      "Please enter a fallback URL for your organization's donation forms. You can overwrite this URL at the time of email creation.",
  },
  {
    label: "EMAIL_PLATFORM",
    name: "email_platform",
    field: "emailPlatform",
    type: "select",
    tooltipDescr: "Please select the email platform.",
  },
  {
    label: "CLIENT_COLOR",
    name: "client_color",
    field: "clientColor",
    type: "color",
    tooltipDescr: "Please select the brand color.",
  },
  {
    label: "UTM_PARAMETERS",
    name: "utm_parameters",
    field: "utmParameters",
    type: "",
    tooltipDescr:
      "UTM parameters are used for Google Analytics, media, advertising platforms, and traffic attribution tracking across web behavior analysis platforms. UTM tags are also used by media and advertising platforms.",
  },
  {
    label: "URL_PARAMETERS",
    name: "url_parameters",
    field: "urlParameters",
    type: "repeative-textfield",
    tooltipDescr:
      "Custom source codes are used for unique organizational tracking needs.",
  },
  {
    label: "FOOTER_HTML",
    name: "footer_html",
    field: "footerHtml",
    type: "rich-text",
    tooltipDescr: "Please enter the HTML of your email's footer.",
  },
];

// email details input fields
export const emailDetailsField = [
  {
    label: "Choose Category",
    name: "email_category",
    id: "email_category",
    rules: {
      required: "Category selection is required",
    },
    type: "select",
  },
  {
    label: "Email Name",
    name: "email_name",
    id: "email_name",
    rules: {
      required: "Email name is required",
      pattern: {
        value: /^[A-Za-z0-9 .~_=:-]+$/i,
        message:
          "Email name can only have uppercase letters, lowercase letters, numbers, periods (.), hyphens (-), tildes (~), underscores, equal signs, and colons",
      },
    },
    type: "text",
  },
  {
    label: "Preview Text",
    name: "preview_text",
    id: "preview_text",
    rules: {
      required: "Preview text is required",
    },
    type: "text",
  },
  {
    label: "Hero Image Alt",
    name: "heroImg_alt",
    id: "heroImg_alt",
    rules: {
      required: "Hero Image alt is required",
    },
    type: "text",
  },
  {
    label: "Choose Email Tag",
    name: "email_tag",
    id: "email_tag",
    rules: "",
    type: "select",
  },
];

// lightbox details fields
export const lightboxDataFields = [
  {
    label: "Lightbox Name",
    name: "lightbox_name",
    id: "lightbox_name",
    rules: {
      required: "Lightbox name is required",
      pattern: {
        value: /^[A-Za-z0-9 .~_=:-]+$/i,
        message:
          "Lightbox name can only have uppercase letters, lowercase letters, numbers, periods (.), hyphens (-), tildes (~), underscores, equal signs, and colons",
      },
    },
    type: "text",
  },
  ...utmParameters,
];

// schedule the lightbox
export const scheduleFields = [
  {
    label: "Start date & time",
    name: "start_timestamp",
    placeholder: "Enter start date & time",
  },
  {
    label: "End date & time",
    name: "end_timestamp",
    placeholder: "Enter end date & time",
  },
];

// schedule button label
export const btnLabel = {
  SAVE: "save",
  SKIP: "skip",
};
