import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material ui icons
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

// core components
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import { ROLE_KEY } from "utils/constants/AuthConfig.js";
import { adminUserRole } from "utils/constants/index.js";
import {
  clientEmailCopy,
  deleteClientEmail,
  removeEmailEditorMessage,
  removeSingleClientEmail,
} from "redux/EmailEditor/action.js";
import { getIsAgency } from "utils/helpers";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/emailEditorStyle.js";
const useStyles = makeStyles(styles);

export default function EmailListCard(props) {
  // styles
  const classes = useStyles();

  // auth0 variables
  const { user } = useAuth0();

  // icons tooltip placement
  const tooltipPlacement = "bottom";

  const {
    index,
    randomNumber,
    templatePrev,
    editNavlink,
    templateName,
    templateId,
    clientEmails,
  } = props;

  const dispatch = useDispatch();
  const emailTag = useSelector(({ EmailEditor }) =>
    EmailEditor.emailTagSelection ? EmailEditor.emailTagSelection : ""
  );
  const filterClientEmails = useSelector(({ EmailEditor }) =>
    Object.keys(EmailEditor.emailsByTag).length
      ? EmailEditor.emailsByTag.filter((item) => item.status != "deleted")
      : []
  );
  const isAgency = getIsAgency(user);
  // create email's copy
  const emailCopy = (id) => {
    const clientData = emailTag ? filterClientEmails : clientEmails;
    dispatch(
      clientEmailCopy({
        email_details: clientData.find((obj) => obj.clientEmailEditorId == id),
        is_agency: isAgency,
      })
    );
  };

  return (
    <GridItem
      xs={12}
      sm={4}
      md={4}
      lg={3}
      key={`template${index}`}
      className={classes.gridItemMargin}
    >
      <Card className={classes.cardHover}>
        <CardHeader image className={classes.cardHeaderHover}>
          <div className={classes.thumbnailContainer}>{templatePrev}</div>
        </CardHeader>
        <CardBody className={classes.cardTextBlock}>
          <div className={classes.cardHoverUnder}>
            <Tooltip
              id={`tooltip${randomNumber}`}
              title="Edit"
              placement={
                user[ROLE_KEY] == adminUserRole ? "right" : tooltipPlacement
              }
              classes={{ tooltip: classes.tooltip }}
            >
              <Link to={editNavlink}>
                <Button
                  simple
                  round
                  justIcon
                  color="warning"
                  tabIndex="-1"
                  onClick={() =>
                    dispatch(
                      removeEmailEditorMessage(),
                      removeSingleClientEmail()
                    )
                  }
                  className={classes.actionBtn}
                >
                  <EditIcon />
                </Button>
              </Link>
            </Tooltip>
            {user[ROLE_KEY] != adminUserRole && (
              <>
                <Tooltip
                  id={`tooltip${randomNumber}`}
                  title="Copy"
                  placement={tooltipPlacement}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    simple
                    round
                    justIcon
                    color="rose"
                    tabIndex="-1"
                    onClick={() => emailCopy(templateId)}
                    className={classes.actionBtn}
                  >
                    <FileCopyIcon />
                  </Button>
                </Tooltip>
                <Tooltip
                  id={`tooltip${randomNumber}`}
                  title="Delete"
                  placement={tooltipPlacement}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    simple
                    round
                    justIcon
                    color="danger"
                    tabIndex="-1"
                    onClick={() =>
                      dispatch(
                        deleteClientEmail({
                          templateId,
                          client_acronym: user[ROLE_KEY],
                          email_status: "deleted",
                        })
                      )
                    }
                    className={classes.actionBtn}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </>
            )}
          </div>
          <Tooltip
            id={`EmailNameTooltip-${index}`}
            title={templateName}
            placement="bottom-end"
            classes={{ tooltip: classes.tooltip }}
          >
            <h4 className={classes.cardEmailTitle}>{templateName}</h4>
          </Tooltip>
        </CardBody>
      </Card>
    </GridItem>
  );
}

// define props type
EmailListCard.propTypes = {
  index: PropTypes.number,
  randomNumber: PropTypes.string,
  templatePrev: PropTypes.object,
  editNavlink: PropTypes.string,
  templateName: PropTypes.string,
  templateId: PropTypes.string,
  clientEmails: PropTypes.array,
};
