import ClientTypes from "./type";

const initialState = {
  clientData: [],
  singleClientData: [],
  clientsList: [],
  fileUrl: "",
  signatureFileUrl: "",
  loading: false,
  insertLoading: false,
  modalLoading: false,
  updateLoading: false,
  clientCatgLoading: false,
  uploadLoading: false,
  successMessage: "",
  errorMessage: "",
  selectedEmailClients: [],
};

export default function ClientReducer(state = initialState, action) {
  switch (action.type) {
    // insert data
    case ClientTypes.INSERT_CLIENT:
      return {
        ...state,
        insertLoading: true,
      };

    case ClientTypes.INSERT_CLIENT_SUCCESS:
      return {
        ...state,
        insertLoading: false,
        successMessage: action.payload.msg,
        errorMessage: "",
      };

    case ClientTypes.INSERT_CLIENT_FAILURE:
      return {
        ...state,
        insertLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // get all data
    case ClientTypes.GET_CLIENT:
      return {
        ...state,
        loading: true,
      };

    case ClientTypes.GET_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        clientData: action.payload,
        successMessage: action.payload.msg,
        errorMessage: "",
      };

    case ClientTypes.GET_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // get client data by acronym
    case ClientTypes.GET_CLIENT_BY_ACRONYM:
      return {
        ...state,
        modalLoading: true,
      };

    case ClientTypes.GET_CLIENT_BY_ACRONYM_SUCCESS:
      return {
        ...state,
        modalLoading: false,
        singleClientData: action.payload,
        successMessage: action.payload.msg,
        errorMessage: "",
      };

    case ClientTypes.GET_CLIENT_BY_ACRONYM_FAILURE:
      return {
        ...state,
        modalLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // get clients by category
    case ClientTypes.GET_CLIENT_BY_CATEGORY:
      return {
        ...state,
        clientCatgLoading: true,
      };

    case ClientTypes.GET_CLIENT_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        clientCatgLoading: false,
        clientsList: action.payload,
        successMessage: action.payload.msg,
        errorMessage: "",
      };

    case ClientTypes.GET_CLIENT_BY_CATEGORY_FAILURE:
      return {
        ...state,
        clientCatgLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // update client data
    case ClientTypes.UPDATE_CLIENT:
      return {
        ...state,
        updateLoading: true,
      };

    case ClientTypes.UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        successMessage: action.payload.msg,
        errorMessage: "",
        fileUrl: "",
        signatureFileUrl: "",
      };

    case ClientTypes.UPDATE_CLIENT_FAILURE:
      return {
        ...state,
        updateLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // upload client's images
    case ClientTypes.UPLOAD_IMAGES:
      return {
        ...state,
        uploadLoading: true,
      };

    case ClientTypes.UPLOAD_IMAGES_SUCCESS:
      return {
        ...state,
        uploadLoading: false,
        fileUrl: action.payload,
        successMessage: "",
        errorMessage: "",
      };

    case ClientTypes.UPLOAD_SIGNATURE_IMAGES_SUCCESS:
      return {
        ...state,
        uploadLoading: false,
        signatureFileUrl: action.payload,
        successMessage: "",
        errorMessage: "",
      };

    case ClientTypes.UPLOAD_IMAGES_FAILURE:
      return {
        ...state,
        uploadLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // remove client data
    case ClientTypes.REMOVE_CLIENT_DATA:
      return {
        ...state,
        singleClientData: [],
        clientsList: [],
        clientData: [],
        successMessage: "",
        errorMessage: "",
      };

    // remove client data
    case ClientTypes.REMOVE_MESSAGE:
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };

    // store selected clients data
    case ClientTypes.SELECTED_EMAIL_CLIENTS:
      return {
        ...state,
        selectedEmailClients: action.payload,
      };

    default:
      return state;
  }
}
