const BatchHistoryTypes = {
  // get template batch history action type
  GET_BATCH_HISTORY: "GET_BATCH_HISTORY",
  GET_BATCH_HISTORY_SUCCESS: "GET_BATCH_HISTORY_SUCCESS",
  GET_BATCH_HISTORY_FAILURE: "GET_BATCH_HISTORY_FAILURE",

  // download batch template
  DOWNLOAD_BATCH_TEMPLATES: "DOWNLOAD_BATCH_TEMPLATES",
  DOWNLOAD_BATCH_TEMPLATES_SUCCESS: "DOWNLOAD_BATCH_TEMPLATES_SUCCESS",
  DOWNLOAD_BATCH_TEMPLATES_FAILURE: "DOWNLOAD_BATCH_TEMPLATES_FAILURE",
};

export default BatchHistoryTypes;
