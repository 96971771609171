import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { useAuth0 } from "@auth0/auth0-react";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// material ui icons
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import CustomCircularProgress from "components/CustomCircularProgress/CustomCircularProgress.js";
import Create from "./Create";
import Edit from "./Edit.js";
import {
  getClient,
  getClientByAcronym,
  removeClientData,
} from "redux/Client/action.js";
import { clientVariableName } from "utils/constants/index.js";
import { getIsAgency } from "utils/helpers";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/clientStyle.js";

const useStyles = makeStyles(styles);

const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <CustomInput
      onChange={onFilter}
      value={filterText}
      labelText="Filter by client acronym or name"
      id="search"
      inputProps={{
        type: "text",
        endAdornment: (
          <InputAdornment>
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
      }}
      style={{ width: "30rem" }}
    />
  </>
);

// define props type
FilterComponent.propTypes = {
  filterText: PropTypes.string,
  onFilter: PropTypes.func,
};

export default function View() {
  // state to handle modal
  const [openAddModal, setAddModal] = useState(false);
  const [openEditModal, setEditModal] = useState(false);
  const [clientDetails, setClientDetails] = useState("");
  const [blankDataArr, setBlankDataArr] = useState([]);
  const [filterText, setFilterText] = useState("");
  const { user: authUser } = useAuth0();
  // Toggle snackbar (Notification)
  const [show, setShow] = useState(false);

  const [notificationProps, setNotificationProps] = useState({
    color: "success",
    message: "",
  });

  const dispatch = useDispatch();

  // Redux store variable
  const clientData = useSelector(({ EmailClient }) =>
    Object.keys(EmailClient.clientData).length
      ? EmailClient.clientData.Items.filter(
          (item) =>
            (item.acronym &&
              item.acronym.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.clientName &&
              item.clientName.toLowerCase().includes(filterText.toLowerCase()))
        )
      : []
  );
  const singleClientData = useSelector(({ EmailClient }) =>
    Object.keys(EmailClient.singleClientData).length
      ? EmailClient.singleClientData.Item
      : []
  );
  const emailClientData = useSelector(({ EmailClient }) => EmailClient);

  // define cols for datatable
  const columns = [
    {
      id: "col0",
      name: "Client Acronym",
      selector: (row) => row.acronym,
      sortable: true,
    },
    {
      id: "col1",
      name: "Client Name",
      selector: (row) => row.clientName,
      sortable: true,
    },
    {
      id: "col2",
      name: "Client Category",
      selector: (row) => row.clientCategory,
      sortable: true,
    },
    {
      id: "col3",
      name: "Action(s)",
      cell: (row) => (
        <Button
          justIcon
          round
          simple
          onClick={() => EditClient(row, row.acronym)}
          color="warning"
        >
          <EditIcon />
        </Button>
      ),
      selector: (row) => row.actions,
      right: true,
    },
  ];

  // dispatch get all client action
  useEffect(() => {
    const isAgency = getIsAgency(authUser);
    dispatch(getClient(isAgency));
  }, []);

  // open modal, if there are client details
  useEffect(() => {
    if (Object.keys(singleClientData).length) {
      setEditModal(true);
    }
  }, [singleClientData]);
  // remove data after success
  useEffect(() => {
    return () => {
      dispatch(removeClientData());
    };
  }, []);

  // open modal
  const AddClient = () => {
    setAddModal(true);
  };

  const EditClient = (data, acronym) => {
    setClientDetails(data);
    let array = [];
    array[acronym] = clientVariableName.map((item) => item.field);
    setBlankDataArr(array);
    dispatch(getClientByAcronym(acronym));
  };

  const toggleSnackbar = () => setShow(!show);

  useEffect(() => {
    // If any error found, display in notification
    if (emailClientData.errorMessage) {
      setNotificationProps({
        color: "danger",
        message: emailClientData.errorMessage,
      });
      setShow(true);
    }

    // If email template created successfully, display in notification
    if (emailClientData.successMessage) {
      setEditModal(false);
      setAddModal(false);
      setNotificationProps({
        color: "success",
        message: emailClientData.successMessage,
      });
      setShow(true);
    }
  }, [emailClientData]);

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        filterText={filterText}
      />
    );
  }, [filterText]);

  // styles
  const classes = useStyles();

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>Clients</h4>
              </CardText>
              <Button
                color="rose"
                className={classes.addClientBtn}
                onClick={AddClient}
              >
                <AddIcon />
                Add Client
              </Button>
              {openAddModal && (
                <Create
                  open={openAddModal}
                  handleClose={() => setAddModal(false)}
                />
              )}
            </CardHeader>
            <CardBody>
              <DataTable
                columns={columns}
                onRowClicked={(row) => EditClient(row, row.acronym)}
                pointerOnHover
                data={clientData}
                defaultSortFieldId="col0"
                pagination
                paginationPerPage={100}
                paginationRowsPerPageOptions={[100, 200, 300, 400]}
                paginationComponentOptions={{
                  rowsPerPageText: "Records per page:",
                  rangeSeparatorText: "out of",
                }}
                progressPending={emailClientData.loading}
                subHeader
                subHeaderComponent={
                  !emailClientData.loading && subHeaderComponentMemo
                }
                customStyles={styles.datatableFontSize}
              />
              {openEditModal && (
                <Edit
                  open={openEditModal}
                  handleClose={() => setEditModal(false)}
                  blankFields={blankDataArr}
                  clientData={clientDetails}
                  dialogType="editClientDialog"
                />
              )}
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}></CardFooter>
          </Card>
        </GridItem>
        <CustomCircularProgress loading={emailClientData.modalLoading} />
      </GridContainer>
      <Snackbar
        color={notificationProps.color}
        message={notificationProps.message}
        place="tr"
        open={show}
        close
        closeNotification={toggleSnackbar}
        autoHideDuration={6000}
      />
    </div>
  );
}
