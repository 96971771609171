import {
  cardTitle,
  whiteColor,
  grayColor,
  tooltip,
  defaultFontSize,
  datatableFontSize,
} from "assets/jss/material-dashboard-pro-react.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";

const clientTableStyle = {
  tooltip,
  datatableFontSize,
  ...customSelectStyle,
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  btnRight: {
    float: "right",
  },
  addClientBtn: {
    top: "10px",
    float: "right",
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  dialogAction: {
    minHeight: "73px",
  },
  inputField: {
    marginRight: "10px",
  },
  mTop10: {
    marginTop: "15px",
  },
  label: {
    color: "#AAAAAA",
    fontSize: defaultFontSize,
    lineHeight: 3,
  },
  errorMsgMargin: {
    marginTop: "-10px",
  },
  uploadBtn: {
    marginTop: "15px",
    float: "right",
  },
  uploadBtnGridItem: {
    paddingRight: "0 !important",
  },
  UrlLabel: {
    color: grayColor[2],
    fontSize: defaultFontSize,
    lineHeight: "1.428571429",
    fontWeight: "400",
    wordBreak: "break-all",
  },
  ...modalStyle,
};

export default clientTableStyle;
