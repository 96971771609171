import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import { clientCatg, emailDetailsField } from "utils/constants/index.js";
import { getEmailTags } from "redux/EmailEditor/action.js";
import { getIsAgency } from "utils/helpers";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/emailEditorStyle.js";
const useStyles = makeStyles(styles);

export default function AddTemplateDetails(props) {
  // styles
  const classes = useStyles();
  // auth0 variables
  const { user } = useAuth0();
  // props
  const { open, handleClose, category } = props;
  // Redux store methods
  const dispatch = useDispatch();
  const singleBeeEmailData = useSelector(({ EmailEditor }) =>
    Object.keys(EmailEditor.singleTemplate).length
      ? EmailEditor.singleTemplate
      : ""
  );
  const emailTagsList = useSelector(({ EmailEditor }) =>
    Object.keys(EmailEditor.emailTags).length ? EmailEditor.emailTags : []
  );
  const BEEAuthToken = useSelector(({ EmailEditor }) => EmailEditor.authToken);

  useEffect(() => {
    const isAgency = getIsAgency(user);
    if (emailTagsList && !emailTagsList.length) {
      dispatch(getEmailTags(isAgency));
    }
  }, [BEEAuthToken]);

  // get email details from localStorage if there
  const {
    email_category,
    preview_text,
    heroImg_alt,
    email_tag,
  } = localStorage.getItem("emailData")
    ? JSON.parse(localStorage.getItem("emailData"))
    : {};

  // react-hook-form methods
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email_category: email_category ? email_category : "",
      email_name: singleBeeEmailData?.name
        ? singleBeeEmailData.name.replace(/[^A-Za-z0-9 .~_=:-]/g, "")
        : "",
      preview_text: preview_text
        ? preview_text
        : "Give now to help children facing hunger this weekend.",
      heroImg_alt: heroImg_alt ? heroImg_alt : "",
      email_tag: email_tag
        ? email_tag
        : singleBeeEmailData.tags && singleBeeEmailData.tags.length > 0
        ? singleBeeEmailData.tags.map((tag) => tag)
        : [],
    },
  });

  useEffect(() => {
    setValue("email_category", category ? category : "");
  }, [category]);

  // save email details
  const saveTemplateData = (inputs) => {
    inputs.emailThumbnail = singleBeeEmailData.thumb_medium;
    localStorage.setItem("emailData", JSON.stringify(inputs));
    handleClose();
  };

  return (
    <>
      <Dialog open={open} disableBackdropClick maxWidth="md" fullWidth>
        <DialogTitle>
          Add email details
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={handleClose}
          >
            <Close />
          </Button>
        </DialogTitle>

        <DialogContent>
          {emailDetailsField.map((data, index) => {
            if (data.type == "text") {
              return (
                <div key={`emailTextField${index}`}>
                  <Controller
                    control={control}
                    name={data.name}
                    rules={data.rules}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomInput
                        labelText={data.label}
                        onChange={onChange}
                        onBlur={onBlur}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={value}
                        inputProps={{
                          name: data.name,
                          id: data.id,
                          type: data.type,
                        }}
                      />
                    )}
                  />
                  <Danger>
                    <p className={classes.errorMsgMargin}>
                      {errors?.[data.name]?.message}
                    </p>
                  </Danger>
                </div>
              );
            } else if (data.type == "select" && data.name.includes("tag")) {
              return (
                <div key={`emailSelectField${index}`}>
                  <Controller
                    control={control}
                    name={data.name}
                    rules={data.rules}
                    render={({
                      field: { value, onChange, onBlur, ...field },
                    }) => (
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor={data.name}
                          className={classes.selectLabel}
                        >
                          {data.label}
                        </InputLabel>
                        <Select
                          multiple={data.name.includes("tag") ? true : false}
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputProps={{
                            name: data.name,
                            id: data.id,
                            type: data.type,
                          }}
                          {...field}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                          >
                            {data.label}
                          </MenuItem>
                          {data.name.includes("category") &&
                            clientCatg.map((catg, index) => (
                              <MenuItem
                                key={`catg${index}`}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: data.name.includes("tag")
                                    ? classes.selectMenuItemSelectedMultiple
                                    : classes.selectMenuItemSelected,
                                }}
                                value={catg.value}
                              >
                                {catg.label}
                              </MenuItem>
                            ))}
                          {data.name.includes("tag") &&
                            emailTagsList.length > 0 &&
                            emailTagsList.map((tag, index) => (
                              <MenuItem
                                key={`tag${index}`}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected:
                                    classes.selectMenuItemSelectedMultiple,
                                }}
                                value={tag.tag}
                              >
                                {tag.tag}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Danger>
                    <p className={classes.errorMsgMargin}>
                      {errors?.[data.name]?.message}
                    </p>
                  </Danger>
                </div>
              );
            }
          })}
        </DialogContent>

        <DialogActions>
          <Button
            type="submit"
            color="rose"
            style={{ marginRight: "10px" }}
            onClick={handleSubmit((formData) => saveTemplateData(formData))}
          >
            <SaveIcon /> Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

// define props type
AddTemplateDetails.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  category: PropTypes.string,
};
