// import npm packages
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

// style
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

export default function LoginPage() {
  // Auth0 functions
  const { loginWithRedirect } = useAuth0();

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  // styles
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>WELCOME</h4>
              </CardHeader>
              <CardBody className={classes.textCenter}>
                <img
                  src="https://public.charitable.one/assets/branding/one-logo.png"
                  alt="rkdLogo"
                  className={classes.mainLogo}
                />
                <h3>BuildR</h3>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  type="submit"
                  color="rose"
                  size="lg"
                  block
                  onClick={() =>
                    loginWithRedirect({
                      redirectUri:
                        window.location.origin + "/admin/create-email-template",
                    })
                  }
                >
                  LOGIN
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
