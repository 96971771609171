import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import DownloadIcon from "@material-ui/icons/GetApp";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import Button from "components/CustomButtons/Button.js";
import ClientListSelect from "components/ClientListSelect/ClientListSelect.js";
import Danger from "components/Typography/Danger.js";
import { getClient, removeClientData } from "redux/Client/action.js";
import { exportClientEmail } from "redux/EmailEditor/action.js";
import {
  replacePlaceholders,
  replaceClientEmailPlaceholders,
  getIsAgency,
} from "utils/helpers/index.js";
import AddTemplateDetails from "./AddTemplateDetails.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/emailEditorStyle.js";
import UrlContructor from "views/EmailTemplate/UrlConstructor.js";
const useStyles = makeStyles(styles);

export default function Preview(props) {
  // styles
  const classes = useStyles();
  const { user: authUser } = useAuth0();
  const { open, handleClose, HTMLEmail } = props;

  const [selectedClient, setSelectedClient] = useState(null);
  const [replacedHTMLEmail, setReplacedHTMLEmail] = useState(""); // store replaced html email
  const [addDataModal, setAddDataModal] = useState(false);
  const [urlConstructModal, setUrlConstructModal] = useState(false);
  const [errors, setErrors] = useState("");
  const isAgency = getIsAgency(authUser);
  // Redux store methods
  const dispatch = useDispatch();
  useEffect(() => {
    if (open) {
      dispatch(getClient(isAgency));
    }
    return () => dispatch(removeClientData());
  }, []);
  const clientData = useSelector(({ EmailClient }) =>
    Object.keys(EmailClient.clientData).length
      ? EmailClient.clientData.Items
      : []
  );
  const clientListLoading = useSelector(
    ({ EmailClient }) => EmailClient.loading
  );
  const downloadEmailLoading = useSelector(
    ({ EmailEditor }) => EmailEditor.exportClientEmailLoading
  );
  const UrlConstructData = useSelector(({ EmailTemplate }) =>
    EmailTemplate.UrlConstructData?.length ? EmailTemplate.UrlConstructData : []
  );

  // localstorage email details
  const emailDetails = localStorage.getItem("emailData");

  const selectClient = (clientInfo) => {
    if (clientInfo) {
      setErrors("");
      setSelectedClient(clientInfo);
      setReplacedHTMLEmail(replacePlaceholders(clientInfo, HTMLEmail));
    }
  };

  // download HTML email
  const downloadEmail = () => {
    if (selectedClient) {
      if (emailDetails) {
        let singleclientData = selectedClient;
        let check = [];
        const urlCheck = singleclientData?.urlParameters
          ? JSON.parse(singleclientData?.urlParameters)
          : {};
        const data = UrlConstructData?.filter(
          (e) => e.acronym == singleclientData.acronym
        );
        if (data.length) {
          for (let value of Object.keys(urlCheck)) {
            const obj = data[0].url?.filter(
              (e) => e.textbox == urlCheck[value].label
            );
            urlCheck[value].value = obj[0][urlCheck[value].label];
          }
          singleclientData = {
            ...singleclientData,
            urlParameters: JSON.stringify(urlCheck),
          };
        } else {
          if (Object.keys(urlCheck).length) {
            Object.keys(urlCheck)?.map((e, i) => {
              if (urlCheck[e].checked) {
                setUrlConstructModal(true);
                check.push(i);
              }
            });
          }
        }
        if (check.length == 0) {
          // replace placeholder
          const replacedEmail = replaceClientEmailPlaceholders({
            emaildata: emailDetails,
            clientDetails: JSON.stringify(singleclientData),
            htmlTemplate: HTMLEmail,
          });
          dispatch(
            exportClientEmail({
              client_acronym: selectedClient.acronym,
              email_details: emailDetails,
              clientHtmlEmail: replacedEmail,
              is_agency: isAgency,
            })
          );
        }
      } else {
        setAddDataModal(true);
      }
    } else setErrors("Client selection is required");
  };

  return (
    <Dialog open={open} disableBackdropClick maxWidth="md" fullWidth>
      {clientListLoading ? (
        <div className={classes.circularLoader}>
          <CircularProgress
            size={70}
            className={classes.circularLoader}
            color="secondary"
          />
        </div>
      ) : (
        <>
          <DialogTitle>
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={handleClose}
            >
              <Close />
            </Button>
          </DialogTitle>
          <DialogContent>
            {clientData.length > 0 && (
              <>
                <ClientListSelect
                  classes={classes}
                  clientData={clientData}
                  onChange={selectClient}
                  value={selectedClient}
                />
                <Danger>{errors}</Danger>
              </>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: replacedHTMLEmail ? replacedHTMLEmail : HTMLEmail,
              }}
            />
          </DialogContent>
          {!window.location.pathname.split("/").includes("lightbox") && (
            <DialogActions>
              <Button
                color="rose"
                onClick={downloadEmail}
                loading={downloadEmailLoading}
                disabled={downloadEmailLoading}
              >
                <DownloadIcon /> Download HTML
              </Button>
            </DialogActions>
          )}
        </>
      )}
      {addDataModal && (
        <AddTemplateDetails
          open={addDataModal}
          category={selectedClient.clientCategory}
          handleClose={() => setAddDataModal(false)}
        />
      )}
      {urlConstructModal && (
        <UrlContructor
          open={urlConstructModal}
          handleClose={() => setUrlConstructModal(false)}
          client_data={selectedClient}
        />
      )}
    </Dialog>
  );
}

// define props type
Preview.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  HTMLEmail: PropTypes.string,
  category: PropTypes.string,
};
