// import npm packages
import { takeLatest, call, put, all } from "redux-saga/effects";
import { lightboxUrl } from "redux/baseUrl";
import {
  postRequestAPI,
  putRequestAPI,
  getRequestAPI,
} from "utils/services/api";
import {
  copyLightboxFailure,
  copyLightboxSuccess,
  createLightboxFailure,
  createLightboxSuccess,
  createLightboxWarning,
  getLightbox,
  getLightboxByIdFailure,
  getLightboxByIdSuccess,
  getLightboxFailure,
  getLightboxSuccess,
  scheduleLightboxFailure,
  scheduleLightboxSuccess,
  scheduleLightboxWarning,
  updateLightboxFailure,
  updateLightboxSuccess,
  updateLightboxWarning,
} from "./action";
import LightboxTypes from "./type";

// create request
export function* createLightboxRequest(action) {
  try {
    const response = yield call(postRequestAPI, {
      url: `${lightboxUrl}/create`,
      data: action.payload,
    });

    if (response.success) {
      yield put(createLightboxSuccess(response));
    } else if (response.payload.resStatus == "warning") {
      yield put(createLightboxWarning(response.msg));
    } else {
      yield put(createLightboxFailure(response.msg));
    }
  } catch (error) {
    yield put(createLightboxFailure(error.message));
  }
}

// get all request
export function* getLightboxRequest() {
  try {
    const response = yield call(getRequestAPI, {
      url: lightboxUrl,
    });

    if (response.success) {
      yield put(getLightboxSuccess(response.payload));
    } else {
      yield put(getLightboxFailure(response.msg));
    }
  } catch (error) {
    yield put(getLightboxFailure(error.message));
  }
}

// get by id request
export function* getLightboxByIdRequest(action) {
  try {
    const response = yield call(getRequestAPI, {
      url: `${lightboxUrl}/${action.payload}`,
    });

    if (response.success) {
      yield put(getLightboxByIdSuccess(response.payload));
    } else {
      yield put(getLightboxByIdFailure(response.msg));
    }
  } catch (error) {
    yield put(getLightboxByIdFailure(error.message));
  }
}

// update details request
export function* updateLightboxRequest(action) {
  try {
    const response = yield call(putRequestAPI, {
      url: `${lightboxUrl}/update/${action.payload.lightboxId}`,
      data: action.payload,
    });

    if (response.success) {
      yield put(updateLightboxSuccess(response));
    } else if (response.payload.resStatus == "warning") {
      yield put(updateLightboxWarning(response.msg));
    } else {
      yield put(updateLightboxFailure(response.msg));
    }
  } catch (error) {
    yield put(updateLightboxFailure(error.message));
  }
}

// schedule lightbox request
export function* scheduleLightboxRequest(action) {
  try {
    const response = yield call(putRequestAPI, {
      url: `${lightboxUrl}/schedule/${action.payload.lightboxId}`,
      data: action.payload,
    });

    if (response.success) {
      yield put(scheduleLightboxSuccess(response));
    } else if (response.payload.resStatus == "warning") {
      yield put(scheduleLightboxWarning(response.msg));
    } else {
      yield put(scheduleLightboxFailure(response.msg));
    }
  } catch (error) {
    yield put(scheduleLightboxFailure(error.message));
  }
}

// copy request
export function* copyLightboxRequest(action) {
  try {
    const response = yield call(postRequestAPI, {
      url: `${lightboxUrl}/copy/${action.payload.lightboxId}`,
      data: action.payload,
    });

    if (response.success) {
      yield put(copyLightboxSuccess(response));
      yield put(getLightbox());
    } else {
      yield put(copyLightboxFailure(response.msg));
    }
  } catch (error) {
    yield put(copyLightboxFailure(error.message));
  }
}

export function* watchClient() {
  yield takeLatest(LightboxTypes.CREATE_LIGHTBOX, createLightboxRequest);
  yield takeLatest(LightboxTypes.GET_LIGHTBOXES, getLightboxRequest);
  yield takeLatest(LightboxTypes.GET_LIGHTBOX_BY_ID, getLightboxByIdRequest);
  yield takeLatest(LightboxTypes.UPDATE_LIGHTBOX, updateLightboxRequest);
  yield takeLatest(LightboxTypes.SCHEDULE_LIGHTBOX, scheduleLightboxRequest);
  yield takeLatest(LightboxTypes.COPY_LIGHTBOX, copyLightboxRequest);
}

export function* LightboxSaga() {
  yield all([call(watchClient)]);
}
