import {
  cardTitle,
  dangerColor,
  whiteColor,
  grayColor,
  roseColor,
  warningColor,
  tooltip,
  defaultFontSize,
  datatableFontSize,
} from "assets/jss/material-dashboard-pro-react.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";

const emailTemplateStyle = {
  tooltip,
  datatableFontSize,
  ...customCheckboxRadioSwitch,
  ...customSelectStyle,
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  formCategory: {
    marginBottom: "0",
    color: grayColor[0],
    fontSize: defaultFontSize,
    padding: "10px 0 10px",
  },
  center: {
    textAlign: "center",
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  registerButton: {
    float: "right",
  },
  danger: {
    color: dangerColor[0] + "!important",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  errorMsgMargin: {
    marginTop: "-10px",
  },
  editFormIcon: {
    color: warningColor[0] + "!important",
  },
  circularLoader: {
    color: roseColor[6],
    margin: "5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  actionBtn: {
    width: "25px !important",
    height: "25px !important",
  },
  dangerBtn: {
    backgroundColor: dangerColor[0] + " !important",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
  },
  imageInner: {
    width: "250px",
    height: "350px",
    border: "1px solid #ccc",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  },
  image: {
    width: "auto",
    height: "100%",
    objectFit: "cover",
    padding: "5px",
  },
  ...buttonStyle,
  ...modalStyle,
  UrlLabel: {
    color: grayColor[2],
    fontSize: defaultFontSize,
    lineHeight: "1.428571429",
    fontWeight: "400",
    wordBreak: "break-all",
  },
};

export default emailTemplateStyle;
