import React, { Fragment, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { ColorPicker } from "material-ui-color";
import { useAuth0 } from "@auth0/auth0-react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { DevTool } from "@hookform/devtools";

// material ui icons
import SaveIcon from "@material-ui/icons/Save";
import InfoIcon from "@material-ui/icons/Info";
import Close from "@material-ui/icons/Close";
import PublishIcon from "@material-ui/icons/Publish";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import { ROLE_KEY } from "utils/constants/AuthConfig";
import {
  clientCatg,
  clientVariableName,
  emailPlatformList,
  validateUrlRegex,
  utmParameters,
} from "utils/constants/index.js";
import { getIsAgency } from "utils/helpers";
import {
  updateClient,
  uploadImage,
  removeMessage,
} from "redux/Client/action.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/clientStyle.js";
const useStyles = makeStyles(styles);

export default function Edit(props) {
  // styles
  const classes = useStyles();

  const { user } = useAuth0();

  // props
  const { open, handleClose, blankFields, clientData, dialogType } = props;

  const [color, setColor] = useState(); // state to set brand color
  let [uploadType, setUploadType] = useState(""); // state to manage uploaded file
  const [txtFieldArr, setTxtFieldArr] = useState([
    {
      id:
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15),
    },
  ]);
  const editorRef = useRef(null);
  const [show, setShow] = useState(false);

  // Redux store variable
  const clientDetails = useSelector(({ EmailClient }) => EmailClient);
  const fileUrl = useSelector(({ EmailClient }) => EmailClient.fileUrl);
  const signatureFileUrl = useSelector(
    ({ EmailClient }) => EmailClient.signatureFileUrl
  );

  // react-hook-form methods
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
    clearErrors,
  } = useForm({
    shouldUnregister: true,
    mode: "onChange",
    defaultValues: {
      client_category: "",
    },
  });

  // tooltip placement
  const tooltipPlacement = "left";
  const clientCategoryWatch = watch("client_category");
  const dispatch = useDispatch();

  // function to set selected color
  const handleChange = (value) => {
    setColor(value?.css?.backgroundColor ? value?.css?.backgroundColor : value);
  };

  const handleImageUpload = (event, label) => {
    const uploadTypeVal = label == "CLIENT_LOGO_URL" ? 1 : 2;
    setUploadType(label);
    const uploads = new FormData();
    uploads.append("acronym", clientData.acronym);
    uploads.append("upload_type", uploadTypeVal);
    uploads.append("file", event.target.files[0]);
    dispatch(uploadImage(uploads));
  };

  // set client data
  useEffect(() => {
    if (Object.keys(clientData).length) {
      setValue("client_name", clientData.clientName || "");
      setValue("client_category", clientData.clientCategory || "");
      setValue("organization_name", clientData.organizationName || "");
      setValue("organization_nic_name", clientData.organizationNicName || "");
      setValue("community_name", clientData.communityName || "");
      setValue("client_website_url", clientData.clientWebsiteUrl || "");
      setValue("client_logo_url", clientData.clientLogoUrl || "");
      setValue("signature_file_url", clientData.signatureFileUrl || "");
      setValue("signature_name", clientData.signatureName || "");
      setValue("signature_title", clientData.signatureTitle || "");
      setValue("default_algorithm", clientData.defaultAlgorithm || "");
      setValue("dollar_amount", clientData.dollarAmount || "");
      setValue("meals_amount", clientData.mealsAmount || "");
      setValue("default_df_url", clientData.defaultDfUrl || "");
      setValue("email_platform", clientData.emailPlatform || "");
      setValue("footer_html", clientData.footerHtml || "");
      setColor(clientData.clientColor || "#FF69B4");
      if (clientData.urlParameters) {
        const urlParamObject = JSON.parse(clientData.urlParameters);
        txtFieldArr.length = 0;
        for (const key in urlParamObject) {
          setValue(
            `url_parameters.${key}.label`,
            urlParamObject[key].label || ""
          );
          setValue(
            `url_parameters.${key}.checked`,
            urlParamObject[key].checked
          );
          txtFieldArr.push({ id: key });
        }
      }
      if (clientData.utmParams) {
        const utmParamObj = JSON.parse(clientData.utmParams);
        for (const key in utmParamObj) {
          setValue(`utm_params.${key}.checked`, utmParamObj[key].checked);
          setValue(`utm_params.${key}.value`, utmParamObj[key].value || "");
        }
      } else {
        const utmParamObj = {
          utm_source: {
            checked: true,
          },
          utm_campaign: {
            checked: true,
          },
          utm_medium: {
            checked: true,
            value: "email",
          },
        };

        for (const key in utmParamObj) {
          setValue(`utm_params.${key}.checked`, utmParamObj[key].checked);
          setValue(`utm_params.${key}.value`, utmParamObj[key].value || "");
        }
      }
    }
  }, [clientData]);

  useEffect(() => {
    if (fileUrl) {
      setValue("client_logo_url", fileUrl);
    }
  }, [fileUrl]);

  useEffect(() => {
    if (signatureFileUrl) {
      setValue("signature_file_url", signatureFileUrl);
    }
  }, [signatureFileUrl]);

  const inputField = (data, index) => {
    if (
      data.type == "rich-text" &&
      blankFields[clientData.acronym].length &&
      blankFields[clientData.acronym].find((obj) => obj == data.field)
    ) {
      return (
        <div className={classes.mTop10} key={`htmledit${index}`}>
          <Controller
            control={control}
            name={data.name}
            render={({ field: { onChange, ...field } }) => (
              <div>
                <FormLabel className={classes.label}>
                  {data.label}
                  <Tooltip
                    id="tooltip-top"
                    title={data.tooltipDescr}
                    placement="right"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      simple
                      justIcon
                      color="rose"
                      style={{ paddingTop: "4px" }}
                      tabIndex="-1"
                    >
                      <InfoIcon style={{ color: "#AAAAAA" }} />
                    </Button>
                  </Tooltip>
                </FormLabel>
                <Editor
                  {...field}
                  onEditorChange={onChange}
                  apiKey="6y30ipiczd53rbxghkdkqxgnhm3tgcunou5lmkg8ix7rrx6t"
                  onInit={(editor) => (editorRef.current = editor)}
                  initialValue={
                    clientData && clientData[data.field]
                      ? clientData[data.field]
                      : ""
                  }
                  init={{
                    height: 500,
                    plugins: ["code"],
                    menubar: false,
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | code",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                />
              </div>
            )}
          />
        </div>
      );
    } else if (
      data.type == "select" &&
      blankFields[clientData.acronym].length &&
      blankFields[clientData.acronym].find((obj) => obj == data.field)
    ) {
      return (
        <div key={`select${index}`}>
          <Controller
            control={control}
            name={data.name}
            rules={{
              required:
                data.label == "CLIENT_CATEGORY"
                  ? "Client Category is required"
                  : data.label == "EMAIL_PLATFORM"
                  ? "Email platform selection is required"
                  : false,
            }}
            render={({ field: { onChange, onBlur } }) => (
              <FormControl
                onClick={(e) => e.stopPropagation()}
                fullWidth
                className={classes.selectFormControl}
              >
                <InputLabel htmlFor={data.name} className={classes.selectLabel}>
                  {data.label}
                </InputLabel>
                <Select
                  className={classes.inputField}
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  defaultValue={
                    clientData && clientData[data.field]
                      ? clientData[data.field]
                      : ""
                  }
                  onChange={onChange}
                  onBlur={onBlur}
                  IconComponent={() => (
                    <InputAdornment position="end">
                      <Tooltip
                        id="tooltip-top"
                        title={data.tooltipDescr}
                        placement={tooltipPlacement}
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button simple justIcon color="rose" tabIndex="-1">
                          <InfoIcon style={{ color: "#AAAAAA" }} />
                        </Button>
                      </Tooltip>
                    </InputAdornment>
                  )}
                  inputProps={{
                    name: data.name,
                    id: data.name,
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    {data.label == "CLIENT_CATEGORY"
                      ? "Choose Client Category"
                      : data.label == "EMAIL_PLATFORM"
                      ? "Choose Email Platform"
                      : ""}
                    ,
                  </MenuItem>
                  {data.label == "CLIENT_CATEGORY" &&
                    clientCatg.map((catg, index) => {
                      return (
                        <MenuItem
                          key={`catg${index}`}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={catg.value}
                        >
                          {catg.label}
                        </MenuItem>
                      );
                    })}
                  {data.label == "EMAIL_PLATFORM" &&
                    emailPlatformList.map((platform, index) => {
                      return (
                        <MenuItem
                          key={`platform${index}`}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={platform.value}
                        >
                          {platform.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            )}
          />
          <Danger>
            <p className={classes.errorMsgMargin}>
              {errors?.[data.name]?.message}
            </p>
          </Danger>
        </div>
      );
    } else if (
      data.type == "color" &&
      blankFields[clientData.acronym].length &&
      blankFields[clientData.acronym].find((obj) => obj == data.field)
    ) {
      return (
        <div className={classes.mTop10} key={`colorPicker${index}`}>
          <FormLabel className={classes.label}>
            {data.label}
            <Tooltip
              id="tooltip-top"
              title={data.tooltipDescr}
              placement="right"
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                simple
                justIcon
                color="rose"
                style={{ paddingTop: "4px" }}
                tabIndex="-1"
              >
                <InfoIcon style={{ color: "#AAAAAA" }} />
              </Button>
            </Tooltip>
          </FormLabel>
          <ColorPicker
            value={color}
            onChange={handleChange}
            disablePlainColor
          />
        </div>
      );
    } else if (
      (data.type == "url" || data.type == "text") &&
      blankFields[clientData.acronym].length &&
      blankFields[clientData.acronym].find((obj) => obj == data.field)
    ) {
      return (
        <div key={`input${index}`}>
          <Controller
            control={control}
            name={data.name}
            rules={{
              required:
                data.label == "CLIENT_NAME"
                  ? "CLIENT_NAME field is required"
                  : false,
              pattern:
                data.type == "url"
                  ? {
                      value: validateUrlRegex,
                      message: "Please enter a valid URL",
                    }
                  : false,
            }}
            render={({ field: { onChange, onBlur } }) =>
              data.label == "CLIENT_LOGO_URL" ||
              data.label == "SIGNATURE_FILE_URL" ? (
                <GridContainer className={classes.uploadBtnGridContainer}>
                  <GridItem sm={8} md={10} lg={10}>
                    <CustomInput
                      labelText={data.label}
                      id={data.label}
                      className={classes.inputField}
                      onChange={onChange}
                      onBlur={onBlur}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: data.type,
                        defaultValue:
                          fileUrl && fileUrl.includes(data.flag)
                            ? fileUrl
                            : signatureFileUrl &&
                              signatureFileUrl.includes(data.flag)
                            ? signatureFileUrl
                            : clientData && clientData[data.field]
                            ? clientData[data.field]
                            : "",
                        key:
                          fileUrl && fileUrl.includes(data.flag)
                            ? `${data.flag}_${Math.random()}`
                            : signatureFileUrl &&
                              signatureFileUrl.includes(data.flag)
                            ? `${data.flag}_${Math.random()}`
                            : "",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              id="tooltip-top"
                              title={data.tooltipDescr}
                              placement={tooltipPlacement}
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                simple
                                justIcon
                                color="rose"
                                tabIndex="-1"
                              >
                                <InfoIcon style={{ color: "#AAAAAA" }} />
                              </Button>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GridItem>
                  <GridItem
                    sm={3}
                    md={2}
                    lg={2}
                    className={classes.uploadBtnGridItem}
                  >
                    <Button
                      component="label"
                      className={classes.uploadBtn}
                      color="rose"
                      size="md"
                      onChange={(e) => handleImageUpload(e, data.label)}
                      disabled={
                        uploadType == data.label && clientDetails.uploadLoading
                      }
                      loading={
                        uploadType == data.label && clientDetails.uploadLoading
                      }
                    >
                      <PublishIcon />
                      Upload
                      <input
                        type="file"
                        id={`btn-${data.label}`}
                        accept="image/*"
                        style={{ display: "none" }}
                      />
                    </Button>
                  </GridItem>
                </GridContainer>
              ) : data.label == "DEFAULT_ALGORITHM" ||
                data.label == "DOLLAR_AMOUNT" ||
                data.label == "MEALS_AMOUNT" ? (
                <>
                  {clientCategoryWatch == "Humane" &&
                    data.label == "DEFAULT_ALGORITHM" && (
                      <CustomInput
                        labelText={data.label}
                        id={data.label}
                        className={classes.inputField}
                        onChange={onChange}
                        onBlur={onBlur}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: data.type,
                          defaultValue:
                            clientData && clientData[data.field]
                              ? clientData[data.field]
                              : "",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                id="tooltip-top"
                                title={data.tooltipDescr}
                                placement={tooltipPlacement}
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button
                                  simple
                                  justIcon
                                  color="rose"
                                  tabIndex="-1"
                                >
                                  <InfoIcon style={{ color: "#AAAAAA" }} />
                                </Button>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  {clientCategoryWatch == "Food Bank" &&
                    (data.label == "DOLLAR_AMOUNT" ||
                      data.label == "MEALS_AMOUNT") && (
                      <CustomInput
                        labelText={data.label}
                        id={data.label}
                        className={classes.inputField}
                        onChange={onChange}
                        onBlur={onBlur}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: data.type,
                          defaultValue:
                            clientData && clientData[data.field]
                              ? clientData[data.field]
                              : "",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                id="tooltip-top"
                                title={data.tooltipDescr}
                                placement={tooltipPlacement}
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button
                                  simple
                                  justIcon
                                  color="rose"
                                  tabIndex="-1"
                                >
                                  <InfoIcon style={{ color: "#AAAAAA" }} />
                                </Button>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                </>
              ) : (
                <CustomInput
                  labelText={data.label}
                  id={data.label}
                  className={classes.inputField}
                  onChange={onChange}
                  onBlur={onBlur}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: data.type,
                    defaultValue:
                      clientData && clientData[data.field]
                        ? clientData[data.field]
                        : "",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          id="tooltip-top"
                          title={data.tooltipDescr}
                          placement={tooltipPlacement}
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <Button simple justIcon color="rose" tabIndex="-1">
                            <InfoIcon style={{ color: "#AAAAAA" }} />
                          </Button>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              )
            }
          />
          <Danger>
            <p className={classes.errorMsgMargin}>
              {errors[data.name]?.message}
            </p>
          </Danger>
        </div>
      );
    } else if (
      data.type == "repeative-textfield" &&
      blankFields[clientData.acronym].length &&
      blankFields[clientData.acronym].find((obj) => obj == data.field)
    ) {
      return (
        <div className={classes.mTop10} key={data.type + index}>
          <FormLabel className={classes.label}>
            {data.label}
            <Tooltip
              id="tooltip-top"
              title={data.tooltipDescr}
              placement="right"
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                simple
                justIcon
                color="rose"
                style={{ paddingTop: "4px" }}
                tabIndex="-1"
              >
                <InfoIcon style={{ color: "#AAAAAA" }} />
              </Button>
            </Tooltip>
          </FormLabel>
          <GridContainer style={{ alignItems: "baseline" }}>
            {txtFieldArr.map((obj, i) => (
              <Fragment key={`fragment_${obj.id}`}>
                {!clientData.urlParameters &&
                  !show &&
                  (txtFieldArr.length == 1 || txtFieldArr.length == 0) && (
                    <GridItem
                      xs={8}
                      sm={10}
                      md={11}
                      lg={11}
                      key={`def_button_${obj.id}`}
                    >
                      <Button
                        size="sm"
                        color="rose"
                        onClick={() => addTextField(true)}
                      >
                        <AddIcon />
                      </Button>
                    </GridItem>
                  )}
                {(clientData.urlParameters || show) && (
                  <>
                    <GridItem
                      xs={8}
                      sm={10}
                      md={11}
                      lg={11}
                      style={{ display: "flex", alignItems: "baseline" }}
                      key={`textbox_${obj.id}`}
                    >
                      <Controller
                        control={control}
                        name={`url_parameters.${obj.id}.checked`}
                        defaultValue={true}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Tooltip
                            id="tooltip-top"
                            title="Make parameter required"
                            placement="right"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Checkbox
                              checked={value}
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              name={`url_parameters.${obj.id}.checked`}
                              color="secondary"
                            />
                          </Tooltip>
                        )}
                      />
                      <Controller
                        control={control}
                        name={`url_parameters.${obj.id}.label`}
                        render={({ field: { onChange, onBlur } }) => (
                          <CustomInput
                            id={`${obj.id}`}
                            className={classes.inputField}
                            onChange={onChange}
                            onBlur={onBlur}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              defaultValue: getValues(
                                `url_parameters.${obj.id}.label`
                              ),
                            }}
                          />
                        )}
                      />
                    </GridItem>
                    <GridItem
                      xs={4}
                      sm={2}
                      md={1}
                      lg={1}
                      key={`button_${obj.id}`}
                    >
                      {i == 0 ? (
                        <Button
                          size="sm"
                          color="rose"
                          onClick={() => addTextField()}
                        >
                          <AddIcon />
                        </Button>
                      ) : (
                        <Button
                          size="sm"
                          color="rose"
                          onClick={() => removeTextField(obj.id)}
                        >
                          <RemoveIcon />
                        </Button>
                      )}
                    </GridItem>
                  </>
                )}
              </Fragment>
            ))}
          </GridContainer>
        </div>
      );
    } else if (
      blankFields[clientData.acronym].length &&
      blankFields[clientData.acronym].find((obj) => obj == data.field)
    ) {
      return (
        <div className={classes.mTop10} key={data.name + index}>
          <FormLabel className={classes.label}>
            {data.label}
            <Tooltip
              id="tooltip-top"
              title={data.tooltipDescr}
              placement="right"
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                simple
                justIcon
                color="rose"
                style={{ paddingTop: "4px" }}
                tabIndex="-1"
              >
                <InfoIcon style={{ color: "#AAAAAA" }} />
              </Button>
            </Tooltip>
          </FormLabel>
          {utmParameters.map((utm, index) => (
            <div key={`utmParams${index}`}>
              <Controller
                control={control}
                name={`utm_params.${utm.name}.checked`}
                defaultValue={true}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Tooltip
                    id="tooltip-top"
                    title="Make parameter required"
                    placement="right"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Checkbox
                      checked={value}
                      onChange={(e) => {
                        onChange(e);
                        !e.target.checked &&
                          clearErrors(`utm_params.${utm.name}.value`);
                      }}
                      onBlur={onBlur}
                      name={`utm_params.${utm.name}.checked`}
                      color="secondary"
                    />
                  </Tooltip>
                )}
              />
              <span
                className={classes.UrlLabel}
                style={{ marginRight: "1rem" }}
              >
                {utm.name}
              </span>
              <Controller
                control={control}
                name={`utm_params.${utm.name}.value`}
                rules={{
                  required: watch(`utm_params.${utm.name}.checked`)
                    ? `${utm.label} is required`
                    : false,
                  pattern: {
                    value: /^\S*$/g,
                    message: "Whitespace(s) are not allowed",
                  },
                }}
                render={({ field: { onChange } }) => (
                  <CustomInput
                    id={utm.name}
                    onChange={onChange}
                    inputProps={{
                      name: utm.name,
                      type: utm.type,
                      defaultValue: getValues(`utm_params.${utm.name}.value`),
                    }}
                  />
                )}
              />
              <Danger>
                <p className={classes.errorMsgMargin}>
                  {errors?.["utm_params"]?.[utm.name]?.value?.message}
                </p>
              </Danger>
            </div>
          ))}
        </div>
      );
    }
  };

  const addTextField = (val) => {
    if (val) {
      setShow(val);
    } else {
      let tempArray = txtFieldArr;
      tempArray.push({
        id:
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15),
      });
      setTxtFieldArr([...txtFieldArr], tempArray);
    }
  };

  const removeTextField = (id) => {
    let temp = txtFieldArr.filter((item) => item.id !== id);
    setTxtFieldArr(temp);
  };

  // update client data
  const updateClientData = (inputs) => {
    const isAgency = getIsAgency(user);
    const inputData = {
      ...inputs,
      role: user[ROLE_KEY],
      acronym: clientData.acronym,
      client_color: color,
      url_parameters:
        !Object.keys(inputs).includes("url_parameters") ||
        Object.values(inputs?.url_parameters)[0] == undefined ||
        Object.values(inputs?.url_parameters)[0] == ""
          ? ""
          : JSON.stringify(inputs?.url_parameters),
      utm_params: JSON.stringify(inputs.utm_params),
      is_agency: isAgency,
    };
    dispatch(updateClient(inputData));
  };

  // remove data after success
  useEffect(() => {
    return () => {
      dispatch(removeMessage());
    };
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        disableBackdropClick
        maxWidth="md"
        fullWidth
        disableEnforceFocus
      >
        <DialogTitle className={classes.dialogTitle}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={handleClose}
          >
            <Close />
          </Button>
          Edit Client{clientData.acronym && ` - ${clientData.acronym}`}
          {dialogType == "editBlankFieldsDialog" && (
            <div>
              <small>
                Client {clientData.acronym} is missing the following fields:
              </small>
            </div>
          )}
        </DialogTitle>
        <DialogContent>
          {clientVariableName.map((data, index) => {
            return inputField(data, index);
          })}
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            type="submit"
            color="rose"
            className={classes.btnRight}
            onClick={handleSubmit((formdata) => updateClientData(formdata))}
            disabled={clientDetails.updateLoading}
            loading={clientDetails.updateLoading}
          >
            <SaveIcon />
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <DevTool control={control} />
    </div>
  );
}

// define props type
Edit.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  blankFields: PropTypes.array,
  clientData: PropTypes.object,
  dialogType: PropTypes.string,
};
