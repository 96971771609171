// import npm packages
import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import CustomCircularProgress from "components/CustomCircularProgress/CustomCircularProgress.js";

export default function PrivateRoute({ component, ...args }) {
  return (
    <Route
      component={withAuthenticationRequired(component, {
        onRedirecting: () => <CustomCircularProgress />,
      })}
      {...args}
    />
  );
}

// define prop types
PrivateRoute.propTypes = {
  component: PropTypes.func,
};
