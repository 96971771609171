import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material ui icons
import PublishIcon from "@material-ui/icons/Publish";

// core components
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/components/dropzoneStyle.js";

export default function Dropzone(props) {
  // props
  const { text, maxSize, callFile, onChange, onBlur } = props;

  // state to store accepted files
  const [acceptedFile, setAcceptedFile] = useState([]);

  // Redux store variable
  const EmailTemplate = useSelector(({ EmailTemplate }) => EmailTemplate);

  // dropzone methods
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    fileRejections,
    open,
  } = useDropzone({
    accept: { "application/zip": [".zip"] },
    multiple: false,
    maxSize,
    onDrop: (files) => {
      callFile(files);
      setAcceptedFile(files);
    },
  });

  // accepted files
  const acceptFileData = acceptedFile.map((file) => (
    <span key={file.path}> {file.path} </span>
  ));

  // rejected files
  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <div key={file.path}>
        {file.path} -{" "}
        {errors.map((e) => {
          // custom error message to check file size
          if (e.code == "file-too-large") {
            return "File size exceeds 20MB";
          }
          if (e.code == "file-invalid-type") {
            return "File type must be .zip";
          }
          return <span key={e.code}>{e.message}</span>;
        })}
      </div>
    );
  });

  const style = useMemo(
    () => ({
      ...styles.baseStyle,
      ...(isFocused ? styles.focusedStyle : {}),
      ...(isDragAccept ? styles.acceptStyle : {}),
      ...(isDragReject ? styles.rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  useEffect(() => {
    EmailTemplate.successMessage && setAcceptedFile([]);
  }, [EmailTemplate]);

  return (
    <div>
      <div {...getRootProps({ style })} onClick={(e) => e.stopPropagation()}>
        <input
          {...getInputProps({
            onChange,
            onBlur,
          })}
        />
        {text !== undefined ? (
          <div>
            <Button color="rose" onClick={open}>
              <PublishIcon />
              {text}
            </Button>
          </div>
        ) : (
          <div>UPLOAD a FILE</div>
        )}
      </div>
      {/* Display file name */}
      <Success>{acceptFileData}</Success>
      <Danger>{fileRejectionItems}</Danger>
    </div>
  );
}

// define props type
Dropzone.propTypes = {
  text: PropTypes.node,
  maxSize: PropTypes.number,
  callFile: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};
