import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material ui icons
import DownloadIcon from "@material-ui/icons/GetApp";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Checkbox from "@material-ui/core/Checkbox";
import CustomCircularProgress from "components/CustomCircularProgress/CustomCircularProgress.js";
import {
  getBatchHistory,
  downloadBatchTemplate,
} from "redux/BatchHistory/action.js";
import ClientsDialog from "./ClientsDialog";
import { getIsAgency } from "utils/helpers";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/batchHistoryStyle.js";
const useStyles = makeStyles(styles);

export default function View() {
  // styles
  const classes = useStyles();

  const [openClientDialog, setClientDialog] = useState(false);
  const [clientDetails, setClientDetails] = useState([]);
  const [inputs, setInputs] = useState({
    downloadType: "",
  });

  const dispatch = useDispatch();
  const { user: authUser } = useAuth0();
  // Redux store variable
  const batchHistoryData = useSelector(({ BatchHistory }) =>
    Object.keys(BatchHistory.batchHistoryData).length
      ? BatchHistory.batchHistoryData.Items
      : []
  );
  const isAgency = getIsAgency(authUser);
  const batchdata = useSelector(({ BatchHistory }) => BatchHistory);

  // dispatch get all client action
  useEffect(() => {
    dispatch(getBatchHistory(isAgency));
  }, []);

  const columns = [
    {
      id: "batch0",
      name: "Template Name",
      selector: (row) => row.templateName,
      sortable: true,
    },
    {
      id: "batch1",
      name: "Batch Time",
      selector: (row) =>
        moment.unix(row.batchTime).format("YYYY-MM-DD HH:mm:ss"),
      sortable: true,
    },
    {
      id: "batch2",
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
    },
    {
      id: "batch3",
      name: "Action(s)",
      cell: (row) => (
        <Button
          simple
          justIcon
          round
          color="github"
          size="md"
          onClick={() => downloadSingleTemplate(row)}
        >
          <DownloadIcon />
        </Button>
      ),
    },
  ];

  // open client listing dialog
  const clientList = (data) => {
    setClientDetails(data);
    setClientDialog(true);
  };

  // handle single template download
  const downloadSingleTemplate = (data) => {
    setInputs({
      ...inputs,
      downloadType: "",
    });
    const inputData = {};
    inputData[data.templatesBatchHistoryId] = {
      templateName: data.templateName,
      uniqueId: data.uniqueId,
      clientDetails: data.clients,
    };

    dispatch(
      downloadBatchTemplate({
        template_details: inputData,
        is_agency: isAgency,
      })
    );
  };

  // append selected template
  const selectedTemplate = useCallback((state) => {
    const selectedRow = state.selectedRows;

    // store selected client's acronym & template details
    const emailClients = {};
    for (let i = 0; i < selectedRow.length; i++) {
      emailClients[selectedRow[i].templatesBatchHistoryId] = {
        templateName: selectedRow[i].templateName,
        uniqueId: selectedRow[i].uniqueId,
        clientDetails: selectedRow[i].clients,
      };
    }

    setInputs({
      ...inputs,
      template_details: emailClients,
      is_agency: isAgency,
      downloadType: "multiple",
    });
  }, []);

  // download templates
  const downloadSelected = () => {
    dispatch(downloadBatchTemplate(inputs));
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>Batch History</h4>
              </CardText>
              {!batchdata.loading && (
                <Button
                  type="submit"
                  color="rose"
                  className={classes.downloadBtn}
                  onClick={downloadSelected}
                  disabled={
                    inputs.downloadType == "multiple" &&
                    batchdata.downloadLoading
                  }
                  loading={
                    inputs.downloadType == "multiple" &&
                    batchdata.downloadLoading
                  }
                >
                  <DownloadIcon /> Download Selected
                </Button>
              )}
            </CardHeader>
            <CardBody>
              <DataTable
                columns={columns}
                data={batchHistoryData}
                defaultSortFieldId="batch1"
                defaultSortAsc={false}
                onRowClicked={(row) => clientList(row.clients)}
                pointerOnHover
                selectableRows
                selectableRowsComponent={Checkbox}
                onSelectedRowsChange={selectedTemplate}
                pagination
                paginationPerPage={100}
                paginationRowsPerPageOptions={[100, 200, 300, 400]}
                paginationComponentOptions={{
                  rowsPerPageText: "Records per page:",
                  rangeSeparatorText: "out of",
                }}
                progressPending={batchdata.loading}
                customStyles={styles.datatableFontSize}
              />
              {openClientDialog && (
                <ClientsDialog
                  open={openClientDialog}
                  handleClose={() => setClientDialog(false)}
                  clientDetails={clientDetails}
                  batchHistoryData={batchHistoryData}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {!inputs.downloadType && (
        <CustomCircularProgress loading={batchdata.downloadLoading} />
      )}
    </div>
  );
}
