import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert"; // react component used to create sweet alerts
import { useAuth0 } from "@auth0/auth0-react";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// material ui icons
import SearchIcon from "@material-ui/icons/Search";
import DownloadIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Button from "components/CustomButtons/Button.js";
import CustomCircularProgress from "components/CustomCircularProgress/CustomCircularProgress.js";
import ClientListDialog from "./ClientListDialog.js";
import {
  getTemplates,
  getTemplateVariables,
  updateTemplateStatus,
  downloadTemplate,
  removeTemplateData,
} from "redux/EmailTemplate/action.js";
import { removeClientData } from "redux/Client/action.js";
import { getIsAgency } from "utils/helpers/index.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/emailTemplateStyle.js";

const useStyles = makeStyles(styles);

const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <CustomInput
      onChange={onFilter}
      value={filterText}
      labelText="Filter by template name or category"
      id="search"
      inputProps={{
        type: "text",
        endAdornment: (
          <InputAdornment>
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
      }}
      style={{ width: "30rem" }}
    />
  </>
);

// define props type
FilterComponent.propTypes = {
  filterText: PropTypes.string,
  onFilter: PropTypes.func,
};

export default function View() {
  // state to handle modal
  const [openModal, setModal] = useState(false);
  const [selectedTemplate, setTemplate] = useState("");
  const [filterText, setFilterText] = useState(""); // state to set searched text
  const [showAlert, setAlert] = useState(null); // state manage alert

  const { user: authUser } = useAuth0();
  // Toggle snackbar (Notification)
  const [show, setShow] = useState(false);

  const [notificationProps, setNotificationProps] = useState({
    color: "success",
    message: "",
  });

  const dispatch = useDispatch();
  const isAgency = getIsAgency(authUser);
  // Redux store variable
  const emailTemplates = useSelector(({ EmailTemplate }) => EmailTemplate);
  const emailTemplatesData = useSelector(({ EmailTemplate }) =>
    Object.keys(EmailTemplate.templates).length
      ? EmailTemplate.templates.Items.filter(
          (item) =>
            (item.status !== "inactive" &&
              item.email_template_name &&
              item.email_template_name
                .toLowerCase()
                .includes(filterText.toLowerCase())) ||
            (item.status !== "inactive" &&
              item.templateCategory &&
              item.templateCategory
                .toLowerCase()
                .includes(filterText.toLowerCase()))
        )
      : []
  );

  // download template
  const downloadEmailTemplate = (data) => {
    dispatch(
      downloadTemplate({
        template_id: data.email_template_id,
        template_name: data.email_template_name,
        is_agency: isAgency,
      })
    );
  };

  // warning pop-up while archiving the template
  const warningPopup = (id) => {
    dispatch(removeTemplateData());
    setAlert(
      <div>
        <SweetAlert
          warning
          title="Are you sure you would like to delete this template?"
          onConfirm={() => archiveTemplate(id)}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.dangerBtn}
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        ></SweetAlert>
      </div>
    );
  };

  // archive the template
  const archiveTemplate = (template_id) => {
    dispatch(
      updateTemplateStatus(
        { id: template_id, template_status: "inactive" },
        isAgency
      )
    );
  };

  // define cols for datatable
  const columns = [
    {
      id: "col0",
      name: "Template Name",
      selector: (row) => row.email_template_name,
      cell: (row) => (
        <div
          onClick={() => templateModal(row)}
          className={classes.cursorPointer}
        >
          {row.email_template_name}
        </div>
      ),
      sortable: true,
    },
    {
      id: "col1",
      name: "Template Category",
      selector: (row) => row.templateCategory,
      sortable: true,
    },
    {
      id: "col2",
      name: "Created At",
      selector: (row) =>
        moment.unix(row.createdAt).format("YYYY-MM-DD HH:mm:ss"),
      sortable: true,
    },
    {
      id: "col3",
      name: "Last Used",
      selector: (row) =>
        row.lastUsed
          ? moment.unix(row.lastUsed).format("YYYY-MM-DD HH:mm:ss")
          : "",
      sortable: true,
    },
    {
      id: "col4",
      name: "Action(s)",
      cell: (row) => (
        <div>
          <Button
            simple
            justIcon
            round
            color="github"
            onClick={() => downloadEmailTemplate(row)}
          >
            <DownloadIcon className={classes.actionBtn} />
          </Button>
          <Button
            simple
            justIcon
            round
            color="danger"
            onClick={() => warningPopup(row.email_template_id)}
          >
            <DeleteIcon className={classes.actionBtn} />
          </Button>
        </div>
      ),
    },
  ];

  // dispatch get all templates action
  useEffect(() => {
    dispatch(getTemplates(isAgency));
  }, []);

  // handling snackbar
  const toggleSnackbar = () => setShow(!show);

  useEffect(() => {
    // If any error found, display in notification
    if (emailTemplates.errorMessage) {
      setNotificationProps({
        color: "danger",
        message: emailTemplates.errorMessage,
      });
      setShow(true);
    }

    // If email template created successfully, display in notification
    if (emailTemplates.successMessage) {
      closeModal();

      if (showAlert !== null) {
        // alert if template archived successfully
        setAlert(
          <SweetAlert
            success
            title="Template has been archived"
            onConfirm={() => setAlert(null)}
            showConfirm={false}
          ></SweetAlert>
        );
        setTimeout(() => setAlert(null), 2000); // timeout to success alert
      }

      setNotificationProps({
        color: "success",
        message: emailTemplates.successMessage,
      });
      setShow(true);
    }
  }, [emailTemplates]);

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        filterText={filterText}
      />
    );
  }, [filterText]);

  // open modal
  const templateModal = (templateData) => {
    dispatch(removeTemplateData()); // action to remove data after success
    dispatch(getTemplateVariables(templateData.email_template_id, isAgency)); // takes template id
    setModal(true);
    setTemplate(templateData);
  };
  // close modal
  const closeModal = () => {
    dispatch(removeClientData());
    setModal(false);
  };

  // styles
  const classes = useStyles();

  return (
    <div>
      {showAlert}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>Email Templates</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <DataTable
                columns={columns}
                data={emailTemplatesData}
                defaultSortFieldId="col3"
                defaultSortAsc={false}
                pagination
                paginationPerPage={100}
                paginationRowsPerPageOptions={[100, 200, 300]}
                paginationComponentOptions={{
                  rowsPerPageText: "Records per page:",
                  rangeSeparatorText: "out of",
                }}
                progressPending={emailTemplates.loading}
                subHeader
                subHeaderComponent={
                  !emailTemplates.loading && subHeaderComponentMemo
                }
                customStyles={styles.datatableFontSize}
              />
              {openModal && (
                <ClientListDialog
                  open={openModal}
                  handleClose={closeModal}
                  selectedTemplate={selectedTemplate}
                  showTemplatePreview={true}
                />
              )}
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}></CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <CustomCircularProgress loading={emailTemplates.downloadLoading} />
      <CustomCircularProgress loading={emailTemplates.updateTemplateLoading} />
      <Snackbar
        color={notificationProps.color}
        message={notificationProps.message}
        place="tr"
        open={show}
        close
        closeNotification={toggleSnackbar}
        autoHideDuration={6000}
      />
    </div>
  );
}
