import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

// style for this component
import styles from "assets/jss/material-dashboard-pro-react/components/clientListSelectStyle.js";
const useStyles = makeStyles(styles);

export default function ClientListSelect(props) {
  const { clientData, onChange, value } = props;
  // styles
  const classes = useStyles();

  return (
    <Autocomplete
      id="clientList"
      disableClearable={true}
      options={clientData}
      getOptionLabel={(client) => `${client.acronym} - ${client.clientName}`}
      value={value}
      onChange={(e, newValue) => onChange(newValue)}
      className={classes.root}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select a Client"
          className={classes.textfield}
        />
      )}
    />
  );
}

// define props type
ClientListSelect.propTypes = {
  classes: PropTypes.object,
  clientData: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.object,
};
