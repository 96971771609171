// import npm packages
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Dropzone from "components/Dropzone/Dropzone.js";
import Danger from "components/Typography/Danger.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import { clientCatg, utmParameters } from "utils/constants/index.js";
import {
  uploadTemplate,
  removeTemplateData,
} from "redux/EmailTemplate/action.js";
import { getIsAgency } from "utils/helpers";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/emailTemplateStyle.js";

const useStyles = makeStyles(styles);

export default function Create() {
  const dispatch = useDispatch();
  const { user: authUser } = useAuth0();
  // react-hook-form methods
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      template_category: "",
      template_name: "",
      template_zip: "",
      preview_text: "",
      heroImg_alt: "",
      utm_source: "",
      utm_campaign: "",
      utm_medium: "email",
    },
  });

  // get file
  const [file, setFile] = useState([]);

  // validation state
  const [notificationProps, setNotificationProps] = useState({
    color: "success",
    message: "",
  });

  // Toggle snackbar (Notification)
  const [show, setShow] = useState(false);

  // Redux store variable
  const createTemplateData = useSelector(({ EmailTemplate }) => EmailTemplate);

  const toggleSnackbar = () => setShow(!show);

  // set file to state getting from Child component
  const setFileData = (data) => {
    setFile(data);
  };

  // file validation
  useEffect(() => {
    file.length > 0 && setValue("template_zip", file, { shouldValidate: true });
  }, [file]);

  useEffect(() => {
    // If any error found, display in notification
    if (createTemplateData.errorMessage) {
      setNotificationProps({
        color: "danger",
        message: createTemplateData.errorMessage,
      });
      toggleSnackbar();
    }

    // If email template created successfully, display in notification
    if (createTemplateData.successMessage) {
      setNotificationProps({
        color: "success",
        message: createTemplateData.successMessage,
      });
      toggleSnackbar();
      // reset form after submission
      reset({
        template_category: "",
        template_name: "",
        template_zip: "",
        preview_text: "",
        heroImg_alt: "",
        utm_source: "",
        utm_campaign: "",
        utm_medium: "email",
      });

      // remove notify msg from redux store on success
      dispatch(removeTemplateData());
    }
  }, [createTemplateData]);

  // submit template
  const submitTemplate = (inputs) => {
    const isAgency = getIsAgency(authUser);
    if (file.length > 0) {
      var uploads = new FormData();
      uploads.append("template_category", inputs.template_category);
      uploads.append("template_name", inputs.template_name);
      uploads.append("template_zip_name", file[0].name);
      uploads.append("template_zip", file[0]);
      uploads.append("preview_text", inputs.preview_text);
      uploads.append("heroImg_alt", inputs.heroImg_alt);
      uploads.append("utm_source", inputs.utm_source);
      uploads.append("utm_campaign", inputs.utm_campaign);
      uploads.append("utm_medium", inputs.utm_medium);
      // dispatch the formData to action
      dispatch(uploadTemplate(uploads, isAgency));
    }
  };
  // styles
  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" text>
            <CardText color="rose">
              <h4 className={classes.cardTitle}>Create Email Template</h4>
            </CardText>
          </CardHeader>
          <form onSubmit={handleSubmit(submitTemplate)}>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    Category<Danger>*</Danger>
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <Controller
                    control={control}
                    name="template_category"
                    rules={{
                      required: "Category is required",
                    }}
                    render={({ field: { value, ...field } }) => (
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="template_category"
                          className={classes.selectLabel}
                        >
                          Choose Category
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={value}
                          inputProps={{
                            name: "template_category",
                            id: "template_category",
                          }}
                          {...field}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                          >
                            Choose Category
                          </MenuItem>
                          {clientCatg.map((catg, index) => {
                            return (
                              <MenuItem
                                key={`catg${index}`}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={catg.value}
                              >
                                {catg.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Danger>
                    <p className={classes.errorMsgMargin}>
                      {errors?.template_category &&
                        errors.template_category.message}
                    </p>
                  </Danger>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    Template Name<Danger>*</Danger>
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <Controller
                    control={control}
                    name="template_name"
                    rules={{
                      required: "Template name is required",
                      pattern: {
                        value: /^[A-Za-z0-9 .~_=:-]+$/i,
                        message:
                          "Template name can only have uppercase letters, lowercase letters, numbers, periods (.), hyphens (-), tildes (~), underscores, equal signs, and colons",
                      },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomInput
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: onChange,
                          onBlur: onBlur,
                        }}
                      />
                    )}
                  />
                  <Danger>
                    <p className={classes.errorMsgMargin}>
                      {errors?.template_name && errors.template_name.message}
                    </p>
                  </Danger>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    Preview Text<Danger>*</Danger>
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <Controller
                    control={control}
                    name="preview_text"
                    rules={{
                      required: "Preview text is required",
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomInput
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: onChange,
                          onBlur: onBlur,
                        }}
                      />
                    )}
                  />
                  <Danger>
                    <p className={classes.errorMsgMargin}>
                      {errors?.preview_text?.message}
                    </p>
                  </Danger>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    Hero Image Alt<Danger>*</Danger>
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <Controller
                    control={control}
                    name="heroImg_alt"
                    rules={{
                      required: "Hero Image alt is required",
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomInput
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: onChange,
                          onBlur: onBlur,
                        }}
                      />
                    )}
                  />
                  <Danger>
                    <p className={classes.errorMsgMargin}>
                      {errors?.heroImg_alt?.message}
                    </p>
                  </Danger>
                </GridItem>
              </GridContainer>
              {utmParameters.map((utm, index) => (
                <GridContainer key={`utmParam${index}`}>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      {utm.label}
                      <Danger>*</Danger>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    <Controller
                      control={control}
                      name={utm.name}
                      rules={{
                        required: `${utm.label} is required`,
                        pattern: {
                          value: /^\S*$/g,
                          message: "Whitespace(s) are not allowed",
                        },
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomInput
                          id={utm.name}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            onChange: onChange,
                            onBlur: onBlur,
                          }}
                        />
                      )}
                    />
                    <Danger>
                      <p className={classes.errorMsgMargin}>
                        {errors?.[utm.name]?.message}
                      </p>
                    </Danger>
                  </GridItem>
                </GridContainer>
              ))}
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <FormLabel className={classes.labelHorizontal}>
                    Drag and drop or select zip file for upload
                    <Danger>*</Danger>
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={12}>
                  <FormLabel>
                    <Controller
                      control={control}
                      name="template_zip"
                      rules={{
                        required: "Template zip field is required",
                      }}
                      render={({ field: { onChange, onBlur } }) => (
                        <Dropzone
                          onChange={onChange}
                          onBlur={onBlur}
                          maxSize={20000000}
                          text="UPLOAD ZIP FILE"
                          callFile={setFileData}
                        />
                      )}
                    />
                  </FormLabel>
                  {errors?.template_zip && (
                    <Danger>{errors.template_zip.message}</Danger>
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button
                type="submit"
                color="rose"
                disabled={createTemplateData.uploadLoading}
                loading={createTemplateData.uploadLoading}
              >
                Submit Template
              </Button>
            </CardFooter>
          </form>
          <Snackbar
            color={notificationProps.color}
            message={notificationProps.message}
            place="tr"
            open={show}
            close
            closeNotification={toggleSnackbar}
          />
        </Card>
      </GridItem>
    </GridContainer>
  );
}
