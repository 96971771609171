const ClientTypes = {
  // insert client data
  INSERT_CLIENT: "INSERT_CLIENT",
  INSERT_CLIENT_SUCCESS: "INSERT_CLIENT_SUCCESS",
  INSERT_CLIENT_FAILURE: "INSERT_CLIENT_FAILURE",

  // get client data
  GET_CLIENT: "GET_CLIENT",
  GET_CLIENT_SUCCESS: "GET_CLIENT_SUCCESS",
  GET_CLIENT_FAILURE: "GET_CLIENT_FAILURE",

  // get client data by acronym
  GET_CLIENT_BY_ACRONYM: "GET_CLIENT_BY_ACRONYM",
  GET_CLIENT_BY_ACRONYM_SUCCESS: "GET_CLIENT_BY_ACRONYM_SUCCESS",
  GET_CLIENT_BY_ACRONYM_FAILURE: "GET_CLIENT_BY_ACRONYM_FAILURE",

  // get clients by category
  GET_CLIENT_BY_CATEGORY: "GET_CLIENT_BY_CATEGORY",
  GET_CLIENT_BY_CATEGORY_SUCCESS: "GET_CLIENT_BY_CATEGORY_SUCCESS",
  GET_CLIENT_BY_CATEGORY_FAILURE: "GET_CLIENT_BY_CATEGORY_FAILURE",

  // update client data
  UPDATE_CLIENT: "UPDATE_CLIENT",
  UPDATE_CLIENT_SUCCESS: "UPDATE_CLIENT_SUCCESS",
  UPDATE_CLIENT_FAILURE: "UPDATE_CLIENT_FAILURE",

  // update client's images
  UPLOAD_IMAGES: "UPLOAD_IMAGES",
  UPLOAD_IMAGES_SUCCESS: "UPLOAD_IMAGES_SUCCESS",
  UPLOAD_SIGNATURE_IMAGES_SUCCESS: "UPLOAD_SIGNATURE_IMAGES_SUCCESS",
  UPLOAD_IMAGES_FAILURE: "UPLOAD_IMAGES_FAILURE",

  // remove clinet data
  REMOVE_CLIENT_DATA: "REMOVE_CLIENT_DATA",

  // remove clinet data
  REMOVE_MESSAGE: "REMOVE_MESSAGE",

  SELECTED_EMAIL_CLIENTS: "SELECTED_EMAIL_CLIENTS",
};

export default ClientTypes;
