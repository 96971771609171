import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  patchRequest,
  putS3Request,
  getRequestWithHeader,
  postRequestWithoutBaseUrl,
  patchRequestWithoutBaseUrl,
} from "./http.js";

export function postRequestAPI({ url = "", data = {} }) {
  return postRequest(url, data);
}

export function postRequestAPIWithoutBaseUrl({ url = "", data = {} }) {
  return postRequestWithoutBaseUrl(url, data);
}

export function patchRequestAPI({ url = "", data = {} }) {
  return patchRequest(url, data);
}

export function patchRequestAPIWithoutBaseUrl({
  url = "",
  data = {},
  options = {},
}) {
  return patchRequestWithoutBaseUrl(url, data, options);
}

export function putRequestAPI({ url = "", data = {} }) {
  return putRequest(url, data);
}

export function putS3RequestAPI({ url = "", data = {}, options = {} }) {
  return putS3Request(url, data, options);
}

export function deleteRequestAPI({ url = "", data = {} }) {
  return deleteRequest(url, data);
}

export function getRequestAPI({ url = "", params = {} }) {
  return getRequest(url, params);
}

export function getRequestAPIWithHeader({ url = "", headerParams = {} }) {
  return getRequestWithHeader(url, headerParams);
}
