// import npm packages
import { takeLatest, call, put, all } from "redux-saga/effects";

// core components
import {
  postRequestAPI,
  putRequestAPI,
  putS3RequestAPI,
  getRequestAPI,
} from "../../utils/services/api";
import {
  uploadTemplateSuccess,
  uploadTemplateFailure,
  getTemplatesSuccess,
  getTemplatesFailure,
  createEmailTemplateSuccess,
  createEmailTemplateFailure,
  getTemplateVariablesSuccess,
  getTemplateVariablesFailure,
  getTemplates,
  updateTemplateStatusSuccess,
  updateTemplateStatusFailure,
  downloadTemplateSuccess,
  downloadTemplateFailure,
} from "./action";
import {
  generatePresignedUrl,
  extractTemplateUrl,
  getTemplatesUrl,
  createEmailTemplateUrl,
  updateTemplateStatusUrl,
  downloadTemplateUrl,
} from "../baseUrl";
import EmailTemplateTypes from "./type";

export function* makeUploadTemplateRequest(action) {
  try {
    // Create presigned URL to upload file into S3 as API gateway support payload size upto 10 MB only.
    const response = yield call(postRequestAPI, {
      url: generatePresignedUrl,
      data: {
        template_name: action.payload.get("template_name"),
        template_zip_name: action.payload.get("template_zip_name"),
        is_agency: action.userData,
      },
    });

    // If we got presigned URL call that URL to upload file
    if (response.success) {
      const {
        processFileLocation,
        preSignedS3URL,
        template_id,
      } = response.payload;

      // Upload zip file to S3 using presigned URL
      const s3response = yield call(putS3RequestAPI, {
        url: preSignedS3URL,
        data: action.payload.get("template_zip"),
        options: {
          headers: { "Content-Type": "application/zip" },
        },
      });

      // If we got response as empty means file is uploaded successfully
      if (s3response == "" || s3response.response.status == 200) {
        // Upload zip file to S3 using presigned URL
        const extractResponse = yield call(putRequestAPI, {
          url: extractTemplateUrl,
          data: {
            template_id: template_id,
            template_category: action.payload.get("template_category"),
            template_name: action.payload.get("template_name"),
            template_zip: processFileLocation,
            preview_text: action.payload.get("preview_text"),
            heroImg_alt: action.payload.get("heroImg_alt"),
            utm_source: action.payload.get("utm_source"),
            utm_campaign: action.payload.get("utm_campaign"),
            utm_medium: action.payload.get("utm_medium"),
            is_agency: action.userData,
          },
        });

        if (extractResponse.success) {
          yield put(uploadTemplateSuccess(extractResponse));
        } else {
          yield put(uploadTemplateFailure(s3response.msg));
        }
      } else {
        yield put(
          uploadTemplateFailure("Something went wrong! Please try again later.")
        );
      }
    } else {
      yield put(uploadTemplateFailure(response.msg));
    }
  } catch (error) {
    yield put(uploadTemplateFailure(error.message));
  }
}

// create email templates
export function* makeCreateTemplateRequest(action) {
  try {
    const response = yield call(putRequestAPI, {
      url: createEmailTemplateUrl,
      data: action.payload,
    });

    if (response.success) {
      yield put(createEmailTemplateSuccess(response));
      if (response.payload) window.location.href = response.payload;
      yield put(getTemplates(action.payload.is_agency));
    } else {
      yield put(createEmailTemplateFailure(response.msg));
    }
  } catch (error) {
    yield put(createEmailTemplateFailure(error.message));
  }
}

// get email templates
export function* makeGetTemplatesRequest(action) {
  try {
    const response = yield call(getRequestAPI, {
      url: getTemplatesUrl + `?isAgencyData=${JSON.stringify(action.payload)}`,
    });

    if (response.success) {
      yield put(getTemplatesSuccess(response.payload));
    } else {
      yield put(getTemplatesFailure(response.msg));
    }
  } catch (error) {
    yield put(getTemplatesFailure(error.message));
  }
}

// get email template variables
export function* GetTemplateVariablesRequest(action) {
  try {
    const response = yield call(getRequestAPI, {
      url: `${getTemplatesUrl}${
        action.payload
      }/true?isAgencyData=${JSON.stringify(action.userData)}`,
    });

    if (response.success) {
      yield put(getTemplateVariablesSuccess(response.payload));
    } else {
      yield put(getTemplateVariablesFailure(response.msg));
    }
  } catch (error) {
    yield put(getTemplateVariablesFailure(error.message));
  }
}

// update email template status
export function* UpdateTemplateStatusRequest(action) {
  try {
    const response = yield call(putRequestAPI, {
      url: updateTemplateStatusUrl + action.payload.id,
      data: action.payload,
    });

    if (response.success) {
      yield put(updateTemplateStatusSuccess(response));
      yield put(getTemplates(action.userData));
    } else {
      yield put(updateTemplateStatusFailure(response.msg));
    }
  } catch (error) {
    yield put(updateTemplateStatusFailure(error.message));
  }
}

// download email template
export function* DownloadTemplateRequest(action) {
  try {
    const response = yield call(putRequestAPI, {
      url: downloadTemplateUrl,
      data: action.payload,
    });

    if (response.success) {
      if (response.payload) window.location.href = response.payload;
      yield put(downloadTemplateSuccess(response));
    } else {
      yield put(downloadTemplateFailure(response.msg));
    }
  } catch (error) {
    yield put(downloadTemplateFailure(error.message));
  }
}

export function* watchEmailTemplate() {
  yield takeLatest(
    EmailTemplateTypes.UPLOAD_TEMPLATE,
    makeUploadTemplateRequest
  );
  yield takeLatest(EmailTemplateTypes.CREATE_EMAILS, makeCreateTemplateRequest);
  yield takeLatest(EmailTemplateTypes.GET_TEMPLATES, makeGetTemplatesRequest);
  yield takeLatest(
    EmailTemplateTypes.GET_TEMPLATE_VARIABLES,
    GetTemplateVariablesRequest
  );
  yield takeLatest(
    EmailTemplateTypes.UPDATE_TEMPLATE_STATUS,
    UpdateTemplateStatusRequest
  );
  yield takeLatest(
    EmailTemplateTypes.DOWNLOAD_TEMPLATE,
    DownloadTemplateRequest
  );
}

export function* EmailTemplateSagas() {
  yield all([call(watchEmailTemplate)]);
}
