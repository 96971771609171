/*eslint-disable*/
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import html2canvas from "html2canvas";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import PublishIcon from "@material-ui/icons/Publish";
import DownloadIcon from "@material-ui/icons/GetApp";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Danger from "components/Typography/Danger.js";
import Edit from "views/Client/Edit.js";
import AddTemplateDetails from "views/EmailEditor/AddTemplateDetails.js";
import { clientCatg } from "utils/constants/index.js";
import {
  getClientByCategory,
  setSelectedEmailClients,
  removeMessage,
} from "redux/Client/action.js";
import { createEmailTemplate } from "redux/EmailTemplate/action.js";
import { publishEmail, downloadClientEmail } from "redux/EmailEditor/action.js";
import UrlContructor from "./UrlConstructor.js";
import { getIsAgency } from "utils/helpers/index.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/emailTemplateStyle.js";
const useStyles = makeStyles(styles);

export default function ClientListDialog(props) {
  // props
  const {
    open,
    handleClose,
    selectedTemplate,
    showTemplatePreview,
    jsonTemplate,
    htmlTemplate,
  } = props;

  const [category, setCategory] = useState("");
  let [inputs, setInputs] = useState([]);
  const [openEditModal, setEditModal] = useState(false); // handle edit client details dialog
  const [blankFields, setblankFields] = useState([]);
  const [templatePrev, setTemplatePrev] = useState(false); // handle template preview visibility
  const [clearSelectedClients, setClearSelectedClients] = useState(false);
  const [clientInfo, setClientInfo] = useState([]);
  const [emailClients, setEmailClients] = useState([]); // store selected client details
  const [settingsDialog, setSettingsDialog] = useState(false); // handle add email details dialog
  const [urlModal, setUrlModal] = useState(false); // handle URL contructor modal
  const [singleClientData, setSingleClientData] = useState(""); // store client data for url contructor
  const [addDataModal, setAddDataModal] = useState(false);
  const [image, setImage] = useState("");
  // Toggle snackbar (Notification)
  const [show, setShow] = useState(false);
  let checkArray = [];
  const [notificationProps, setNotificationProps] = useState({
    color: "success",
    message: "",
  });

  const dispatch = useDispatch();
  const { user: authUser } = useAuth0();
  // Redux store variable
  const clients = useSelector(({ EmailClient }) =>
    Object.keys(EmailClient.clientsList).length
      ? EmailClient.clientsList.Items
      : []
  );
  const UrlConstructData = useSelector(({ EmailTemplate }) =>
    EmailTemplate.UrlConstructData?.length
      ? EmailTemplate.UrlConstructData
      : []
  );
  const clientDetails = useSelector(({ EmailClient }) => EmailClient);
  const selectedEmailClients = useSelector(
    ({ EmailClient }) => EmailClient.selectedEmailClients
  );
  const templateData = useSelector(({ EmailTemplate }) => EmailTemplate);
  const { publishEmailLoading, downloadEmailsLoading } = useSelector(({ EmailEditor }) => EmailEditor);

  // handle occurrence of Df url input field
  const temp = [];
  clients.map((obj) => {
    return (temp[obj.acronym] = false);
  });
  const [checkedAcronym, setCheckedAcronym] = useState(temp); // if checked then show textbox else don't
  const captureElement = async () => {
    // generate unique id
    const unique_id = Math.random().toString(36).substring(2, 15);

    if (templateData.htmlTemplate) {
      // Create iframe runtime
      const iframe = document.createElement("iframe");
      iframe.id = unique_id;
      iframe.style.width = "100%";
      iframe.style.visibility = "hidden";
      document.body.appendChild(iframe);
      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(templateData.htmlTemplate);
      iframe.contentWindow.document.close();

      const canvas = await html2canvas(iframe.contentWindow.document.body, {
        backgroundColor: "none",
        useCORS: true, //to enable cross origin params
        width: document.documentElement.clientWidth,
        height: 1000,
      });
      canvas && setTemplatePrev(true);

      const imageData = canvas.toDataURL("image/png"); // Convert canvas into image
      setImage(imageData);

      // Delete iFrame after image created
      setTimeout(() => {
        const hiddeniframe = document.getElementById(unique_id);
        hiddeniframe.parentNode.removeChild(hiddeniframe);
      }, 1000);
    }
  };

  useEffect(() => {
    if (image && templatePrev) {
      document.getElementById("showImg").src = image; // append into image tag
    }
  }, [templatePrev, image]);

  useEffect(() => {
    if (showTemplatePreview && !templateData.updateVariablesLoading) {
      captureElement();
    }
  }, [showTemplatePreview, templateData.updateVariablesLoading]);

  const findClientEmptyFields = () => {
    let blankDataArr = [];
    let uniqData = [];

    if (showTemplatePreview) {
      // camelize template variables
      const camelizeData =
        templateData.templateVariables?.length
          ? camelize(templateData.templateVariables)
          : [];

      uniqData = [...new Set(camelizeData)]; // Remove duplicates
    } else {
      // fetch email placeholders
      const templateVariables = jsonTemplate.match(/{{[^{}]*}}/g);

      // Remove duplicates
      let uniq = [...new Set(templateVariables)];
      uniq = uniq.map((placeholder) => placeholder.replace(/[{}]/g, ""));

      // camelize template variables
      uniqData = uniq?.length ? camelize(uniq) : [];
    }

    for (let i = 0; i < uniqData.length; i++) {
      const indexFName = uniqData.indexOf("firstName");
      if (indexFName !== -1) {
        uniqData.splice(indexFName, 1, "emailPlatform");
      }
      // remove preview-text placeholder
      const indexPreviewText = uniqData.indexOf("previewText");
      indexPreviewText !== -1 && uniqData.splice(indexPreviewText, 1);
      // add UTM params
      !uniqData.includes("utmParameters") && uniqData.push("utmParameters");
      !uniqData.includes("urlParameters") && uniqData.push("urlParameters");

      const templateVar = uniqData[i];
      // check if email place holders having missing data in client details
      for (const key in clients) {
        const element = clients[key];
        if (element.hasOwnProperty.call(templateVar) || !element[templateVar]) {
          if (!blankDataArr[element.acronym]) {
            blankDataArr[element.acronym] = [];
          }

          blankDataArr[element.acronym].push(templateVar);

          // For UTM/URL params
          const utmCheck = element?.utmParams ? JSON.parse(element?.utmParams) : {};
          if (Object.keys(utmCheck).length) {
            Object.keys(utmCheck)?.map((e, i) => {
              if ((utmCheck[e].checked && utmCheck[e].value !== "") || !utmCheck[e].checked) {
                const index = blankDataArr[element.acronym].indexOf("utmParameters");
                const index1 = blankDataArr[element.acronym].indexOf("urlParameters");
                if (index > -1 || index1 > -1) {
                  blankDataArr[element.acronym].splice(
                    index ? index : index1,
                    1
                  );
                }
              }
            });
          }
          // For Humane
          if (
            element.clientCategory == "Humane" &&
            (templateVar == "dollarAmount" || templateVar == "mealsAmount")
          ) {
            const index = blankDataArr[element.acronym].indexOf("dollarAmount");
            const indexMeals = blankDataArr[element.acronym].indexOf(
              "mealsAmount"
            );
            // remove dollarAmount & mealsAmount placeholder
            if (index > -1 || indexMeals > -1) {
              blankDataArr[element.acronym].splice(
                index ? index : indexMeals,
                1
              );
            }
          }
          // For Food Bank clients
          if (
            element.clientCategory == "Food Bank" &&
            templateVar == "defaultAlgorithm"
          ) {
            const index = blankDataArr[element.acronym].indexOf(
              "defaultAlgorithm"
            );
            // remove defaultAlgorithm placeholder
            if (index > -1) {
              blankDataArr[element.acronym].splice(index, 1);
            }
          }

          if (!blankDataArr[element.acronym].length) {
            delete blankDataArr[element.acronym];
          }
        }
      }
    }

    setblankFields(blankDataArr);
  };

  useEffect(() => {
    if (clients.length) {
      findClientEmptyFields();
    }
  }, [clients]);

  // define cols for datatable
  const columns = [
    {
      id: "1",
      name: "Client Acronym",
      selector: (row) => row.acronym,
      sortable: true,
    },
    {
      id: "2",
      name: "Client Name",
      selector: (row) => row.clientName,
      sortable: true,
    },
    {
      id: "3",
      cell: (row) => {
        // if client is selected then display button
        if (checkedAcronym[row.acronym]) {
          const data = UrlConstructData?.filter(
            (e) => e.acronym == row.acronym
          );
          let str = "";
          let btnDisable = false;
          let utmData = row?.utmParams ? JSON.parse(row?.utmParams) : {};
          let urlData = row?.urlParameters ? JSON.parse(row?.urlParameters) : {};
          if (Object.keys(utmData).length) {
            Object.keys(utmData)?.map((e, i) => {
              if (utmData[e].checked && utmData[e].value == "" && data.length == 0) {
                checkArray.includes(row.acronym) ? '' : checkArray.push(row.acronym);
              }
              if (utmData[e].checked && utmData[e].value !== "") {
                str += `&${Object.keys(utmData)[i]}=${utmData[e].value}`;
              }
            });
          } else {
            btnDisable = true;
          }
          if (urlData) {
            Object.keys(urlData)?.map((e) => {
              if (urlData[e].checked) {
                checkArray.includes(row.acronym) ? '' : checkArray.push(row.acronym);
              }
              if (urlData[e].checked && data.length) {
                let index = checkArray.indexOf(row.acronym);
                if (index !== -1) {
                  checkArray.splice(index, 1);
                }
              }
            });
          }
          return (
            <>
              <Tooltip
                id="tooltip-left"
                title={data.length ? data[0]?.contructed_url : row.defaultDfUrl + str}
                placement="right"
                classes={{ tooltip: classes.image }}
              >
                <Button
                  color="rose"
                  size="sm"
                  onClick={() => handleUrlContructor(row)}
                  disabled={btnDisable}
                >
                  URL Constructor
                </Button>
              </Tooltip>
              {checkArray.includes(row.acronym) && <Danger>
                <p style={{ margin: "0px 0px 0px 15px", fontSize: "small", lineHeight: "1rem", fontWeight: "500" }}>
                  URL parameters are missing.
                </p>
              </Danger>}
            </>
          );
        }
      },
    },
    {
      id: "4",
      cell: (row) => {
        if (
          checkedAcronym[row.acronym] &&
          Object.keys(blankFields)?.length > 0 &&
          blankFields[row.acronym]?.length
        ) {
          return (
            <div key={row.acronym}>
              <Tooltip
                id="tooltip-left"
                title="This template requires fields that have not been filled out for this client. Click the red icon to fill remaining details."
                placement="left"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => setEditModal(row.acronym)}
                >
                  <InfoIcon color="error" />
                </Button>
              </Tooltip>
              {openEditModal && (
                <Edit
                  open={openEditModal == row.acronym}
                  handleClose={() => setEditModal(false)}
                  blankFields={blankFields}
                  clientData={row}
                  dialogType="editBlankFieldsDialog"
                />
              )}
            </div>
          );
        }
      },
      center: true,
    },
  ];

  const handleUrlContructor = (data) => {
    setSingleClientData(data);
    setUrlModal(true);
  };

  // get client by category
  const clientCategory = (event) => {
    const isAgency = getIsAgency(authUser);
    setCheckedAcronym(temp);
    setClearSelectedClients(true);
    setCategory(event.target.value);
    dispatch(setSelectedEmailClients([]));
    dispatch(getClientByCategory(event.target.value, isAgency));
  };

  // append selected client's acronym & toggle occurence of df url input field
  const selectedClient = useCallback(
    (state) => {
      const selectedRow = state.selectedRows;
      // array to store selected client's acronym
      let selectedClients = [];
      let client = [];
      for (let i = 0; i < selectedRow.length; i++) {
        const acronym = selectedRow[i].acronym;
        selectedClients.push(acronym);
        temp[acronym] = true;

        client.push({
          acronym,
          client_name: selectedRow[i].clientName,
          client_category: selectedRow[i].clientCategory,
        });
      }
      setClientInfo(client);
      setEmailClients(selectedRow);

      setInputs({
        ...inputs,
        template_details: selectedTemplate,
        selected_clients: selectedClients,
      });
      selectedClients.length > 0 &&
        dispatch(setSelectedEmailClients(selectedClients));
      setCheckedAcronym(temp);
    },
    [temp]
  );

  // function to change string to camel case
  const camelize = (str) => {
    if (typeof str == "object") {
      const arr = [];
      for (let i = 0; i < str.length; i++) {
        const camelCase = str[i]
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
        arr.push(camelCase);
      }
      return arr;
    }
    return str
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  };

  // display notification for error message
  const toggleSnackbar = () => setShow(!show);
  useEffect(() => {
    // If any error found, display in notification
    if (clientDetails.errorMessage) {
      setNotificationProps({
        color: "danger",
        message: clientDetails.errorMessage,
      });
      setShow(true);
    }
    const isAgency = getIsAgency(authUser);
    // If email template created successfully, display in notification
    if (clientDetails.successMessage) {
      setEditModal(false);
      dispatch(removeMessage()); // remove success message after success
      category && dispatch(getClientByCategory(category, isAgency));
    }
  }, [clientDetails]);

  // Reset selected client on unmount component
  useEffect(() => {
    return () => {
      dispatch(setSelectedEmailClients([]));
    };
  }, []);

  // Pre selected checkbox updating client data
  const setDefaultSelected = useMemo(
    () => (row) => {
      return selectedEmailClients.length > 0
        ? selectedEmailClients.includes(row.acronym)
        : false;
    },
    [clientDetails.clientCatgLoading]
  );

  // submit email template
  const createEmail = (btnText) => {
    // As blankfields contains all the clients so we need to check with selected clients fields only
    let intersection = Object.keys(blankFields).filter((e) => inputs.selected_clients.includes(e));

    // get constructed donation URL
    const data = UrlConstructData?.filter((e) =>
      inputs.selected_clients.includes(e.acronym)
    );
    let Obj = {};
    let UrlParamsObj = {};
    data?.filter((e) => {
      Obj = Object.assign({ ...Obj, [e.acronym]: e.contructed_url });
    });
    let constructedUrl = Obj;
    // If client is not selected then no need to proceed further
    if (Object.keys(inputs).length == 0 || inputs.selected_clients.length == 0)
      return false;

    // email details
    const emailData = localStorage.getItem("emailData");

    const inputsArray = {
      template_details: inputs.template_details,
      selected_clients: [],
      client_info: clientInfo,
      urlParams: {},
    };
    // manage URL/UTM params
    for (const element of emailClients) {
      let utmData = element?.utmParams ? JSON.parse(element?.utmParams) : {};
      if (Object.keys(constructedUrl).includes(element.acronym)) {
        inputsArray.selected_clients.push({
          acronym: element.acronym,
          url: constructedUrl[element.acronym],
        });
        if (element.defaultDfUrl) {
          const finalParams = constructedUrl[element.acronym].split(element.defaultDfUrl);
          let final = "";
          if (finalParams[1].charAt(0) == "?") {
            final = finalParams[1].substring(finalParams[1].indexOf('?')+1);
          } else if (finalParams[1].charAt(0) == "&") {
            final = finalParams[1].substring(finalParams[1].indexOf('&')+1);
          }
          UrlParamsObj = Object.assign({ ...UrlParamsObj, [element.acronym]: final });
        }else{
          UrlParamsObj = Object.assign({ ...UrlParamsObj, [element.acronym]: constructedUrl[element.acronym] });
        }
      } else {
        if (element.defaultDfUrl) {
          if (Object.keys(utmData).length) {
            let str = "";
            Object.keys(utmData)?.map((e, i) => {
              if (utmData[e].checked && utmData[e].value !== "") {
                str += `&${Object.keys(utmData)[i]}=${utmData[e].value}`;
              }
            });
            inputsArray.selected_clients.push({
              acronym: element.acronym,
              url: element.defaultDfUrl + str,  
            });
            let finalStr = "";
            if (str.charAt(0) == "?") {
              finalStr = str.substring(str.indexOf('?')+1);
            } else if (str.charAt(0) == "&") {
              finalStr = str.substring(str.indexOf('&')+1);
            }
            UrlParamsObj = Object.assign({ ...UrlParamsObj, [element.acronym]: finalStr });
            Obj = Object.assign({ ...Obj, [element.acronym]: element.defaultDfUrl + str });
          } else {
            inputsArray.selected_clients.push({
              acronym: element.acronym,
              url: element.defaultDfUrl,
            });
            UrlParamsObj = Object.assign({ ...UrlParamsObj, [element.acronym]: "" });
            Obj = Object.assign({ ...Obj, [element.acronym]: element.defaultDfUrl });
          }
        }
      }
    }
    inputsArray.urlParams = UrlParamsObj;
    constructedUrl = Obj;
    const isAgency = getIsAgency(authUser);
    if (showTemplatePreview) {
      if (
        inputs.selected_clients.length == inputsArray.selected_clients.length &&
        intersection.length == 0 &&
        checkArray.length == 0
        ) {
          inputsArray.is_agency = isAgency;
          // console.log("inputsArray:", inputsArray);
          dispatch(createEmailTemplate(inputsArray));
        }
      } else if (
      emailData &&
      jsonTemplate &&
      htmlTemplate &&
      inputs.selected_clients.length == emailClients.length
    ) {
      if (
        inputs.selected_clients.length == inputsArray.selected_clients.length &&
        intersection.length == 0 &&
        checkArray.length == 0
      ) {
        if (btnText == "publish") {
          const emailDetails = {
            jsonEmailTemplate: jsonTemplate,
            htmlEmailTemplate: htmlTemplate,
            email_details: emailData,
            client_details: emailClients,
            df_url: constructedUrl,
            urlParams: UrlParamsObj,
            heroImg_data: localStorage.getItem("heroImg_data"),
            is_agency: isAgency,
          };
          // console.log("emailDetails:", emailDetails);
          dispatch(publishEmail(emailDetails));
        } else {
          dispatch(
            downloadClientEmail({
              client_details: emailClients,
              email_details: emailData,
              htmlEmailTemplate: htmlTemplate,
              constructedUrl,
              urlParams: UrlParamsObj,
              is_agency: isAgency,
            })
          );
        }
      }
    } else {
      setSettingsDialog(true);
    }
  };

  // styles
  const classes = useStyles();

  return (
    <>
      <Dialog open={open} disableBackdropClick maxWidth="xl" fullWidth>
        {templateData.updateVariablesLoading ? (
          <div className={classes.circularLoader}>
            <CircularProgress
              size={70}
              className={classes.circularLoader}
              color="secondary"
            />
          </div>
        ) : (
          <>
            <DialogTitle>
              {showTemplatePreview ? "Client Details" : "Select Clients"}
              <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={handleClose}
              >
                <Close />
              </Button>
            </DialogTitle>
            <DialogContent>
              {showTemplatePreview && (
                <div className={classes.imageContainer}>
                  <div className={classes.imageInner}>
                    {templatePrev ? (
                      <img
                        id="showImg"
                        alt={selectedTemplate.email_template_name}
                        title={selectedTemplate.email_template_name}
                        className={classes.image}
                      />
                    ) : (
                      <span>Loading...</span>
                    )}
                  </div>
                </div>
              )}
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="client_category"
                  className={classes.selectLabel}
                >
                  Choose Client Category
                </InputLabel>
                <Select
                  MenuProps={{ className: classes.selectMenu }}
                  classes={{ select: classes.select }}
                  value={category}
                  onChange={clientCategory}
                  inputProps={{
                    name: "client_category",
                    id: "client_category",
                    defaultValue: category ? category : "",
                  }}
                >
                  <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                    Choose Client Category
                  </MenuItem>
                  {clientCatg.map((catg, index) => {
                    return (
                      <MenuItem
                        key={`catg${index}`}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={catg.value}
                      >
                        {catg.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {clients.length > 0 && !clientDetails.clientCatgLoading && (
                <DataTable
                  columns={columns}
                  data={clients}
                  defaultSortFieldId="1"
                  pagination
                  paginationPerPage={50}
                  paginationRowsPerPageOptions={[50, 100, 150, 200]}
                  paginationComponentOptions={{
                    rowsPerPageText: "Records per page:",
                    rangeSeparatorText: "out of",
                  }}
                  selectableRows
                  onSelectedRowsChange={selectedClient}
                  selectableRowsComponent={Checkbox}
                  selectableRowSelected={setDefaultSelected}
                  clearSelectedRows={clearSelectedClients}
                  customStyles={styles.datatableFontSize}
                />
              )}
              {clientDetails.clientCatgLoading && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      fontSize: "24px",
                      fontWeight: 700,
                      padding: "24px",
                    }}
                  >
                    Loading...
                  </div>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              {showTemplatePreview ? (
                <Button
                  type="submit"
                  color="rose"
                  style={{ marginRight: "10px" }}
                  onClick={createEmail}
                  disabled={templateData.createLoading}
                  loading={templateData.createLoading}
                >
                  Create Emails
                </Button>
              ) : (
                <>
                  <Button
                    type="submit"
                    color="rose"
                    style={{ marginRight: "10px" }}
                    onClick={() => createEmail("download")}
                    disabled={downloadEmailsLoading}
                    loading={downloadEmailsLoading}
                  >
                    <DownloadIcon /> Download
                  </Button>
                  <Button
                    type="submit"
                    color="rose"
                    style={{ marginRight: "10px" }}
                    onClick={() => createEmail("publish")}
                    disabled={publishEmailLoading}
                    loading={publishEmailLoading}
                  >
                    <PublishIcon /> Publish to client view
                  </Button>
                </>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
      {settingsDialog && (
        <AddTemplateDetails
          open={settingsDialog}
          category={category}
          handleClose={() => setSettingsDialog(false)}
        />
      )}
      {addDataModal && (
        <AddTemplateDetails
          open={addDataModal}
          handleClose={() => setAddDataModal(false)}
        />
      )}
      {urlModal && (
        <UrlContructor
          open={urlModal}
          handleClose={() => setUrlModal(false)}
          client_data={singleClientData}
        />
      )}
      <Snackbar
        color={notificationProps.color}
        message={notificationProps.message}
        place="tr"
        open={show}
        close
        closeNotification={toggleSnackbar}
      />
    </>
  );
}

// define props type
ClientListDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedTemplate: PropTypes.object,
  showTemplatePreview: PropTypes.bool,
  jsonTemplate: PropTypes.string,
  htmlTemplate: PropTypes.string,
  category: PropTypes.string,
};
