const EmailEditorTypes = {
  // beePro login
  BEE_LOGIN: "BEE_LOGIN",
  BEE_LOGIN_SUCCESS: "BEE_LOGIN_SUCCESS",
  BEE_LOGIN_FAILURE: "BEE_LOGIN_FAILURE",

  // get all bee email templates
  GET_BEE_TEMPLATES: "GET_BEE_TEMPLATES",
  GET_BEE_TEMPLATES_SUCCESS: "GET_BEE_TEMPLATES_SUCCESS",
  GET_BEE_TEMPLATES_FAILURE: "GET_BEE_TEMPLATES_FAILURE",

  // get email tags
  GET_EMAIL_TAGS: "GET_EMAIL_TAGS",
  GET_EMAIL_TAGS_SUCCESS: "GET_EMAIL_TAGS_SUCCESS",
  GET_EMAIL_TAGS_FAILURE: "GET_EMAIL_TAGS_FAILURE",

  // get selected bee email template
  GET_SINGLE_BEE_TEMPLATE: "GET_SINGLE_BEE_TEMPLATE",
  GET_SINGLE_BEE_TEMPLATE_SUCCESS: "GET_SINGLE_BEE_TEMPLATE_SUCCESS",
  GET_SINGLE_BEE_TEMPLATE_FAILURE: "GET_SINGLE_BEE_TEMPLATE_FAILURE",

  // save bee email template changes
  SAVE_BEE_TEMPLATE: "SAVE_BEE_TEMPLATE",
  SAVE_BEE_TEMPLATE_SUCCESS: "SAVE_BEE_TEMPLATE_SUCCESS",
  SAVE_BEE_TEMPLATE_FAILURE: "SAVE_BEE_TEMPLATE_FAILURE",

  // publish email to client view
  PUBLISH_EMAIL: "PUBLISH_EMAIL",
  PUBLISH_EMAIL_SUCCESS: "PUBLISH_EMAIL_SUCCESS",
  PUBLISH_EMAIL_FAILURE: "PUBLISH_EMAIL_FAILURE",

  // get client emails
  GET_CLIENT_EMAILS: "GET_CLIENT_EMAILS",
  GET_CLIENT_EMAILS_SUCCESS: "GET_CLIENT_EMAILS_SUCCESS",
  GET_CLIENT_EMAIL_BY_ID_SUCCESS: "GET_CLIENT_EMAIL_BY_ID_SUCCESS",
  GET_CLIENT_EMAILS_FAILURE: "GET_CLIENT_EMAILS_FAILURE",

  // get client emails by tag
  GET_CLIENT_EMAILS_BY_TAG: "GET_CLIENT_EMAILS_BY_TAG",
  GET_CLIENT_EMAILS_BY_TAG_SUCCESS: "GET_CLIENT_EMAILS_BY_TAG_SUCCESS",
  GET_CLIENT_EMAILS_BY_TAG_FAILURE: "GET_CLIENT_EMAILS_BY_TAG_FAILURE",

  // create client email copy
  CLIENT_EMAIL_COPY: "CLIENT_EMAIL_COPY",
  CLIENT_EMAIL_COPY_SUCCESS: "CLIENT_EMAIL_COPY_SUCCESS",
  CLIENT_EMAIL_COPY_FAILURE: "CLIENT_EMAIL_COPY_FAILURE",

  // update client email
  UPDATE_CLIENT_EMAIL: "UPDATE_CLIENT_EMAIL",
  UPDATE_CLIENT_EMAIL_SUCCESS: "UPDATE_CLIENT_EMAIL_SUCCESS",
  UPDATE_CLIENT_EMAIL_FAILURE: "UPDATE_CLIENT_EMAIL_FAILURE",

  // export client html email
  EXPORT_CLIENT_EMAIL: "EXPORT_CLIENT_EMAIL",
  EXPORT_CLIENT_EMAIL_SUCCESS: "EXPORT_CLIENT_EMAIL_SUCCESS",
  EXPORT_CLIENT_EMAIL_FAILURE: "EXPORT_CLIENT_EMAIL_FAILURE",

  // download client html email
  DOWNLOAD_CLIENT_EMAIL: "DOWNLOAD_CLIENT_EMAIL",
  DOWNLOAD_CLIENT_EMAIL_SUCCESS: "DOWNLOAD_CLIENT_EMAIL_SUCCESS",
  DOWNLOAD_CLIENT_EMAIL_FAILURE: "DOWNLOAD_CLIENT_EMAIL_FAILURE",

  // delete client email
  DELETE_CLIENT_EMAIL: "DELETE_CLIENT_EMAIL",
  DELETE_CLIENT_EMAIL_SUCCESS: "DELETE_CLIENT_EMAIL_SUCCESS",
  DELETE_CLIENT_EMAIL_FAILURE: "DELETE_CLIENT_EMAIL_FAILURE",

  // store email tag selection
  STORE_EMAIL_TAG: "STORE_EMAIL_TAG",

  // remove data
  REMOVE_EMAIL_EDITOR_MESSAGE: "REMOVE_EMAIL_EDITOR_MESSAGE",
  REMOVE_SINGLE_CLIENT_EMAIL: "REMOVE_SINGLE_CLIENT_EMAIL",
  REMOVE_EMAILS_BY_TAG_DATA: "REMOVE_EMAILS_BY_TAG_DATA",
};

export default EmailEditorTypes;
