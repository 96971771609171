import BatchHistoryTypes from "./type";

// get template batch history
export const getBatchHistory = (payload) => {
  return {
    type: BatchHistoryTypes.GET_BATCH_HISTORY,
    payload,
  };
};

export const getBatchHistorySuccess = (payload) => {
  return {
    type: BatchHistoryTypes.GET_BATCH_HISTORY_SUCCESS,
    payload,
  };
};

export const getBatchHistoryFailure = (payload) => {
  return {
    type: BatchHistoryTypes.GET_BATCH_HISTORY_FAILURE,
    payload,
  };
};

// download batch template
export const downloadBatchTemplate = (payload) => {
  return {
    type: BatchHistoryTypes.DOWNLOAD_BATCH_TEMPLATES,
    payload,
  };
};

export const downloadBatchTemplateSuccess = (payload) => {
  return {
    type: BatchHistoryTypes.DOWNLOAD_BATCH_TEMPLATES_SUCCESS,
    payload,
  };
};

export const downloadBatchTemplateFailure = (payload) => {
  return {
    type: BatchHistoryTypes.DOWNLOAD_BATCH_TEMPLATES_FAILURE,
    payload,
  };
};
