//Import NPM Packages.
import axios from "axios";

//Import Shared Constant.
import { API_BASE_URL } from "../../constants/index.js";

//Request Middleware.
async function makeHttpRequest(apiCall) {
  try {
    const data = await apiCall();
    return data.data;
  } catch (error) {
    return error;
  }
}

export function getRequest(path, params) {
  return makeHttpRequest(() => axios.get(`${API_BASE_URL}${path}`, { params }));
}

export function getRequestWithHeader(path, headerParams) {
  return makeHttpRequest(() => axios.get(path, headerParams));
}

export async function postRequest(path, data, options) {
  return makeHttpRequest(() =>
    axios.post(`${API_BASE_URL}${path}`, data, options)
  );
}

export async function postRequestWithoutBaseUrl(path, data, options) {
  return makeHttpRequest(() => axios.post(path, data, options));
}

export async function patchRequest(path, data, options) {
  return makeHttpRequest(() =>
    axios.patch(`${API_BASE_URL}${path}`, data, options)
  );
}

export async function patchRequestWithoutBaseUrl(path, data, options) {
  return makeHttpRequest(() => axios.patch(path, data, options));
}

export function putRequest(path, data, options) {
  return makeHttpRequest(() =>
    axios.put(`${API_BASE_URL}${path}`, data, options)
  );
}

export function putS3Request(url, data, options) {
  return makeHttpRequest(() => axios.put(url, data, options));
}

export function deleteRequest(path, data) {
  return makeHttpRequest(() =>
    axios.delete(`${API_BASE_URL}${path}`, { data })
  );
}
