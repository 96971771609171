import { all, call } from "redux-saga/effects";
import { ClientSaga } from "./Client/saga";
import { EmailTemplateSagas } from "./EmailTemplate/saga";
import { BatchHistorySaga } from "./BatchHistory/saga";
import { EmailEditorSaga } from "./EmailEditor/saga";
import { LightboxSaga } from "./Lightbox/saga";

export default function* rootSaga() {
  yield all([
    call(EmailTemplateSagas),
    call(ClientSaga),
    call(BatchHistorySaga),
    call(EmailEditorSaga),
    call(LightboxSaga),
  ]);
}
