import EmailEditorTypes from "./type";

const initialState = {
  authToken: "",
  beeTemplates: [],
  emailTags: [],
  singleTemplate: "",
  clientEmails: "",
  clientHtmlEmails: "",
  emailsByTag: [],
  FilteredHtmlEmail: "",
  singleClientEmail: "",
  downloadClientEmail: "",
  downloadBulkEmails: "",
  emailTagSelection: "",
  loginLoading: false,
  loading: false,
  emailTagsLoading: false,
  singleTemplateLoading: false,
  saveTemplateLoading: false,
  publishEmailLoading: false,
  clientEmailsLoading: false,
  emailsByTagLoading: false,
  clientEmailCopyLoading: false,
  exportClientEmailLoading: false,
  downloadEmailsLoading: false,
  updateEmailLoading: false,
  deleteEmailLoading: false,
  successMessage: "",
  errorMessage: "",
};

export default function EmailEditorReducer(state = initialState, action) {
  switch (action.type) {
    // Bee login
    case EmailEditorTypes.BEE_LOGIN:
      return {
        ...state,
        loginLoading: true,
        successMessage: "",
        errorMessage: "",
      };

    case EmailEditorTypes.BEE_LOGIN_SUCCESS:
      return {
        ...state,
        loginLoading: false,
        authToken: action.payload.token || action.payload.access,
        successMessage: "",
        errorMessage: "",
      };

    case EmailEditorTypes.BEE_LOGIN_FAILURE:
      return {
        ...state,
        loginLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // publish email to client view
    case EmailEditorTypes.PUBLISH_EMAIL:
      return {
        ...state,
        publishEmailLoading: true,
        successMessage: "",
        errorMessage: "",
      };

    case EmailEditorTypes.PUBLISH_EMAIL_SUCCESS:
      return {
        ...state,
        publishEmailLoading: false,
        successMessage: action.payload.msg,
        errorMessage: "",
      };

    case EmailEditorTypes.PUBLISH_EMAIL_FAILURE:
      return {
        ...state,
        publishEmailLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // create client email copy
    case EmailEditorTypes.CLIENT_EMAIL_COPY:
      return {
        ...state,
        clientEmailCopyLoading: true,
        successMessage: "",
        errorMessage: "",
      };

    case EmailEditorTypes.CLIENT_EMAIL_COPY_SUCCESS:
      return {
        ...state,
        clientEmailCopyLoading: false,
        successMessage: action.payload.msg,
        errorMessage: "",
      };

    case EmailEditorTypes.CLIENT_EMAIL_COPY_FAILURE:
      return {
        ...state,
        clientEmailCopyLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // export client html email
    case EmailEditorTypes.EXPORT_CLIENT_EMAIL:
      return {
        ...state,
        exportClientEmailLoading: true,
        successMessage: "",
        errorMessage: "",
      };

    case EmailEditorTypes.EXPORT_CLIENT_EMAIL_SUCCESS:
      return {
        ...state,
        exportClientEmailLoading: false,
        downloadClientEmail: action.payload.payload,
        successMessage: action.payload.msg,
        errorMessage: "",
      };

    case EmailEditorTypes.EXPORT_CLIENT_EMAIL_FAILURE:
      return {
        ...state,
        exportClientEmailLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // Download bulk client emails
    case EmailEditorTypes.DOWNLOAD_CLIENT_EMAIL:
      return {
        ...state,
        downloadEmailsLoading: true,
      };

    case EmailEditorTypes.DOWNLOAD_CLIENT_EMAIL_SUCCESS:
      return {
        ...state,
        downloadEmailsLoading: false,
        downloadBulkEmails: action.payload.payload,
        successMessage: action.payload.msg,
        errorMessage: "",
      };

    case EmailEditorTypes.DOWNLOAD_CLIENT_EMAIL_FAILURE:
      return {
        ...state,
        downloadEmailsLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // get all BeePro templates
    case EmailEditorTypes.GET_BEE_TEMPLATES:
      return {
        ...state,
        loading: true,
      };

    case EmailEditorTypes.GET_BEE_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        beeTemplates: action.payload,
        successMessage: "",
        errorMessage: "",
      };

    case EmailEditorTypes.GET_BEE_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // get email tags
    case EmailEditorTypes.GET_EMAIL_TAGS:
      return {
        ...state,
        emailTagsLoading: true,
      };

    case EmailEditorTypes.GET_EMAIL_TAGS_SUCCESS:
      return {
        ...state,
        emailTagsLoading: false,
        emailTags: action.payload,
        successMessage: "",
        errorMessage: "",
      };

    case EmailEditorTypes.GET_EMAIL_TAGS_FAILURE:
      return {
        ...state,
        emailTagsLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // get selected BeePro template
    case EmailEditorTypes.GET_SINGLE_BEE_TEMPLATE:
      return {
        ...state,
        singleTemplateLoading: true,
      };

    case EmailEditorTypes.GET_SINGLE_BEE_TEMPLATE_SUCCESS:
      return {
        ...state,
        singleTemplateLoading: false,
        singleTemplate: action.payload,
        successMessage: "",
        errorMessage: "",
      };

    case EmailEditorTypes.GET_SINGLE_BEE_TEMPLATE_FAILURE:
      return {
        ...state,
        singleTemplateLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // save bee email template changes
    case EmailEditorTypes.SAVE_BEE_TEMPLATE:
      return {
        ...state,
        saveTemplateLoading: true,
        successMessage: "",
        errorMessage: "",
      };

    case EmailEditorTypes.SAVE_BEE_TEMPLATE_SUCCESS:
      return {
        ...state,
        saveTemplateLoading: false,
        successMessage: "",
        errorMessage: "",
      };

    case EmailEditorTypes.SAVE_BEE_TEMPLATE_FAILURE:
      return {
        ...state,
        saveTemplateLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // get client emails
    case EmailEditorTypes.GET_CLIENT_EMAILS:
      return {
        ...state,
        clientEmailsLoading: true,
      };

    case EmailEditorTypes.GET_CLIENT_EMAILS_SUCCESS:
      return {
        ...state,
        clientEmailsLoading: false,
        clientEmails: action.payload,
        clientHtmlEmails: {
          ...state.clientHtmlEmails,
          ...action.payload.clientEmailHtml,
        },
        successMessage: "",
        errorMessage: "",
      };

    case EmailEditorTypes.GET_CLIENT_EMAIL_BY_ID_SUCCESS:
      return {
        ...state,
        clientEmailsLoading: false,
        singleClientEmail: action.payload,
        successMessage: "",
        errorMessage: "",
      };

    case EmailEditorTypes.GET_CLIENT_EMAILS_FAILURE:
      return {
        ...state,
        clientEmailsLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // get client emails by tag
    case EmailEditorTypes.GET_CLIENT_EMAILS_BY_TAG:
      return {
        ...state,
        emailsByTagLoading: true,
      };

    case EmailEditorTypes.GET_CLIENT_EMAILS_BY_TAG_SUCCESS:
      return {
        ...state,
        emailsByTagLoading: false,
        emailsByTag: action.payload.filteredClientEmail,
        FilteredHtmlEmail: action.payload.clientHtmlEmail,
        successMessage: "",
        errorMessage: "",
      };

    case EmailEditorTypes.GET_CLIENT_EMAILS_BY_TAG_FAILURE:
      return {
        ...state,
        emailsByTagLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // update client email
    case EmailEditorTypes.UPDATE_CLIENT_EMAIL:
      return {
        ...state,
        updateEmailLoading: true,
        successMessage: "",
        errorMessage: "",
      };

    case EmailEditorTypes.UPDATE_CLIENT_EMAIL_SUCCESS:
      return {
        ...state,
        updateEmailLoading: false,
        successMessage: "",
        errorMessage: "",
      };

    case EmailEditorTypes.UPDATE_CLIENT_EMAIL_FAILURE:
      return {
        ...state,
        updateEmailLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // delete client email
    case EmailEditorTypes.DELETE_CLIENT_EMAIL:
      return {
        ...state,
        deleteEmailLoading: true,
        successMessage: "",
        errorMessage: "",
      };

    case EmailEditorTypes.DELETE_CLIENT_EMAIL_SUCCESS:
      return {
        ...state,
        deleteEmailLoading: false,
        successMessage: action.payload.msg,
        errorMessage: "",
      };

    case EmailEditorTypes.DELETE_CLIENT_EMAIL_FAILURE:
      return {
        ...state,
        deleteEmailLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // store email tag selection
    case EmailEditorTypes.STORE_EMAIL_TAG:
      return {
        ...state,
        emailTagSelection: action.payload,
      };

    // remove messages after success
    case EmailEditorTypes.REMOVE_EMAIL_EDITOR_MESSAGE:
      localStorage.removeItem("emailData");
      return {
        ...state,
        beeTemplates: [],
        clientEmails: "",
        successMessage: "",
        errorMessage: "",
      };

    // remove single selected client email data
    case EmailEditorTypes.REMOVE_SINGLE_CLIENT_EMAIL:
      return {
        ...state,
        singleClientEmail: "",
      };

    // remove emaile by tag data
    case EmailEditorTypes.REMOVE_EMAILS_BY_TAG_DATA:
      return {
        ...state,
        emailsByTag: [],
        FilteredHtmlEmail: "",
      };

    default:
      return state;
  }
}
