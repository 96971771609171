import {
  cardTitle,
  whiteColor,
  datatableFontSize,
} from "assets/jss/material-dashboard-pro-react.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";

const batchHistoryStyle = {
  datatableFontSize,
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  downloadBtn: {
    float: "right",
    marginTop: "15px",
  },
  ...modalStyle,
};

export default batchHistoryStyle;
