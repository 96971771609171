import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import BeePlugin from "@mailupinc/bee-plugin";

// material ui icons
import VisibilityIcon from "@material-ui/icons/Visibility";
import SettingsIcon from "@material-ui/icons/Settings";
import CallSplitIcon from "@material-ui/icons/CallSplit";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomCircularProgress from "components/CustomCircularProgress/CustomCircularProgress.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import ClientListDialog from "views/EmailTemplate/ClientListDialog.js";
import UrlContructor from "views/EmailTemplate/UrlConstructor.js";
import AddTemplateDetails from "./AddTemplateDetails.js";
import Preview from "./Preview.js";
import {
  fetchHeroImg,
  replaceClientEmailPlaceholders,
  getIsAgency,
} from "utils/helpers/index.js";
import {
  beeProLogin,
  getSingleBeeTemplate,
  getClientEmails,
  exportClientEmail,
  saveBeeTemplate,
  updateClientEmail,
  removeEmailEditorMessage,
} from "redux/EmailEditor/action.js";
import {
  removeMessage,
  removeClientData,
  getClientByAcronym,
} from "redux/Client/action.js";
import { ROLE_KEY } from "utils/constants/AuthConfig.js";
import { adminUserRole } from "utils/constants/index.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/emailEditorStyle.js";
const useStyles = makeStyles(styles);

export default function Edit() {
  // styles
  const classes = useStyles();

  const params = useParams();

  const beeInstance = new BeePlugin();

  // tooltip attribute's values
  const tooltipId = "tooltip_" + Math.random().toString(36).substring(2, 15);
  const tooltipPlacement = "top";

  // auth0 variables
  const { user } = useAuth0();

  // get auth-token from localStorage
  const auth_token = localStorage.getItem("auth_token");

  const [jsonTemplate, setJsonTemplate] = useState("");
  const [htmlTemplate, setHtmlTemplate] = useState("");
  const [emailPreview, setEmailPreview] = useState(false);
  const [settingsDialog, setSettingsDialog] = useState(false);
  const [clientListModal, setClientListModal] = useState(false);
  const [loadEmail, setLoadEmail] = useState(false);
  const [emailSaved, setEmailSaved] = useState(false); // flag to detect the whether email changes are saved
  const [urlConstructModal, setUrlConstructModal] = useState(false);

  // Toggle snackbar (Notification)
  const [show, setShow] = useState(false);
  const isAgency = getIsAgency(user);
  const [notificationProps, setNotificationProps] = useState({
    color: "success",
    message: "",
  });

  // Redux store methods
  const dispatch = useDispatch();

  useEffect(() => {
    if (user[ROLE_KEY] != adminUserRole) {
      dispatch(
        getClientEmails(
          {
            clientEmailId: params.id,
          },
          isAgency
        )
      );
      dispatch(getClientByAcronym(user[ROLE_KEY]));
    } else {
      !auth_token && dispatch(beeProLogin());
    }
  }, []);

  // Redux store methods
  const clientEmails = useSelector(({ EmailEditor }) =>
    Object.keys(EmailEditor.singleClientEmail).length
      ? EmailEditor.singleClientEmail
      : ""
  );
  const emailData = useSelector(({ EmailEditor }) =>
    EmailEditor.singleClientEmail &&
    Object.keys(EmailEditor.singleClientEmail.emaildata).length
      ? EmailEditor.singleClientEmail.emaildata.Items
      : []
  );
  const singleBeeTemplate = useSelector(({ EmailEditor }) =>
    Object.keys(EmailEditor.singleTemplate).length
      ? EmailEditor.singleTemplate
      : ""
  );
  const emailEditorData = useSelector(({ EmailEditor }) => EmailEditor);
  const emailClient = useSelector(({ EmailClient }) => EmailClient);

  const clientData = useSelector(({ EmailClient }) =>
    Object.keys(EmailClient.singleClientData).length
      ? EmailClient.singleClientData.Item
      : []
  );
  const UrlConstructData = useSelector(({ EmailTemplate }) =>
    EmailTemplate.UrlConstructData?.length ? EmailTemplate.UrlConstructData : []
  );

  useEffect(() => {
    if (
      (emailEditorData.authToken || auth_token) &&
      user[ROLE_KEY] == adminUserRole
    ) {
      emailSaved
        ? dispatch(
            saveBeeTemplate(
              {
                id: params.id,
                jsonData: {
                  content_json: JSON.parse(jsonTemplate),
                },
              },
              isAgency
            )
          )
        : dispatch(getSingleBeeTemplate(params, isAgency));
    }
  }, [emailEditorData.authToken]);

  const mergeTags = [
    {
      name: "Email (Mailchimp)",
      value: "*|EMAIL|*",
    },
    {
      name: "First name (Mailchimp)",
      value: "*|FNAME|*",
    },
    {
      name: "Email (MailUp)",
      value: "[email]",
    },
    {
      name: "First name (MailUp)",
      value: "[firstname]",
    },
  ];

  // BeePLugin configuration
  const beeConfiguration = {
    uid: user[ROLE_KEY],
    container: "bee-plugin-container",
    language: "en-US",
    trackChanges: true,
    mergeTags,
    preventClose: true,
    rowsConfiguration: {
      emptyRows: true,
      defaultRows: true,
      saveRows: true,
      externalContentURLs: [
        {
          name: "Saved Rows",
          value: "saved-rows",
          handle: "saved-rows",
          behaviors: {
            canEdit: true,
            canDelete: true,
          },
        },
      ],
    },
    onLoad: () => {
      setLoadEmail(true);
      beeInstance.save();
    },
    onSave: (json, htmlFile) => {
      setJsonTemplate(json);
      setHtmlTemplate(htmlFile);
      if (user[ROLE_KEY] == adminUserRole) {
        // save email API call
        dispatch(
          saveBeeTemplate(
            {
              id: params.id,
              jsonData: {
                content_json: JSON.parse(json),
              },
            },
            isAgency
          )
        );
      } else {
        fetchHeroImg(htmlFile);
        dispatch(
          updateClientEmail({
            ...emailData[0],
            jsonEmailTemplate: json,
            htmlEmailTemplate: htmlFile,
            heroImg_data: localStorage.getItem("heroImg_data"),
          })
        );
      }
      setEmailSaved(true);
    },
    onChange: () => setEmailSaved(false),
    onError: (err) => {
      setNotificationProps({
        color: "danger",
        message: err.message,
      });
      setShow(true);
    },
  };

  // start BeePLugin editor for selected email
  useEffect(() => {
    (singleBeeTemplate || clientEmails.clientEmailJson) &&
      beeInstance
        .getToken(
          process.env.REACT_APP_BEE_CLIENT_ID,
          process.env.REACT_APP_BEE_CLIENT_SECRET
        )
        .then(() => {
          beeInstance.start(
            beeConfiguration,
            user[ROLE_KEY] == adminUserRole
              ? singleBeeTemplate.content_json
              : JSON.parse(clientEmails.clientEmailJson[params.id])
          );
        });
  }, [singleBeeTemplate, clientEmails]);

  const toggleSnackbar = () => setShow(!show);
  useEffect(() => {
    // If any error found, display in notification
    if (emailEditorData.errorMessage) {
      setNotificationProps({
        color: "danger",
        message: emailEditorData.errorMessage,
      });
      setShow(true);
    }

    // If email template created successfully, display in notification
    if (emailEditorData.successMessage) {
      setEmailPreview(false);
      setSettingsDialog(false);

      setNotificationProps({
        color: "success",
        message: emailEditorData.successMessage,
      });
      setShow(true);

      // remove success message after success
      emailClient.successMessage && dispatch(removeMessage());
      if (user[ROLE_KEY] == adminUserRole) {
        closeClientListModal();
        dispatch(removeEmailEditorMessage());
      }
    }
  }, [emailClient, emailEditorData]);

  // display notification if email changes are not saved
  const showNotification = () => {
    if (!emailSaved) {
      setNotificationProps({
        color: "warning",
        message: "Email changes are not saved",
      });
      setShow(true);
      // hide notification after timeout
      setTimeout(() => setShow(false), 5000);
    }
  };

  // handle select client dialog
  const distributeEmail = () => {
    if (emailSaved) {
      fetchHeroImg(htmlTemplate);
      setClientListModal(true);
    } else {
      showNotification();
    }
  };
  const closeClientListModal = () => {
    dispatch(removeClientData());
    setClientListModal(false);
  };

  // handle add email details dialog
  const addEmailDetails = () => {
    setSettingsDialog(true);
  };

  // review replaced placeholder email
  const reviewClientEmail = () => {
    emailSaved ? setEmailPreview(true) : showNotification();
  };

  const exportHtmlEmail = () => {
    if (emailSaved && htmlTemplate) {
      const clientInfo = emailData.find((item) => item);
      // For UTM/URL params
      let singleclientData = clientData;
      let check = [];
      const urlCheck = singleclientData?.urlParameters
        ? JSON.parse(singleclientData?.urlParameters)
        : {};
      const data = UrlConstructData?.filter(
        (e) => e.acronym == singleclientData.acronym
      );
      if (data.length) {
        for (let value of Object.keys(urlCheck)) {
          const obj = data[0].url?.filter(
            (e) => e.textbox == urlCheck[value].label
          );
          urlCheck[value].value = obj[0][urlCheck[value].label];
        }
        singleclientData = {
          ...singleclientData,
          urlParameters: JSON.stringify(urlCheck),
        };
      } else {
        if (Object.keys(urlCheck).length) {
          Object.keys(urlCheck)?.map((e, i) => {
            if (urlCheck[e].checked) {
              setUrlConstructModal(true);
              check.push(i);
            }
          });
        }
      }
      if (check.length == 0) {
        // replace placeholder
        const replacedHTMLClientEmail = replaceClientEmailPlaceholders({
          emaildata: clientInfo.emailDetails,
          clientDetails: JSON.stringify(singleclientData), // replace "clientData" from "clientInfo.clientDetails"
          htmlTemplate: htmlTemplate,
        });
        dispatch(
          exportClientEmail({
            client_acronym: clientInfo.clientAcronym,
            email_details: clientInfo,
            clientHtmlEmail: replacedHTMLClientEmail,
            is_agency: isAgency,
          })
        );
      }
    } else {
      showNotification();
    }
  };

  useEffect(() => {
    setSettingsDialog(false);
    setEmailPreview(false);
    // remove data after success
    return () => {
      dispatch(removeMessage());
      setLoadEmail(false);
      localStorage.removeItem("heroImg_data");
    };
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          {user[ROLE_KEY] !== adminUserRole
            ? !emailEditorData.clientEmailsLoading &&
              loadEmail && (
                <Button
                  color="rose"
                  size="md"
                  onClick={exportHtmlEmail}
                  className={classes.floatRightPosition}
                  loading={emailEditorData.exportClientEmailLoading}
                  disabled={emailEditorData.exportClientEmailLoading}
                >
                  Export Email
                </Button>
              )
            : loadEmail && (
                <div
                  className={classes.paginateBtnBlock}
                  style={{ margin: "2px 0" }}
                >
                  <Tooltip
                    id={tooltipId}
                    title="Publish to one or many client views."
                    placement={tooltipPlacement}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      block
                      size="md"
                      color="rose"
                      onClick={distributeEmail}
                    >
                      <CallSplitIcon /> Publish
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id={tooltipId}
                    title="Add email UTM, alt, and preview text."
                    placement={tooltipPlacement}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      block
                      size="md"
                      color="rose"
                      onClick={addEmailDetails}
                    >
                      <SettingsIcon /> Template Fields
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id={tooltipId}
                    title="Preview the email as a given client."
                    placement={tooltipPlacement}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      block
                      size="md"
                      color="rose"
                      onClick={reviewClientEmail}
                    >
                      <VisibilityIcon /> Preview
                    </Button>
                  </Tooltip>
                </div>
              )}
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div id="bee-plugin-container" className={classes.beeContainer} />
        </GridItem>
      </GridContainer>
      {emailPreview && (
        <Preview
          open={emailPreview}
          handleClose={() => setEmailPreview(false)}
          HTMLEmail={htmlTemplate}
        />
      )}
      {settingsDialog && (
        <AddTemplateDetails
          open={settingsDialog}
          handleClose={() => setSettingsDialog(false)}
        />
      )}
      {clientListModal && (
        <ClientListDialog
          open={clientListModal}
          handleClose={closeClientListModal}
          showTemplatePreview={false}
          jsonTemplate={jsonTemplate}
          htmlTemplate={htmlTemplate}
        />
      )}
      {urlConstructModal && (
        <UrlContructor
          open={urlConstructModal}
          handleClose={() => setUrlConstructModal(false)}
          client_data={clientData}
        />
      )}
      <Snackbar
        color={notificationProps.color}
        message={notificationProps.message}
        place="tr"
        open={show}
        close
        closeNotification={toggleSnackbar}
      />
      <CustomCircularProgress
        loading={
          user[ROLE_KEY] == adminUserRole
            ? emailEditorData.singleTemplateLoading
            : emailEditorData.clientEmailsLoading ||
              emailEditorData.updateEmailLoading
        }
      />
    </div>
  );
}
