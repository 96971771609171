import {
  grayColor,
  roseColor,
  defaultFontSize,
} from "assets/jss/material-dashboard-pro-react.js";

const clientListSelectStyle = {
  defaultFontSize,
  root: {
    "& .MuiInput-underline": {
      "&:before": {
        borderBottomWidth: "1px",
        borderBottomColor: grayColor[4],
      },
      "&:after": {
        borderBottomColor: roseColor[4],
      },
    },
  },
  textfield: {
    "& #clientList-label": {
      color: grayColor[1],
    },
  },
};

export default clientListSelectStyle;
