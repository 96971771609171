// ---- Email Template module ----
// generate pre-signed URL
export const generatePresignedUrl = `/emailtemplate/generateurl`;

// extract zip api
export const extractTemplateUrl = `/emailtemplate/extract`;
// create email template api
export const createEmailTemplateUrl = `/emailtemplate/save`;
// get email templates
export const getTemplatesUrl = `/emailtemplate/`;
// update template status
export const updateTemplateStatusUrl = `/emailtemplate/status/`;
// download template
export const downloadTemplateUrl = `/emailtemplate/download/`;

// ---- Client module ----
export const clientUrl = `/emailtclients/`;
// upload images
export const uploadImageUrl = `/emailtclients/upload`;

// ---- Template Batch History module ----
// get template batch history
export const getBatchHistoryUrl = `/emailtemplate/batchHistory`;
// download batch template
export const downloadBatchTemplateUrl = `/emailtemplate/batchtemplate/download`;

// ---- Email Editor module ----
export const beeLoginUrl = `https://beepro-api.getbee.io/api/v1/login/`;
export const getBeeTemplatesUrl = `https://beepro-api.getbee.io/api/v1/customers/784102/brands/767851/projects/1814948/`;
export const getBeeSummitElementsUrl = `https://beepro-api.getbee.io/api/v1/customers/784102/brands/767851/projects/1963603/`;
export const publishEmailUrl = `/emailtemplate/email/publish`;
export const clientEmailsUrl = `/emailtemplate/client/email/`;

// ---- Lightbox module ----
export const lightboxUrl = `/lightbox`;
