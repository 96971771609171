const LightboxTypes = {
  // create
  CREATE_LIGHTBOX: "CREATE_LIGHTBOX",
  CREATE_LIGHTBOX_SUCCESS: "CREATE_LIGHTBOX_SUCCESS",
  CREATE_LIGHTBOX_WARNING: "CREATE_LIGHTBOX_WARNING",
  CREATE_LIGHTBOX_FAILURE: "CREATE_LIGHTBOX_FAILURE",

  // get all
  GET_LIGHTBOXES: "GET_LIGHTBOXES",
  GET_LIGHTBOXES_SUCCESS: "GET_LIGHTBOXES_SUCCESS",
  GET_LIGHTBOXES_FAILURE: "GET_LIGHTBOXES_FAILURE",

  // get by id
  GET_LIGHTBOX_BY_ID: "GET_LIGHTBOX_BY_ID",
  GET_LIGHTBOX_BY_ID_SUCCESS: "GET_LIGHTBOX_BY_ID_SUCCESS",
  GET_LIGHTBOX_BY_ID_FAILURE: "GET_LIGHTBOX_BY_ID_FAILURE",

  // update
  UPDATE_LIGHTBOX: "UPDATE_LIGHTBOX",
  UPDATE_LIGHTBOX_SUCCESS: "UPDATE_LIGHTBOX_SUCCESS",
  UPDATE_LIGHTBOX_WARNING: "UPDATE_LIGHTBOX_WARNING",
  UPDATE_LIGHTBOX_FAILURE: "UPDATE_LIGHTBOX_FAILURE",

  // schedule
  SCHEDULE_LIGHTBOX: "SCHEDULE_LIGHTBOX",
  SCHEDULE_LIGHTBOX_SUCCESS: "SCHEDULE_LIGHTBOX_SUCCESS",
  SCHEDULE_LIGHTBOX_WARNING: "SCHEDULE_LIGHTBOX_WARNING",
  SCHEDULE_LIGHTBOX_FAILURE: "SCHEDULE_LIGHTBOX_FAILURE",

  // copy
  COPY_LIGHTBOX: "COPY_LIGHTBOX",
  COPY_LIGHTBOX_SUCCESS: "COPY_LIGHTBOX_SUCCESS",
  COPY_LIGHTBOX_FAILURE: "COPY_LIGHTBOX_FAILURE",

  // remove data
  REMOVE_DATA: "REMOVE_DATA",
};

export default LightboxTypes;
