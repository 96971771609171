import BatchHistoryTypes from "./type";

const initialState = {
  batchHistoryData: [],
  loading: false,
  downloadLoading: false,
  successMessage: "",
  errorMessage: "",
};

export default function BatchHistoryReducer(state = initialState, action) {
  switch (action.type) {
    // get template batch history
    case BatchHistoryTypes.GET_BATCH_HISTORY:
      return {
        ...state,
        loading: true,
      };

    case BatchHistoryTypes.GET_BATCH_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        batchHistoryData: action.payload,
        successMessage: action.payload.msg,
        errorMessage: "",
      };

    case BatchHistoryTypes.GET_BATCH_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    // download batch template
    case BatchHistoryTypes.DOWNLOAD_BATCH_TEMPLATES:
      return {
        ...state,
        downloadLoading: true,
      };

    case BatchHistoryTypes.DOWNLOAD_BATCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        downloadLoading: false,
        successMessage: action.payload.msg,
        errorMessage: "",
      };

    case BatchHistoryTypes.DOWNLOAD_BATCH_TEMPLATES_FAILURE:
      return {
        ...state,
        downloadLoading: false,
        successMessage: "",
        errorMessage: action.payload,
      };

    default:
      return state;
  }
}
