import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"; // eslint-disable-line
import { Controller, useForm } from "react-hook-form";
import SweetAlert from "react-bootstrap-sweetalert"; // react component used to create sweet alerts
// nodejs library to set properties for components
import PropTypes from "prop-types";

import { DevTool } from "@hookform/devtools";

// material ui icons
import Close from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Tooltip } from "@material-ui/core";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import { utmParameters, validateUrlRegex } from "utils/constants/index.js";
import { createUrlConstruct } from "redux/EmailTemplate/action.js"; // eslint-disable-line

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/emailTemplateStyle.js";
const useStyles = makeStyles(styles);

export default function UrlContructor(props) {
  // styles
  const classes = useStyles();

  // props
  const { open, handleClose, client_data } = props;

  // client details
  const { acronym, urlParameters, defaultDfUrl, utmParams } = client_data;

  // URL params
  const urlParams = urlParameters ? JSON.parse(urlParameters) : {};

  const utmParameter = utmParams
    ? JSON.parse(utmParams)
    : {
        utm_source: {
          checked: true,
          value: "",
        },
        utm_campaign: {
          checked: true,
          value: "",
        },
        utm_medium: {
          checked: true,
          value: "email",
        },
      };

  // Redux store methods
  const dispatch = useDispatch(); // eslint-disable-line
  const UrlConstructData = useSelector(({ EmailTemplate }) =>
    EmailTemplate.UrlConstructData?.length ? EmailTemplate.UrlConstructData : []
  );

  const defaultUrlParams = Object.values(urlParams).map((value) => {
    return { checkbox: value.checked, textbox: value.label, [value.label]: "" };
  });
  // react-hook-form methods
  const {
    formState: { errors },
    control,
    setValue,
    watch,
    getValues,
    handleSubmit,
    clearErrors,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      donation_url: defaultDfUrl ? defaultDfUrl : "",
      contructed_url: defaultDfUrl ? defaultDfUrl : "",
      utm: {
        utm_source: utmParameter ? utmParameter.utm_source.value : "",
        utm_campaign: utmParameter ? utmParameter.utm_campaign.value : "",
        utm_medium: utmParameter ? utmParameter.utm_medium.value : "",
      },
      url: defaultUrlParams,
    },
  });

  // store selected params
  const [selectedParams, setSelectedParams] = useState([]);
  const [showAlert, setAlert] = useState(null); // state manage alert

  // update the texfield dynamically when user write on live
  const updateTextfield = () => {
    const donationUrl = watch("donation_url");
    // For the UTM Params
    const utmField = watch("utm");

    const filterCheckValue = Object.entries(utmField).filter(([key]) =>
      selectedParams.includes(key)
    );
    const selectedcheckbox = Object.fromEntries(filterCheckValue);
    // For the URL Params
    const urlfield = watch("url");
    let urlParamArray = [];
    for (const key in urlfield) {
      if (Object.hasOwnProperty.call(urlfield, key)) {
        const element = urlfield[key];
        element.checkbox &&
          urlParamArray.push(`${element.textbox}=${element[element.textbox]}`);
      }
    }
    const selectedParamsArr = Object.keys(selectedcheckbox).map((key) => {
      return `${key}=${selectedcheckbox[key]}`;
    });

    const mergedParamsArr = selectedParamsArr.concat(urlParamArray);
    const dfUrl = donationUrl.includes("?")
      ? `${donationUrl}&`
      : `${donationUrl}?`;
    const constructedUrl = dfUrl + mergedParamsArr.join("&");

    return constructedUrl;
  };

  // handle params selection
  const handleChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedParams([...selectedParams, value]);
    } else {
      setSelectedParams(selectedParams.filter((v) => v !== value));
      clearErrors(`utm.${value}`);
    }
  };

  useEffect(() => {
    if (acronym) {
      const inData = UrlConstructData.filter((e) => e.acronym === acronym);
      if (UrlConstructData.length && inData.length) {
        let tempArr = [];
        let tempselectedParams = [];
        for (let value of UrlConstructData) {
          if (value.acronym == acronym) {
            setValue("donation_url", value.donation_url || "");
            if (value.utmChecked.length) {
              value.utmChecked?.map((e) => {
                setValue(`utm.${e.textbox}`, e[e.textbox] || "");
                e.checkbox && tempArr.push(e.textbox);
                e.checkbox && tempselectedParams.push(e.textbox);
              });
            }
            if (value.url.length) {
              value.url?.map((e, i) => {
                setValue(`url.${i}.${e.textbox}`, e[e.textbox] || "");
                setValue(`url.${i}.checkbox`, e.checkbox || "");
              });
            }
          }
        }
        setSelectedParams([...selectedParams, ...tempselectedParams]);
      } else {
        if (Object.keys(utmParameter).length) {
          let tempArr1 = [];
          let tempselectedParams1 = [];
          for (let value of Object.keys(utmParameter)) {
            setValue(`utm.${value}`, utmParameter[value].value || "");
            utmParameter[value].checked && tempArr1.push(value);
            utmParameter[value].checked && tempselectedParams1.push(value);
          }
          setSelectedParams([...selectedParams, ...tempselectedParams1]);
        }
      }
    }
  }, [open]);

  // save constructed URL
  const saveContructedUrl = (inputData) => {
    // get construct Df URL
    const finalUrl = updateTextfield();
    const utmCheckParams = Object.entries(inputData.utm).map((e) => {
      return { checkbox: finalUrl.includes(e[0]), textbox: e[0], [e[0]]: e[1] };
    });
    inputData.contructed_url = finalUrl;
    inputData.acronym = acronym;
    inputData.utmChecked = utmCheckParams;
    dispatch(createUrlConstruct(inputData));
    handleClose();
  };

  // Copy To Clipboard
  const copyToClipboard = async () => {
    let copyText = document.getElementById("FinalUrl");
    const text = copyText.innerHTML;
    await navigator.clipboard.writeText(text);
    setAlert(
      <SweetAlert
        success
        title="Text copied"
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      ></SweetAlert>
    );
    setTimeout(() => setAlert(null), 2000); // timeout to success alert
  };

  return (
    <Dialog open={open} disableBackdropClick maxWidth="md" fullWidth>
      {showAlert}
      <DialogTitle>
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={handleClose}
        >
          <Close />
        </Button>
        URL Constructor
      </DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12} sm={10} md={11} lg={11}>
            <FormLabel>Contructed URL</FormLabel>
            <p className={classes.UrlLabel} id="FinalUrl">
              {updateTextfield()}
            </p>
          </GridItem>
          <GridItem xs={12} sm={2} md={1} lg={1}>
            <Button color="rose" size="md" onClick={() => copyToClipboard()}>
              Copy
            </Button>
          </GridItem>
        </GridContainer>
        <Controller
          control={control}
          name="donation_url"
          rules={{
            required: "Donation URL is required",
            pattern: {
              value: validateUrlRegex,
              message: "Please enter a valid URL",
            },
          }}
          render={({ field: { onChange } }) => (
            <CustomInput
              labelText="Donation URL"
              onChange={onChange}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "donation_url",
                type: "text",
                defaultValue: getValues(`donation_url`),
              }}
            />
          )}
        />
        <Danger>
          <p className={classes.errorMsgMargin}>
            {errors?.donation_url?.message}
          </p>
        </Danger>
        <FormLabel>UTM Parameters</FormLabel>
        <br />
        {utmParameters.map((utm, index) => (
          <div key={`utmParams${index}`}>
            <Tooltip
              id="tooltip-top"
              title="Make parameter required"
              placement="right"
              classes={{ tooltip: classes.tooltip }}
            >
              <Checkbox
                checked={selectedParams?.includes(utm.name) ? true : false}
                onChange={handleChange}
                name={`utm.${index}.checkbox`}
                value={`${utm.name}`}
                color="secondary"
              />
            </Tooltip>
            <span className={classes.UrlLabel} style={{ marginRight: "1rem" }}>
              {utm.name}
            </span>
            <Controller
              control={control}
              name={`utm.${utm.name}`}
              rules={{
                required: selectedParams?.includes(utm.name)
                  ? `${utm.name} is required`
                  : false,
              }}
              render={({ field: { onChange } }) => (
                <CustomInput
                  id={utm.name}
                  onChange={onChange}
                  inputProps={{
                    name: utm.name,
                    type: utm.type,
                    defaultValue: getValues(`utm.${utm.name}`),
                  }}
                />
              )}
            />
            <Danger>
              <p className={classes.errorMsgMargin}>
                {errors?.["utm"]?.[utm.name]?.message}
              </p>
            </Danger>
          </div>
        ))}
        <br />
        {Object.keys(urlParams).length > 0 && (
          <>
            <FormLabel>URL Parameters</FormLabel>
            <br />
            {Object.values(urlParams).map((item, index) => {
              return (
                <div key={`urlParams${index}`}>
                  <Controller
                    control={control}
                    name={`url.${index}.checkbox`}
                    defaultValue={
                      getValues(`url.${index}.checkbox`) ? true : false
                    }
                    render={({ field: { onChange, value } }) => (
                      <Tooltip
                        id="tooltip-top"
                        title="Make parameter required"
                        placement="right"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Checkbox
                          checked={
                            getValues(`url.${index}.checkbox`) ? true : false
                          }
                          onChange={(e) => {
                            onChange(e);
                            !e.target.checked &&
                              clearErrors(`url.${index}.${item.label}`);
                          }}
                          value={value}
                          color="secondary"
                        />
                      </Tooltip>
                    )}
                  />
                  <span
                    className={classes.UrlLabel}
                    style={{ marginRight: "1rem" }}
                  >
                    {item.label}
                  </span>
                  <Controller
                    control={control}
                    name={`url.${index}.${item.label}`}
                    rules={{
                      required: getValues(`url.${index}.checkbox`)
                        ? `${item.label} is required`
                        : false,
                    }}
                    render={({ field: { onChange } }) => (
                      <CustomInput
                        id={item.label}
                        onChange={onChange}
                        inputProps={{
                          name: item.label,
                          type: "text",
                          defaultValue: getValues(`url.${index}.${item.label}`),
                        }}
                      />
                    )}
                  />
                  <Danger>
                    <p className={classes.errorMsgMargin}>
                      {errors?.["url"]?.[index]?.[item.label]?.message}
                    </p>
                  </Danger>
                </div>
              );
            })}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="rose"
          onClick={handleSubmit((formData) => saveContructedUrl(formData))}
        >
          <SaveIcon /> Save
        </Button>
      </DialogActions>
      <DevTool control={control} />
    </Dialog>
  );
}

// define props type
UrlContructor.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  client_data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
