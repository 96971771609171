import ClientTypes from "./type";

// insert client data
export const insertClient = (payload) => {
  return {
    type: ClientTypes.INSERT_CLIENT,
    payload,
  };
};

export const insertClientSuccess = (payload) => {
  return {
    type: ClientTypes.INSERT_CLIENT_SUCCESS,
    payload,
  };
};

export const insertClientFailure = (payload) => {
  return {
    type: ClientTypes.INSERT_CLIENT_FAILURE,
    payload,
  };
};

// get client data
export const getClient = (payload) => {
  return {
    type: ClientTypes.GET_CLIENT,
    payload,
  };
};

export const getClientSuccess = (payload) => {
  return {
    type: ClientTypes.GET_CLIENT_SUCCESS,
    payload,
  };
};

export const getClientFailure = (payload) => {
  return {
    type: ClientTypes.GET_CLIENT_FAILURE,
    payload,
  };
};

// get client data by acronym
export const getClientByAcronym = (payload) => {
  return {
    type: ClientTypes.GET_CLIENT_BY_ACRONYM,
    payload,
  };
};

export const getClientByAcronymSuccess = (payload) => {
  return {
    type: ClientTypes.GET_CLIENT_BY_ACRONYM_SUCCESS,
    payload,
  };
};

export const getClientByAcronymFailure = (payload) => {
  return {
    type: ClientTypes.GET_CLIENT_BY_ACRONYM_FAILURE,
    payload,
  };
};

// get clients by category
export const getClientByCategory = (payload, userData) => {
  return {
    type: ClientTypes.GET_CLIENT_BY_CATEGORY,
    payload,
    userData,
  };
};

export const getClientByCategorySuccess = (payload) => {
  return {
    type: ClientTypes.GET_CLIENT_BY_CATEGORY_SUCCESS,
    payload,
  };
};

export const getClientByCategoryFailure = (payload) => {
  return {
    type: ClientTypes.GET_CLIENT_BY_CATEGORY_FAILURE,
    payload,
  };
};

// update client data
export const updateClient = (payload) => {
  return {
    type: ClientTypes.UPDATE_CLIENT,
    payload,
  };
};

export const updateClientSuccess = (payload) => {
  return {
    type: ClientTypes.UPDATE_CLIENT_SUCCESS,
    payload,
  };
};

export const updateClientFailure = (payload) => {
  return {
    type: ClientTypes.UPDATE_CLIENT_FAILURE,
    payload,
  };
};

// upload image
export const uploadImage = (payload) => {
  return {
    type: ClientTypes.UPLOAD_IMAGES,
    payload,
  };
};

export const uploadImageSuccess = (payload) => {
  return {
    type: ClientTypes.UPLOAD_IMAGES_SUCCESS,
    payload,
  };
};

export const uploadSignatureImageSuccess = (payload) => {
  return {
    type: ClientTypes.UPLOAD_SIGNATURE_IMAGES_SUCCESS,
    payload,
  };
};

export const uploadImageFailure = (payload) => {
  return {
    type: ClientTypes.UPLOAD_IMAGES_FAILURE,
    payload,
  };
};

// remove client data after successfully saved
export const removeClientData = () => {
  return {
    type: ClientTypes.REMOVE_CLIENT_DATA,
  };
};

// remove client data after successfully saved
export const removeMessage = () => {
  return {
    type: ClientTypes.REMOVE_MESSAGE,
  };
};

// store selected clients data
export const setSelectedEmailClients = (payload) => {
  return {
    type: ClientTypes.SELECTED_EMAIL_CLIENTS,
    payload,
  };
};
