import {
  defaultFontSize,
  tooltip,
  roseColor,
} from "assets/jss/material-dashboard-pro-react.js";
import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";

const templateEditorStyle = {
  ...hoverCardStyle,
  ...customSelectStyle,
  tooltip,
  defaultFontSize,
  beeContainer: {
    height: "86vh",
  },
  cardTextBlock: {
    height: "6rem",
  },
  cardEmailTitle: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
  },
  errorMsgMargin: {
    marginTop: "-10px",
  },
  floatRightPosition: {
    float: "right",
  },
  gridItemMargin: {
    margin: "0.8rem 0",
  },
  paginateBtnBlock: {
    display: "flex",
    float: "right",
  },
  circularLoader: {
    color: roseColor[6],
    margin: "5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  filterBlock: {
    width: "30%",
    "@media (max-width: 1024px)": {
      width: "50%",
    },
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  pointerCursor: {
    cursor: "pointer",
  },
  thumbnailContainer: {
    display: "flex",
    justifyContent: "center",
    background: "white",
    width: "100%",
    height: "20rem",
    "@media (max-width: 1440px)": {
      height: "15rem",
    },
  },
  actionBtn: {
    "& svg": {
      width: "25px",
      height: "25px",
    },
  },
  ...buttonStyle,
  ...modalStyle,
};

export default templateEditorStyle;
