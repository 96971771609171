/*!

=========================================================
* Material Dashboard PRO React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import npm packages
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { storeReducer } from "redux/store";
import { Auth0Provider } from "@auth0/auth0-react";

// core components
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import * as auth from "utils/constants/AuthConfig";
import PrivateRoute from "components/Authentication/PrivateRoute.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";

ReactDOM.render(
  <Provider store={storeReducer()}>
    <BrowserRouter>
      <Auth0Provider
        domain={auth.AUTH0_DOMAIN}
        clientId={auth.CLIENT_ID}
        redirectUri={window.location.origin + "/admin/create-email-template"}
      >
        <Switch>
          <Route path="/auth" component={AuthLayout} />
          <PrivateRoute path="/admin" component={AdminLayout} />
          <Redirect exact={true} from="/" to="/auth" />
        </Switch>
      </Auth0Provider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
