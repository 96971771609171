import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import { insertClient } from "redux/Client/action.js";
import { clientCatg } from "utils/constants/index.js";
import { getIsAgency } from "utils/helpers";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/clientStyle.js";

const useStyles = makeStyles(styles);

export default function Create(props) {
  // props
  const { open, handleClose } = props;

  // react-hook-form methods
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      client_category: "",
    },
  });

  const dispatch = useDispatch();
  const { user: authUser } = useAuth0();
  // Redux store variable
  const insertLoading = useSelector(
    ({ EmailClient }) => EmailClient.insertLoading
  );

  const submitClientData = (inputs) => {
    const isAgency = getIsAgency(authUser);
    inputs.is_agency = isAgency;
    if (isAgency) {
      inputs.agency_name = isAgency[0];
      dispatch(insertClient(inputs));
    } else {
      inputs.agency_name = "buildr";
      dispatch(insertClient(inputs));
    }
  };

  // styles
  const classes = useStyles();

  return (
    <>
      <Dialog open={open} disableBackdropClick>
        <DialogTitle>
          Add a new client
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={handleClose}
          >
            <Close />
          </Button>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            To create a new email client, please enter that client’s acronym and
            business name below.
          </DialogContentText>

          <form onSubmit={handleSubmit(submitClientData)}>
            <Controller
              control={control}
              name="client_acronym"
              rules={{
                required: "Client Acronym is required",
              }}
              render={({ field: { onChange, onBlur } }) => (
                <CustomInput
                  onChange={onChange}
                  onBlur={onBlur}
                  labelText="Client Acronym"
                  id="client_acronym"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    onChange: onChange,
                    onBlur: onBlur,
                  }}
                />
              )}
            />
            <Danger>
              <p className={classes.errorMsgMargin}>
                {errors?.client_acronym && errors.client_acronym.message}
              </p>
            </Danger>

            <Controller
              control={control}
              name="client_name"
              rules={{
                required: "Client Name is required",
              }}
              render={({ field: { onChange, onBlur } }) => (
                <CustomInput
                  onChange={onChange}
                  onBlur={onBlur}
                  labelText="Client Name"
                  id="client_name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    onChange: onChange,
                    onBlur: onBlur,
                  }}
                />
              )}
            />
            <Danger>
              <p className={classes.errorMsgMargin}>
                {errors?.client_name && errors.client_name.message}
              </p>
            </Danger>

            <Controller
              control={control}
              name="client_category"
              rules={{
                required: "Client Category is required",
              }}
              render={({ field: { value, ...field } }) => (
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="client_category"
                    className={classes.selectLabel}
                  >
                    Client Category
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={value}
                    inputProps={{
                      name: "client_category",
                      id: "client_category",
                    }}
                    {...field}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Choose Client Category
                    </MenuItem>
                    {clientCatg.map((catg, index) => {
                      return (
                        <MenuItem
                          key={`catg${index}`}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={catg.value}
                        >
                          {catg.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            />
            <Danger>
              <p className={classes.errorMsgMargin}>
                {errors?.client_category && errors.client_category.message}
              </p>
            </Danger>

            <DialogActions>
              <Button
                type="submit"
                color="rose"
                disabled={insertLoading}
                loading={insertLoading}
              >
                Submit
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

// define props type
Create.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};
